<template>
    <div ref="scrollTarget"></div>

    <section style="padding-top: 30px; padding-bottom: 100px;" :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">
        <div class="container mt-4">
            <div class="row justify-content-center">
    <div class="col-lg-8 col-md-12 col-sm-12 mb-4">
        <div class="d-flex flex-column flex-md-row p-4 rounded text-center text-md-left align-items-center align-items-md-start">
            <img src="../assets/img/coworkingSignup.svg" class="mb-3 mb-md-0 me-md-4" alt="Coworking Signup Image" style="max-width: 100px; height: auto;">
            <div>
                <p class="fw-bold display-6 mb-3" style="color: #E5097F;">
                    {{ translate('coworking_signup_title1') }}
                </p>
                <p class="text-dark fw-bold fs-5 mb-2">
                    {{ translate('coworking_signup_title2') }}
                </p>
                <p class="text-dark fw-bold fs-5">
                    {{ translate('coworking_signup_title3') }}
                </p>
            </div>
        </div>
    </div>
</div>


            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10 col-sm-12 mb-4">
                    <div class="p-4 text-center"
                        style="background-color: #FFFAFC; border-radius: 20px; font-weight: bold;">
                        {{ translate('have_account') }}
                        <router-link to="/login" class="btn btn-login2 " type="submit">
                            <i class="fa fa-user" aria-hidden="true"></i> {{ translate('login') }}
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10 col-sm-12 mb-4">
                    <div class="text-center" style="background-color: #FFFAFC; border-radius: 20px; padding: 20px;">
                        <form @submit.prevent="submitForm" style="margin-top: 20px;">
                            <div style="text-align: left; color: black; font-weight: 600; margin-bottom: 20px;">
                                {{ translate('personal_information') }}
                            </div>
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col">
                                        <select class="form-control" v-model="title"
                                            :class="{ 'fill-out': title, 'not-fill-out': !title }">
                                            <option value="Mr">{{ translate('mr') }}</option>
                                            <option value="Mrs">{{ translate('mrs') }}</option>
                                            <option value="Ms">{{ translate('ms') }}</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('first_name')"
                                            required v-model="first_name"
                                            :class="{ 'fill-out': first_name, 'not-fill-out': !first_name }">
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('last_name')"
                                            required v-model="last_name"
                                            :class="{ 'fill-out': last_name, 'not-fill-out': !last_name }">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <input type="email" class="form-control" :placeholder="translate('email')" required
                                    v-model="email" :class="{ 'fill-out': email, 'not-fill-out': !email }">
                            </div>
                            <div class="form-group mb-3">
                                <input type="text" class="form-control" ref="phoneInput" :placeholder="translate('phone')"
                                    required v-model="phone" :class="{ 'fill-out': phone, 'not-fill-out': !phone }">
                            </div>

                            <div class="input-group mb-3 align-items-center">
                                <input id="autocomplete" type="text" class="form-control"
                                    :placeholder="translate('search_address')">
                            </div>

                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('number')" required
                                            v-model="number" :class="{ 'fill-out': number, 'not-fill-out': !number }">
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('street')" required
                                            v-model="street" :class="{ 'fill-out': street, 'not-fill-out': !street }">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('postal_code')"
                                            required v-model="postal_code"
                                            :class="{ 'fill-out': postal_code, 'not-fill-out': !postal_code }">
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('city')" required
                                            v-model="city" :class="{ 'fill-out': city, 'not-fill-out': !city }">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('state')" required
                                            v-model="state" :class="{ 'fill-out': state, 'not-fill-out': !state }">
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Country" required
                                            v-model="country" readonly
                                            :class="{ 'fill-out': country, 'not-fill-out': !country }">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col">
                                        <input type="password" class="form-control" :placeholder="translate('password')"
                                            required v-model="password"
                                            :class="{ 'fill-out': password, 'not-fill-out': !password }">
                                    </div>
                                    <div class="col">
                                        <input type="password" class="form-control"
                                            :placeholder="translate('confirm_password')" required v-model="re_password"
                                            :class="{ 'fill-out': re_password, 'not-fill-out': !re_password }">
                                    </div>
                                </div>
                            </div>

                            <div style="text-align: left; color: black; font-weight: 600; margin-bottom: 20px; margin-top: 30px;">
                                {{ translate('company_information') }}
                            </div>

                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('company_name')" required
                                            v-model="company_name"
                                            :class="{ 'fill-out': company_name, 'not-fill-out': !company_name }">
                                    </div>
                                    <template v-if="!autre">
                                    <div class="col">
                                        <select class="form-control" v-model="activity"
                                            :class="{ 'fill-out': activity, 'not-fill-out': !activity }">
                                            <option value="" disabled selected> {{ translate('activity') }} </option>
                                            <option value="Telecome">Telecome</option>
                                            <option value="Libraire">Libraire</option>
                                            <option value="Informatique">Informatique</option>
                                            <option value="Autre">Autre</option>
                                        </select>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('activity')" required
                                            v-model="activity" :class="{ 'fill-out': activity, 'not-fill-out': !activity }">
                                    </div>
                                </template>
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('store_name')" required
                                            v-model="store_name"
                                            :class="{ 'fill-out': store_name, 'not-fill-out': !store_name }">
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('vat_number')" required
                                            v-model="vat_number"
                                            :class="{ 'fill-out': vat_number, 'not-fill-out': !vat_number }">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <input type="email" class="form-control" :placeholder="translate('email')" required
                                    v-model="email_company"
                                    :class="{ 'fill-out': email_company, 'not-fill-out': !email_company }">
                            </div>
                            <div class="form-group mb-3">
                                <input type="text" class="form-control" ref="phoneInput2" :placeholder="translate('phone')"
                                    required v-model="phone_company"
                                    :class="{ 'fill-out': phone_company, 'not-fill-out': !phone_company }">
                            </div>

                            <div class="input-group mb-3 align-items-center">
                                <input id="autocomplete2" type="text" class="form-control"
                                    :placeholder="translate('search_address')">
                            </div>

                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('number')" required
                                            v-model="number_company"
                                            :class="{ 'fill-out': number_company, 'not-fill-out': !number_company }">
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('street')" required
                                            v-model="street_company"
                                            :class="{ 'fill-out': street_company, 'not-fill-out': !street_company }">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('postal_code')"
                                            required v-model="postal_code_company"
                                            :class="{ 'fill-out': postal_code_company, 'not-fill-out': !postal_code_company }">
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('city')" required
                                            v-model="city_company"
                                            :class="{ 'fill-out': city_company, 'not-fill-out': !city_company }">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col">
                                        <input type="text" class="form-control" :placeholder="translate('state')" required
                                            v-model="state_company"
                                            :class="{ 'fill-out': state_company, 'not-fill-out': !state_company }">
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Country" required
                                            v-model="country" readonly
                                            :class="{ 'fill-out': country, 'not-fill-out': !country }">
                                    </div>
                                </div>
                            </div>

                            <div class="text-center mt-3">
                                <button type="submit" class="btn btn-login2 me-2">{{ translate('sign_up') }}</button>
                                <br>
                                <div class="notification is-danger" style="margin-top: 15px;" v-if="errors.length">
                                    <p v-for="error in errors" :key="error">{{ error }}</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import Swal from 'sweetalert2'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';
import BackgroundImage1 from '../assets/img/background_cowsign.jpg';


export default {
    name: 'SignUp',
    mixins: [en, fr, nl],
    data() {
        return {
            email: '',
            first_name: '',
            last_name: '',
            phone: '',
            extention: '',
            street: '',
            postal_code: '',
            state: '',
            number: '',
            city: '',
            title: 'Mr',
            country: 'Belgium',
            password: '',
            re_password: '',

            company_name: "",
            activity: "",
            store_name: "",
            vat_number: "",
            email_company: "",
            phone_company: "",
            number_company: "",
            street_company: "",
            postal_code_company: "",
            city_company: "",
            state_company: "",
            errors: [],
            autre: false,
            backgroundImage1: BackgroundImage1,
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    watch: {
    async activity(newValue, oldValue) {
      if(this.activity == "Autre"){
        this.autre = true
        this.activity = ""
      }
    },
    },
    mounted() {
        document.title = 'Sign UP Coworking | Eurekali'
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0);

        this.ShowLocationChoice();

        var autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete"),);
        autocomplete.setComponentRestrictions({ country: ["be"] });
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (!place.address_components) {
                return;
            }

            let country = '';
            let postalCode = '';
            let state = '';
            let city = '';
            let number = '';
            let street = '';

            for (const component of place.address_components) {
                if (component.types.includes("country")) {
                    country = component.long_name;
                }
                if (component.types.includes("postal_code")) {
                    postalCode = component.long_name;
                }
                if (component.types.includes("administrative_area_level_1")) {
                    state = component.long_name;
                }
                if (component.types.includes("locality")) {
                    city = component.long_name;
                }
                if (component.types.includes("street_number")) {
                    number = component.long_name;
                }
                if (component.types.includes("route")) {
                    street = component.long_name;
                }
            }

            this.country = country;
            this.postal_code = postalCode;
            this.state = state;
            this.city = city;
            this.number = number;
            this.street = street;
        });

        var autocomplete2 = new google.maps.places.Autocomplete(document.getElementById("autocomplete2"),);
        autocomplete2.setComponentRestrictions({ country: ["be"] });
        autocomplete2.addListener("place_changed", () => {
            const place2 = autocomplete2.getPlace();
            if (!place2.address_components) {
                return;
            }

            let country_company = '';
            let postalCode_company = '';
            let state_company = '';
            let city_company = '';
            let number_company = '';
            let street_company = '';

            for (const component2 of place2.address_components) {
                if (component2.types.includes("country")) {
                    country_company = component2.long_name;
                }
                if (component2.types.includes("postal_code")) {
                    postalCode_company = component2.long_name;
                }
                if (component2.types.includes("administrative_area_level_1")) {
                    state_company = component2.long_name;
                }
                if (component2.types.includes("locality")) {
                    city_company = component2.long_name;
                }
                if (component2.types.includes("street_number")) {
                    number_company = component2.long_name;
                }
                if (component2.types.includes("route")) {
                    street_company = component2.long_name;
                }
            }

            this.country_company = country_company;
            this.postal_code_company = postalCode_company;
            this.state_company = state_company;
            this.city_company = city_company;
            this.number_company = number_company;
            this.street_company = street_company;
        });


        //---------Country code phone
        const phoneInput = this.$refs.phoneInput;
        intlTelInput(phoneInput, {
            separateDialCode: true,
            preferredCountries: ["be"],
        });
        //------------------------

        //---------Country code phone
        const phoneInput2 = this.$refs.phoneInput2;
        intlTelInput(phoneInput2, {
            separateDialCode: true,
            preferredCountries: ["be"],
        });
        //------------------------

    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        ShowLocationChoice() {
      Swal.fire({
        icon: 'info',
        title: this.translate('location_choose_title'),
        text: this.translate('location_choose_desc'),
        showCancelButton: true,
        confirmButtonText: this.translate('belgium'),
        cancelButtonText: this.translate('morocco'),
        confirmButtonColor: '#E5097F',
        cancelButtonColor: '#9b0656',
      }).then((result) => {
        if (result.isConfirmed) {
            this.$router.push('/signup-coworking')
        }
        if(!result.isConfirmed){
            this.$router.push('/signup-coworking-ma')
        }
      });
    },
        async submitForm() {
            this.errors = []
            if (this.email === '') {
                this.errors.push('The Email is missing')
            }
            if (this.password === '') {
                this.errors.push('The password is too short')
            }
            if (this.password !== this.re_password) {
                this.errors.push('The passwords doesn\'t match')
            }
            if (!this.errors.length) {


                const phoneInput = this.$refs.phoneInput;
                const iti = intlTelInputGlobals.getInstance(phoneInput);
                const selectedCountryCode = "+" + iti.getSelectedCountryData().dialCode;


                const extention = selectedCountryCode

                const formData = {
                    email: this.email,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    phone: extention + this.phone,
                    street: this.street,
                    postal_code: this.postal_code,
                    state: this.state,
                    number: this.number,
                    city: this.city,
                    country: "Belgium",
                    title: this.title,
                    password: this.password,
                    re_password: this.re_password
                }
                await axios
                    .post("/api/v1/users/", formData)
                    .then(async response => {
                        await this.CreateServicePoint(response.data['id'])

                        toast({
                            message: this.translate('register_mssg'),
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 3000,
                            position: 'top-right',
                        })
                        this.$router.push('/login')
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                            console.log(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please try again')

                            console.log(JSON.stringify(error))
                        }
                    })
            }
        },
        async CreateServicePoint(user_id) {
            const phoneInput2 = this.$refs.phoneInput2;
            const iti2 = intlTelInputGlobals.getInstance(phoneInput2);
            const selectedCountryCode2 = "+" + iti2.getSelectedCountryData().dialCode;

            const extention2 = selectedCountryCode2

            const formData = {
                user: user_id,
                company_name: this.company_name,
                store_name: this.store_name,
                activity: this.activity,
                vat_number: this.vat_number,
                email: this.email_company,
                phone: extention2 + this.phone_company,

                street: this.street_company,
                postal_code: this.postal_code_company,
                state: this.state_company,
                number: this.number_company,
                city: this.city_company,
                country: "Belgium",
            }
            await axios
                .post("/api/v1/servicepoints/create/", formData)
                .then(response => {
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        this.errors.push('Something went wrong. Please try again')

                        console.log(JSON.stringify(error))
                    }
                })

        }
    }
}
</script>

<style>
.btn-login2 {
    background-color: #E5097F;
    color: white;
    margin-left: 15px;
    border-radius: 20px;
    font-weight: bold;
    padding-left: 25px;
    padding-right: 25px;
}

.btn-login2:hover {
    background-color: #9b0656;
    color: white;
}
</style>