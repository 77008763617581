<template>
  <div ref="scrollTarget"></div>
  <section style="margin-top: 30px; margin-bottom: 100px;">
    <div class="container mt-4">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <form class="border" @submit.prevent="showAlert"
            style="padding: 20px; border-radius: 15px;">
            <div class="form-group mb-3">
              <div class="row text-center" style="color: #E5097F; font-size: 80px;">
                <div>
                  <i class="fa fa-user-circle-o"></i>
                </div>
              </div>
              <div class="row" style="margin-top: 20px;">
                <div class="col">
                  <label class="form-label">{{ translate('title') }}</label>
                  <select class="form-control" v-model="user.title"
                    :class="{ 'fill-out': user.title, 'not-fill-out': !user.title }">
                    <option value="Mr">{{ translate('mr') }}</option>
                    <option value="Mrs">{{ translate('mrs') }}</option>
                    <option value="Ms">{{ translate('ms') }}</option>
                  </select>
                </div>
                <div class="col">
                  <label class="form-label">{{ translate('first_name') }}</label>
                  <input type="text" class="form-control" :placeholder="translate('first_name')" required
                    v-model="user.first_name" :class="{ 'fill-out': user.first_name, 'not-fill-out': !user.first_name }">
                </div>
                <div class="col">
                  <label class="form-label">{{ translate('last_name') }}</label>
                  <input type="text" class="form-control" :placeholder="translate('last_name')" required
                    v-model="user.last_name" :class="{ 'fill-out': user.last_name, 'not-fill-out': !user.last_name }">
                </div>
              </div>
            </div>
            <div class="form-group mb-3">
              <label class="form-label">{{ translate('email') }}</label>
              <input type="email" class="form-control" :placeholder="translate('email')" required v-model="user.email"
                readonly :class="{ 'fill-out': user.email, 'not-fill-out': !user.email }">
            </div>
            <div class="form-group mb-3">
              <label class="form-label">{{ translate('last_login') }}</label>
              <input type="text" class="form-control" placeholder="Last Login" v-model="lastLoginFormatted" readonly
                style="background-color: #FEEEF6;">
            </div>

            <div class="form-group mb-3">
              <label class="form-label">{{ translate('phone') }}</label>
              <input type="text" class="form-control" :placeholder="translate('phone')" v-model="user.phone"
                :class="{ 'fill-out': user.phone, 'not-fill-out': !user.phone }">
            </div>
            <div class="form-group mb-3">
              <label class="form-label">{{ translate('number') }}</label>
              <input type="text" class="form-control" :placeholder="translate('number')" v-model="user.number"
                :class="{ 'fill-out': user.number, 'not-fill-out': !user.number }">
            </div>
            <div class="form-group mb-3">
              <label class="form-label">{{ translate('street') }}</label>
              <input type="text" class="form-control" :placeholder="translate('street')" v-model="user.street"
                :class="{ 'fill-out': user.street, 'not-fill-out': !user.street }">
            </div>
            <div class="form-group mb-3">
              <div class="row">
                <div class="col">
                  <label class="form-label">{{ translate('postal_code') }}</label>
                  <input type="text" class="form-control" :placeholder="translate('postal_code')"
                    v-model="user.postal_code"
                    :class="{ 'fill-out': user.postal_code, 'not-fill-out': !user.postal_code }">
                </div>
                <div class="col">
                  <label class="form-label">{{ translate('city') }}</label>
                  <input type="text" class="form-control" :placeholder="translate('city')" v-model="user.city"
                    :class="{ 'fill-out': user.city, 'not-fill-out': !user.city }">
                </div>
              </div>
            </div>
            <div class="form-group mb-3">
              <div class="row">
                <div class="col">
                  <label class="form-label">{{ translate('state') }}</label>
                  <input type="text" class="form-control" :placeholder="translate('state')" v-model="user.state"
                    :class="{ 'fill-out': user.state, 'not-fill-out': !user.state }">
                </div>
                <div class="col">
                  <label class="form-label">{{ translate('country') }}</label>
                  <input type="text" class="form-control fill-out" :placeholder="translate('country')"
                    :value="translate('belgium')" readonly>
                </div>
              </div>
            </div>
            <template
              v-if="user.first_name && user.last_name && user.phone && user.phone && user.number && user.street && user.postal_code && user.city && user.state">
              <div class="text-center" style="margin-top: 30px; margin-bottom: 20px;">
                <button type="submit" class="btn btn-update-custom btn-block w-50"><i class="fa fa-pencil-square-o"></i>
                  {{ translate('update') }}</button><br />
                <div class="notification is-danger" v-if="errors.length">
                  <p v-for="error in errors" :key="error">{{ error }}</p>
                </div>
              </div>
            </template>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
  
  
  
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import Swal from 'sweetalert2'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';

export default {
  mixins: [en, fr, nl],
  data() {
    return {
      user: {},
      errors: []
    }
  },
  mounted() {
    document.title = 'My profile | Eurekali';
    const element = this.$refs.scrollTarget;
    element.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo(0, 0);
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
    lastLoginFormatted() {
      if (this.user.last_login) {
        const date = new Date(this.user.last_login);
        return date.toLocaleString();
      }
      return "";
    },
  },
  async created() {

    this.$store.commit('setIsLoading', true)

    await axios.get('/api/v1/users/me/')
      .then(response => {
        this.user = response.data


      })
      .catch(error => {
        console.log(error)
      })

    this.$store.commit('setIsLoading', false)
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    showAlert() {
      if (!this.insure_my_parcel) {
        Swal.fire({
          icon: 'warning',
          title: this.translate('update_title'),
          text: this.translate('update_txt'),
          showCancelButton: true,
          confirmButtonText: this.translate('update_now'),
          cancelButtonText: this.translate('cancel'),
          confirmButtonColor: '#4BB543',
          cancelButtonColor: '#d33',
        }).then((result) => {
          if (result.isConfirmed) {
            this.updateUser()
          } else if (result.dismiss === Swal.DismissReason.cancel) {

          }

        });
      } else {
        this.setProforma()
      }
    },
    updateUser() {
      this.user.country = "Belgium"
      axios.put('/api/v1/users/me/', this.user)
        .then(response => {
          toast({
            message: this.translate('update_alert'),
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'top-right',
          })
        })
        .catch(error => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`)
            }
            console.log(JSON.stringify(error.response.data))
          } else if (error.message) {
            this.errors.push(this.translate('error'))

            console.log(JSON.stringify(error))
          }
        })
    }
  }
}
</script>

<style>.btn-update-custom {
  background-color: #E5097F;
  color: white;
  font-weight: bold;
  border-radius: 20px;
}

.btn-update-custom:hover {
  background-color: #9b0656;
  color: white;
}</style>