<template>
    <div ref="scrollTarget"></div>
    <div class="container-fluid" style="height: 100%;">
        <div class="row">
            <!-- Left Side Menu -->
            <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block sidebar">
                <div class="position-sticky" style="margin-top: 50px;">
                    <ul class="nav flex-column" style="padding-left: 5%; margin-top: 10px;">
                        <router-link to="/dashboard" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/dash1.png" class="img-item"
                                    style="margin-right: 12px;">
                                {{
                                    translate('dashboard')
                                }}</li>
                        </router-link>
                        <router-link to="/orders-validate" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/wait_icon.svg" class="img-item"> {{
                                translate('shipments_to_validate') }} <span v-if="pending_orders_notification > 0">({{
                                    pending_orders_notification }})</span>
                            </li>
                        </router-link>
                        <router-link to="/progress-orders" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/progress_icon.svg" class="img-item"> {{
                                translate('shipment_progress') }} <span v-if="progress_orders_notification > 0">({{
                                    progress_orders_notification }})</span>
                            </li>
                        </router-link>
                        <router-link to="/delivered-orders" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/Check.svg" class="img-item"> {{
                                translate('shipment_deleivred') }}
                            </li>
                        </router-link>
                        <router-link to="/commissions" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/dash4.png" class="img-item"
                                    style="margin-right: 12px;">
                                {{
                                    translate('commissions') }}</li>
                        </router-link>
                        <router-link to="/new_order" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/dash5.png" class="img-item"
                                    style="margin-right: 12px;">
                                {{
                                    translate('new_shipment') }}</li>
                        </router-link>
                        <router-link v-if="company.bonus" to="/mybonus" style="color: #000;">
                            <li class="nav-item custom active-item"><img src="../assets/img/bonus_icon.svg"
                                    class="img-item" style="margin-right: 12px;">
                                {{ translate('bonus') }}
                                <span
                                    style="margin-left: 5px; background-color: #E5097F; color: white; padding: 3px 8px 3px 8px; border-radius: 20px; font-weight: bold;">{{
                                    company.bonus_points }} pts</span>
                            </li>
                        </router-link>
                    </ul>

                    <ul class="nav flex-column" style="margin-top: 400px; padding-left: 5%; margin-bottom: 10px;">
                        <router-link to="/company" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/dash6.png" class="img-item"> {{
                                translate('my_company')
                                }}</li>
                        </router-link>
                        <li class="nav-item custom" @click="logout()"><img src="../assets/img/dash8.png"
                                class="img-item"> {{
                                    translate('sign_out') }}</li>
                    </ul>
                </div>
            </nav>

            <!-- Content Area -->
            <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4" style=" background-color: #F7F7F8;">

                <div class="row justify-content-between"
                    style="padding-right: 50px; padding-left: 50px; margin-top: 30px;">
                    <div class="col-auto">
                        <p style="font-weight: bold; color: black;">{{ translate('hello') }} {{ user.first_name }} <br>
                            <span style="font-weight: 400;">
                                {{ translate('welcome_back') }}
                            </span>
                        </p>
                    </div>
                    <router-link to="/company" class="col-auto" style="cursor: pointer;">
                        <p style="color: #E5097F; font-weight: bold;"> <i class="fa fa-building-o" aria-hidden="true"
                                style="margin-right: 10px; font-size: 25px;"></i> {{ company.company_name }} </p>
                    </router-link>
                </div>

                <div class="row justify-content-center" style="margin-top: 20px; margin-bottom: 100px;">
                    <!-- First Table -->
                    <div class="col-12 text-center">
                        <div>
                            <div class="row justify-content-center">
                                <div class="col-md-11 mb-4">
                                    <div class="card" style="border-radius: 20px; padding-top: 50px; padding-bottom: 20px;">

                                        <h2 style="font-weight: bold; color: black; font-size: large;">{{
                                            translate('bonus_title') }}</h2>
                                        <h2 style="font-weight: 400; color: black; font-size: large; margin-top: 15px;">
                                            {{ translate('bonus_subtitle') }}</h2>
                                        <div class="card-body">
                                            <div class="row justify-content-center" style="margin-top: 30px;">
                                                <div class="col-12 col-md-4">
                                                    <div class="row"
                                                        style="width: 100%; border-radius: 15px; padding: 20px; padding-bottom: 20px; background: linear-gradient(to right, #C4DFFF, #C4DFFF); color: black; height: 100%;">
                                                        <div class="custom-background1">
                                                            <div style="font-weight: bold; text-align: center;">{{
                                                                translate('my_points') }}</div>
                                                            <div
                                                                style="font-weight: bold; text-align: center; margin-top: 10px; margin-bottom: 10px; font-size: 3em;">
                                                                {{ company.bonus_points }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div class="row"
                                                        style="width: 100%; border-radius: 15px; padding: 20px; padding-bottom: 40px; background: linear-gradient(to right, #F5F5F5, #F5F5F5); color: black; height: 100%;">
                                                        <div class="custom-background2">
                                                            <div style="font-weight: bold; text-align: center;">{{
                                                                translate('remarks_bonus') }}</div>
                                                            <div
                                                                style="font-weight: 400; text-align: left; margin-top: 10px;">
                                                                <ul class="list-unstyled">
                                                                    <li><router-link class="highlight-span" to="/parrainage" style="background-color: #E5097F; color: white; cursor: pointer; padding-left: 7px; padding-right: 7px; border-radius: 5px;">&#8226; {{ translate('new_client_bonus') }} <b>{{ translate('new_client_points_bonus') }}</b></router-link></li>
                                                                    <li>&#8226; {{ translate('edit_shipment_bonus') }}
                                                                        <b>{{ translate('edit_shipment_points_bonus')
                                                                            }}</b></li>
                                                                    <li>&#8226; {{ translate('new_shipment_bonus') }}
                                                                        <b>{{ translate('new_shipment_points_bonus')
                                                                            }}</b></li>
                                                                    <li>&#8226; {{ translate('fivepoints_bonus') }}
                                                                        <b>{{ translate('fivepoints_points_bonus')
                                                                            }}</b></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <h2
                                            style="font-weight: bold; color: black; font-size: large; margin-top: 50px;">
                                            {{
                                                translate('bonus_title2') }}</h2>
                                        <h2 style="font-weight: 400; color: black; font-size: large; margin-top: 15px;">
                                            {{ translate('bonus_subtitle2') }}</h2>
                                        <div class="card-body">
                                            <div class="row justify-content-center" style="margin-top: 30px;">
                                                <div class="col-12 col-md-4">
                                                    <div class="row justify-content-center align-items-center"
                                                        style="width: 100%; border-radius: 15px; padding: 20px; background: #EC008C; height: 180px;">
                                                        <img src="../assets/img/ipx_icon.svg" style="max-width: 300px;">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div class="row justify-content-center align-items-center"
                                                        style="width: 100%; border-radius: 15px; padding: 20px; background: #EEBF3F; height: 180px;">
                                                        <img src="../assets/img/fnac_icon.svg" style="max-width: 200px;">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row justify-content-center" style="margin-top: 20px;">
                                                <div class="col-12 col-md-4">
                                                    <div class="row justify-content-center align-items-center"
                                                        style="width: 50%;">
                                                        <select class="form-select" style="width: 100%; border-radius: 20px; cursor: pointer; text-align: center;" :disabled="availablePrices.length == 0" v-model="gift_value_IPX">
                                                            <option v-for="price in availablePrices" :value="price">{{ price }} pts - {{ price/5 }}&euro;</option>
                                                        </select>
                                                        <div style="margin-top: 15px;">
                                                            <button @click="orderIPX" style="font-size: 18px; padding-left: 35px; padding-right: 35px;" class="btn btn-primary printbtn"> {{ translate('order_bonus') }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div class="row justify-content-center align-items-center"
                                                        style="width: 50%;">
                                                        <select class="form-select" style="width: 100%; border-radius: 20px; cursor: pointer; text-align: center;" :disabled="availablePrices.length == 0" v-model="gift_value_FNC">
                                                            <option v-for="price in availablePrices" :value="price">{{ price }} pts - {{ price/5 }}&euro;</option>
                                                        </select>
                                                        <div style="margin-top: 15px;">
                                                            <button @click="orderFNC" style="font-size: 18px; padding-left: 35px; padding-right: 35px;" class="btn btn-primary printbtn2"> {{ translate('order_bonus') }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="availablePrices.length == 0" style="margin-top: 50px;">
                                                <span style="color: red; font-weight: 600; font-size: large;">{{ translate('min_points') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Chart from 'chart.js/auto';
import { toast } from 'bulma-toast'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';
import Swal from 'sweetalert2'
import '@vuepic/vue-datepicker/dist/main.css';
import Datepicker from '@vuepic/vue-datepicker';
import { ref } from 'vue';

export default {
    name: 'Dashboard',
    mixins: [en, fr, nl],
    data() {
        return {
            user: {},
            today_shipments: "",
            progress_shipments: "",
            delivered_shipments: "",
            all_shipments: "",
            shipments_statistics: [],
            commissions_statistics: [],
            commission_amount: "",
            shipments: [],
            company: {},
            selectedShipment: null,
            selectedDate: "",
            shipment_id: "",
            delivery_date: "",
            transporter: "",
            availableStartTimes: [],
            availableCloseTimes: [],
            ready_time: "",
            close_time: "",
            shipment: {},
            pending_orders_notification: 0,
            progress_orders_notification: 0,
            my_points: 0,
            availablePrices : [],
            gift_value_IPX : 0,
            gift_value_FNC : 0
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    async created() {
        this.$store.commit('setIsLoading', true)
        await axios.get('/api/v1/users/me/')
            .then(async response => {
                this.user = response.data
                await this.getServicePoint(this.user.id)
            })
            .catch(error => {
                console.log(error)
            })
        this.$store.commit('setIsLoading', false)
    },
    async mounted() {
        document.title = 'Bonus';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0);
        this.shipment_id = this.$route.params.shipment_id
    
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        async getNotifications() {
            await axios.post('/api/v1/get-notifications/')
                .then(response => {
                    this.pending_orders_notification = response.data['pending_orders']
                    this.progress_orders_notification = response.data['progress_orders']
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        async getServicePoint(user_id) {
            await axios.post('/api/v1/getservicepoint/')
                .then(response => {
                    this.company = response.data
                    this.updateAvailablePrices(this.company.bonus_points)
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        updateAvailablePrices(points){
            this.availablePrices = []
            for(let i=250; i <= points; i+=5){
                this.availablePrices.push(i)
            }

            if(this.availablePrices.length > 0){
                this.gift_value_IPX = this.availablePrices[0]
                this.gift_value_FNC = this.availablePrices[0]
            }
        },
        async orderIPX(){
            const dataserv = {
        'servicepoint_id': this.company.id,
        "amount": this.gift_value_IPX,
        "description": "Request for ICI PARIS XL Gift"
      }
      this.$store.commit('setIsLoading', true)
      await axios.post('/api/v1/addbonus/', dataserv)
        .then(async response => {
            this.$router.push('/dashboard')
            toast({
          message: this.translate('bonus_alert'),
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true,
          duration: 4000,
          position: 'top-right',
        })
        })
        .catch(error => {
          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
        },
        async orderFNC(){
            const dataserv = {
        'servicepoint_id': this.company.id,
        "amount": this.gift_value_FNC,
        "description": "Request for FNAC Gift"
      }
      this.$store.commit('setIsLoading', true)
      await axios.post('/api/v1/addbonus/', dataserv)
        .then(async response => {
            this.$router.push('/dashboard')
            toast({
          message: this.translate('bonus_alert'),
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true,
          duration: 4000,
          position: 'top-right',
        })
        })
        .catch(error => {
          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
        },
        logout() {
            axios.defaults.headers.common["Authorization"] = ""
            sessionStorage.removeItem("token")
            this.$store.commit('removeToken')
            this.$store.commit('removeAgent')
            this.$store.commit('clearShipment')
            this.$router.push('/login')
        }
    }
}
</script>

<style scoped>
.custom {
    border: none;
    cursor: pointer;
    font-size: 15px;
    padding-bottom: 10px;

}

.custom:hover {
    color: #E5097F;
    font-weight: bold;
}


.active-item {
    color: #E5097F;
    font-weight: bold;
}

.img-item {
    margin-right: 8px;
}

.red-header tr {
    background-color: red;
    color: white;
}

.btn-new-ship {
    color: #E5097F;
    font-weight: bold;
    background-color: #fff;
    border: 2px solid #E5097F;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
    padding-top: 2px;
}

.btn-new-ship:hover {
    background-color: #9b0656;
    color: white;
    border: 2px solid #9b0656;
}


.btn-more {
    color: #fff;
    font-weight: bold;
    background-color: #E5097F;
    border: 2px solid #E5097F;
    margin-left: 10px;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.btn-more:hover {
    background-color: #9b0656;
    border: 2px solid #9b0656;
}

.btn-request {
    background-color: #BCF2C0;
    margin-left: 12px;
    border-radius: 20px;
    border: 2px solid #37B980;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: bold;
}

.btn-request:hover {
    background-color: #37B980;
    color: white;
}


.box-shadow-custom {
    box-shadow: 1px 1px 1px #aaa, -1px -1px 1px #aaa;
}


.bg-progress {
    background-color: #D5CB41;
}

.bg-delivered {
    background-color: #41D54B;
}

.validate-btn {
    padding: 8px;
    background-color: #14a60f;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #14a60f;
    user-select: none;
}

.validate-btn:hover {
    background-color: #0d8109;
    border: 1px solid #0d8109;
}

.modify-btn {
    padding: 8px;
    background-color: #0e5dc5;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #0e5dc5;
    user-select: none;
}

.modify-btn:hover {
    background-color: #0c4896;
    border: 1px solid #0c4896;
}

.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-body {
    display: flex;
    justify-content: space-between;
}

.column {
    width: 45%;
}

.modal-content {
    background-color: #FEEEF6;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 70%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.printbtn {
    border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    color: white;
    background-color: #E5097F;
    border: none;
    font-weight: 600;
}

.printbtn:hover {
    color: white;
    background-color: #9b0656;
}

.printbtn2 {
    border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    color: white;
    background-color: #EEBF3F;
    border: none;
    font-weight: 600;
}

.printbtn2:hover {
    color: white;
    background-color: #dba309;
}

.printbtn:focus {
    color: white;
    background-color: #9b0656;
}

.custom-background1 {
    position: relative;
    background-image: url('../assets/img/bonus_gift.svg');
    background-size: 25%;
    background-position: left bottom;
    background-repeat: no-repeat;
}

.custom-background2 {
    position: relative;
    background-image: url('../assets/img/bonus_remarks.svg');
    background-size: 25%;
    background-position: left bottom;
    background-repeat: no-repeat;
}

.highlight-span{
    animation: pulsate 1s infinite;
}
</style>