<template>
    <div ref="scrollTarget"></div>
    <div class="container-fluid" style="height: 100%;">
        <div class="row">
            <!-- Left Side Menu -->
            <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block sidebar">
                <div class="position-sticky" style="margin-top: 50px;">
                    <ul class="nav flex-column" style="padding-left: 5%; margin-top: 10px;">
                        <router-link to="/dashboard" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/dash1.png" class="img-item"
                                    style="margin-right: 12px;">
                                {{
                                    translate('dashboard')
                                }}</li>
                        </router-link>
                        <router-link to="/orders-validate" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/wait_icon.svg" class="img-item"> {{
                                translate('shipments_to_validate') }} <span v-if="pending_orders_notification > 0">({{
                                    pending_orders_notification }})</span>
                            </li>
                        </router-link>
                        <router-link to="/progress-orders" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/progress_icon.svg" class="img-item"> {{
                                translate('shipment_progress') }} <span v-if="progress_orders_notification > 0">({{
                                    progress_orders_notification }})</span>
                            </li>
                        </router-link>
                        <router-link to="/delivered-orders" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/Check.svg" class="img-item"> {{
                                translate('shipment_deleivred') }}
                            </li>
                        </router-link>
                        <router-link to="/commissions" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/dash4.png" class="img-item"
                                    style="margin-right: 12px;">
                                {{
                                    translate('commissions') }}</li>
                        </router-link>
                        <router-link to="/new_order" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/dash5.png" class="img-item"
                                    style="margin-right: 12px;">
                                {{
                                    translate('new_shipment') }}</li>
                        </router-link>
                        <router-link v-if="company.bonus" to="/mybonus" style="color: #000;">
                            <li class="nav-item custom active-item"><img src="../assets/img/bonus_icon.svg"
                                    class="img-item" style="margin-right: 12px;">
                                {{ translate('bonus') }}
                                <span
                                    style="margin-left: 5px; background-color: #E5097F; color: white; padding: 3px 8px 3px 8px; border-radius: 20px; font-weight: bold;">{{
                                        company.bonus_points }} pts</span>
                            </li>
                        </router-link>
                    </ul>

                    <ul class="nav flex-column" style="margin-top: 400px; padding-left: 5%; margin-bottom: 10px;">
                        <router-link to="/company" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/dash6.png" class="img-item"> {{
                                translate('my_company')
                            }}</li>
                        </router-link>
                        <li class="nav-item custom" @click="logout()"><img src="../assets/img/dash8.png"
                                class="img-item"> {{
                                    translate('sign_out') }}</li>
                    </ul>
                </div>
            </nav>

            <!-- Content Area -->
            <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4" style=" background-color: #F7F7F8;">

                <div class="row justify-content-between"
                    style="padding-right: 50px; padding-left: 50px; margin-top: 30px;">
                    <div class="col-auto">
                        <p style="font-weight: bold; color: black;">{{ translate('hello') }} {{ user.first_name }} <br>
                            <span style="font-weight: 400;">
                                {{ translate('welcome_back') }}
                            </span>
                        </p>
                    </div>
                    <router-link to="/company" class="col-auto" style="cursor: pointer;">
                        <p style="color: #E5097F; font-weight: bold;"> <i class="fa fa-building-o" aria-hidden="true"
                                style="margin-right: 10px; font-size: 25px;"></i> {{ company.company_name }} </p>
                    </router-link>
                </div>

                <div class="row justify-content-center" style="margin-top: 20px; margin-bottom: 100px;">
                    <!-- First Table -->
                    <div class="col-12 text-center">
                        <div>
                            <div class="row justify-content-center">
                                <div class="col-md-11 mb-4">
                                    <div class="card"
                                        style="border-radius: 20px; padding-top: 50px; padding-bottom: 20px;">

                                        <div class="card-body">
                                            <div class="row justify-content-center" style="margin-top: 30px;">
                                                <div class="col-12 col-md-3">
                                                    <img src="../assets/img/100pts_icon.svg" style="max-width: 250px;">
                                                </div>
                                                <div class="col-12 col-md-5 d-flex align-items-center">
                                                    <div style="text-align: left;">
                                                        <p style="color: black; font-size: x-large; font-weight: bold;">
                                                            {{ translate('parrainage_title') }}</p>
                                                        <p style="color: black; font-weight: 400; margin-top: 15px;">{{
                                                            translate('parrainage_stitle') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style="padding-left: 300px; padding-right: 300px;"><h2 style="font-weight: bold; color: #E5097F; font-size: large; margin-top: 50px;">{{ translate('parrainage_stitle2') }}</h2></div>

                                        <div class="card-body">
                                            <div class="row justify-content-center" style="margin-top: 30px;">
                                                <div class="col-12 col-md-4">
                                                    <div class="border shadow" style="text-align: left; padding: 30px; border-radius: 15px;">
                                                        <p style="font-weight: bold; color: black; margin-bottom: 15px;">{{ translate('parrainage_your_info') }}</p>
                                                        <input type="text" class="form-control custom-input" :placeholder="translate('parrainage_your_name')" v-model="agent_name">
                                                        <input type="text" class="form-control custom-input" :placeholder="translate('parrainage_your_email')" v-model="agent_email">
                                                        <input type="text" class="form-control custom-input" :placeholder="translate('phone')" v-model="agent_phone">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div class="border shadow" style="text-align: left; padding: 30px; border-radius: 15px;">
                                                        <p style="font-weight: bold; color: black; margin-bottom: 15px;">{{ translate('parrainage_agent_info') }}</p>
                                                        <input type="text" class="form-control custom-input" :placeholder="translate('parrainage_agent_name')" v-model="new_agent_name">
                                                        <input type="text" class="form-control custom-input" :placeholder="translate('parrainage_agent_address')" v-model="new_agent_address">
                                                        <input type="text" class="form-control custom-input" :placeholder="translate('parrainage_agent_email')" v-model="new_agent_email">
                                                        <input type="text" class="form-control custom-input" :placeholder="translate('phone')" v-model="new_agent_phone">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="margin-top: 20px;">
                                            <template v-if="agent_name && agent_email && agent_phone && new_agent_name && new_agent_address && new_agent_email && new_agent_phone">
                                                <button class="send-btn" @click="AddParrainage">{{ translate('parrainage_send') }}</button>
                                            </template>
                                            <template v-else>
                                                <button class="send-btn-deactivate">{{ translate('parrainage_send') }}</button>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="showNotification" class="modal">
          <div class="modal-content">
            <div style="text-align: center;">
                <span style="text-align: right;" @click="closeModal" class="close">&times;</span>
                <p style="color: #E5097F; font-weight: bold; margin-top: 20px;">{{ translate('parrainage_not_txt1') }}</p>
              <p style="color: #E5097F; font-weight: bold;">{{ translate('parrainage_not_txt2') }}</p>
            </div>
            
          </div>
        </div>
            </main>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Chart from 'chart.js/auto';
import { toast } from 'bulma-toast'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';
import Swal from 'sweetalert2'
import '@vuepic/vue-datepicker/dist/main.css';
import Datepicker from '@vuepic/vue-datepicker';
import { ref } from 'vue';

export default {
    name: 'Dashboard',
    mixins: [en, fr, nl],
    data() {
        return {
            user: {},            
            company: {},
            selectedShipment: null,
            shipment: {},
            pending_orders_notification: 0,
            progress_orders_notification: 0,
            my_points: 0,
            agent_name : "",
            agent_email : "",
            agent_phone : "",
            new_agent_name : "",
            new_agent_address : "",
            new_agent_email : "",
            new_agent_phone : "",
            showNotification: false
            

        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    async created() {
        this.$store.commit('setIsLoading', true)
        await axios.get('/api/v1/users/me/')
            .then(async response => {
                this.user = response.data
                await this.getServicePoint(this.user.id)
            })
            .catch(error => {
                console.log(error)
            })
        this.$store.commit('setIsLoading', false)
    },
    async mounted() {
        document.title = 'Parrainage';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0);

    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        async getNotifications() {
            await axios.post('/api/v1/get-notifications/')
                .then(response => {
                    this.pending_orders_notification = response.data['pending_orders']
                    this.progress_orders_notification = response.data['progress_orders']
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        async getServicePoint(user_id) {
            await axios.post('/api/v1/getservicepoint/')
                .then(response => {
                    this.company = response.data
                    this.updateAvailablePrices(this.company.bonus_points)
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        resetForm(){
            this.agent_name = ""
            this.agent_email = ""
            this.agent_phone = ""
            this.new_agent_name = ""
            this.new_agent_address = ""
            this.new_agent_email = ""
            this.new_agent_phone = ""
        },
        async AddParrainage() {
            this.$store.commit('setIsLoading', true)
            const dataserv = {
                'servicepoint_id': this.company.id,
                "agent_name" : this.agent_name,
                "agent_email" : this.agent_email,
                "agent_phone" : this.agent_phone,
                "new_agent_name" : this.new_agent_name,
                "new_agent_address" : this.new_agent_address,
                "new_agent_email" : this.new_agent_email,
                "new_agent_phone" : this.new_agent_phone
            }
            await axios.post('/api/v1/add-parrainage-agent/', dataserv)
                .then(async response => {
                    this.resetForm()
                    this.showNotification = true
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        closeModal(){
            this.showNotification = false
        },
        logout() {
            axios.defaults.headers.common["Authorization"] = ""
            sessionStorage.removeItem("token")
            this.$store.commit('removeToken')
            this.$store.commit('removeAgent')
            this.$store.commit('clearShipment')
            this.$router.push('/login')
        }
    }
}
</script>

<style scoped>
.custom {
    border: none;
    cursor: pointer;
    font-size: 15px;
    padding-bottom: 10px;

}

.custom:hover {
    color: #E5097F;
    font-weight: bold;
}


.active-item {
    color: #E5097F;
    font-weight: bold;
}

.img-item {
    margin-right: 8px;
}

.red-header tr {
    background-color: red;
    color: white;
}

.btn-new-ship {
    color: #E5097F;
    font-weight: bold;
    background-color: #fff;
    border: 2px solid #E5097F;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
    padding-top: 2px;
}

.btn-new-ship:hover {
    background-color: #9b0656;
    color: white;
    border: 2px solid #9b0656;
}


.btn-more {
    color: #fff;
    font-weight: bold;
    background-color: #E5097F;
    border: 2px solid #E5097F;
    margin-left: 10px;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.btn-more:hover {
    background-color: #9b0656;
    border: 2px solid #9b0656;
}

.btn-request {
    background-color: #BCF2C0;
    margin-left: 12px;
    border-radius: 20px;
    border: 2px solid #37B980;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: bold;
}

.btn-request:hover {
    background-color: #37B980;
    color: white;
}


.box-shadow-custom {
    box-shadow: 1px 1px 1px #aaa, -1px -1px 1px #aaa;
}


.bg-progress {
    background-color: #D5CB41;
}

.bg-delivered {
    background-color: #41D54B;
}

.validate-btn {
    padding: 8px;
    background-color: #14a60f;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #14a60f;
    user-select: none;
}

.validate-btn:hover {
    background-color: #0d8109;
    border: 1px solid #0d8109;
}

.modify-btn {
    padding: 8px;
    background-color: #0e5dc5;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #0e5dc5;
    user-select: none;
}

.modify-btn:hover {
    background-color: #0c4896;
    border: 1px solid #0c4896;
}

.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-body {
    display: flex;
    justify-content: space-between;
}

.column {
    width: 45%;
}

.modal-content {
    background-color: #FEEEF6;
    margin: 15% auto;
    padding: 30px;
    border: 1px solid #888;
    border-radius: 20px;
    width: 25%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.printbtn {
    border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    color: white;
    background-color: #E5097F;
    border: none;
    font-weight: 600;
}

.printbtn:hover {
    color: white;
    background-color: #9b0656;
}

.printbtn2 {
    border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    color: white;
    background-color: #EEBF3F;
    border: none;
    font-weight: 600;
}

.printbtn2:hover {
    color: white;
    background-color: #dba309;
}

.printbtn:focus {
    color: white;
    background-color: #9b0656;
}

.custom-background1 {
    position: relative;
    background-image: url('../assets/img/bonus_gift.svg');
    background-size: 25%;
    background-position: left bottom;
    background-repeat: no-repeat;
}

.custom-background2 {
    position: relative;
    background-image: url('../assets/img/bonus_remarks.svg');
    background-size: 25%;
    background-position: left bottom;
    background-repeat: no-repeat;
}

.custom-input{
    background-color: #F5F5F5;
    padding: 10px;
    margin-bottom: 20px;
}
.send-btn{
    background-color: #E5097F;
    color: white;
    border-radius: 10px;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    font-size: large;
}
.send-btn:hover{
    background-color: #9b0656;
}


.send-btn-deactivate{
    background-color: gray;
    color: white;
    border-radius: 10px;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    font-size: large;
    cursor: not-allowed;
}
</style>