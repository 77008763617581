<template>
    <section style="margin-top: 30px; margin-bottom: 100px;">
        <div ref="scrollTarget"></div>
        <div class="container mt-4">
            <div class="card mb-1">
                <div class="card-header py-3" style="background-color: #E5097F">
                    <h5 class="mb-0" style="color: white; font-weight: bold;"><i class="fa fa-th-list"
                            aria-hidden="true"></i> {{ translate('validate_info') }}</h5>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-6 mb-1">
                        <div class="card-body"
                            style="background-color: #F5F5F5; margin: 15px; height: 90%; position: relative;">
                            <form>
                                <div class="row mb-1 justify-content-center">
                                    <div class="col-sm-8 mb-1">
                                        <div class="form-group text-center">
                                            <label class="form-label" for="form7Example1"> {{ translate('ship_from')
                                            }}</label>
                                            <input type="text" id="form7Example1" class="form-control"
                                                placeholder="Choose a country" v-model="shipment.ship_from"
                                                style="background-color: #FEEEF6; text-align: center; color: #E5097F; font-weight: bold;">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-1 justify-content-center">
                                    <div class="col-sm-8 mb-1">
                                        <div class="form-group text-center">
                                            <label class="form-label" for="form7Example2"> {{ translate('ship_to')
                                            }}</label>
                                            <input type="text" id="form7Example2" class="form-control" readonly
                                                placeholder="Choose a country" v-model="shipment.country"
                                                style="background-color: #FEEEF6; text-align: center; color: #E5097F; font-weight: bold;">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="col-md-6 mb-1">
                        <div class="card-body"
                            style="background-color: #F5F5F5; margin: 15px; height: 90%; position: relative;">
                            <form>
                                <div class="row mb-1 justify-content-center">
                                    <div class="col-sm-8 mb-1">
                                        <div class="form-group text-center">
                                            <img src="../assets/img/pack.svg">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-1 justify-content-center">
                                    <div class="col-sm-8 mb-1">
                                        <div class="form-group text-center">
                                            <label class="form-label" for="form7Example2"></label>
                                            <select class="form-control"
                                                style="text-align: center; color: #E5097F; font-weight: 600;"
                                                :class="{ 'fill-out': package_type, 'not-fill-out': !package_type }"
                                                v-model="package_type">
                                                <option value="Envelope">Envelope, (Max 0.5 kg)</option>
                                                <option value="Extra small"> Extra small, (Max 1.0 kg)</option>
                                                <option value="Small">Small, (Max 2.0 kg)</option>
                                                <option value="Medium"> Medium, (Max 5.0 kg) </option>
                                                <option value="Large"> Large, (Max 10 kg)</option>
                                                <option value="XL"> XL, (Max 20 kg) </option>
                                                <option value="XXL"> XXL, (Max 25 kg) </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <p style="font-weight: bold; text-align: center;"> {{ translate('max_weight') }} {{
                                    max_weight }} Kg</p>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center" style="margin-top: 15px;">
                    <div class="col-md-12 mb-1">
                        <div class="card-body"
                            style="background-color: #F5F5F5; margin: 15px; height: 90%; position: relative;">
                            <form>
                                <div class="row">
                                    <div class="col-sm-6 mb-1">
                                        <div class="row mb-1">
                                            <div class="form-group">
                                                <label class="form-label">{{ translate('package_description') }}</label>
                                                <textarea type="text" class="form-control"
                                                    :placeholder="translate('placeholder_description')" rows="2"
                                                    v-model="shipment.description"
                                                    style="background-color: #FEEEF6;"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" style="margin-top: 15px;">
                                    <template v-if="commercial_value">
                                        <div class="row mb-4">
                                            <div class="col-12 col-sm-6 col-md-3">
                                                <div class="form-outline">
                                                    <label class="form-label">{{ translate('nature_package') }}</label>
                                                    <input type="text" class="form-control" v-model="item_nature"
                                                        style="background-color: #FEEEF6;">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-3">
                                                <div class="form-outline">
                                                    <label class="form-label">{{ translate('remark') }}</label>
                                                    <input type="text" class="form-control" v-model="item_description"
                                                        style="background-color: #FEEEF6;">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-2">
                                                <div class="form-outline">
                                                    <label class="form-label">HS Code</label>
                                                    <input type="text" class="form-control" v-model="item_code"
                                                        style="background-color: #FEEEF6;">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-2">
                                                <div class="form-outline">
                                                    <label class="form-label"> {{ translate('number_items') }}</label>
                                                    <input type="number" class="form-control" v-model="item_number"
                                                        style="background-color: #FEEEF6;">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-2">
                                                <div class="form-outline">
                                                    <label class="form-label">{{ translate('unit_price') }}</label>
                                                    <input type="number" class="form-control" v-model="item_price"
                                                        style="background-color: #FEEEF6;">
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <template v-if="additional_price && payed_price && shipment_price && !payment_type">
                <div class="row justify-content-center" style="margin-top: 20px;">
                    <div class="col-md-4 mb-4">
                        <div class="card mb-4">
                            <div class="card-body">
                                <ul class="list-group list-group-flush">

                                    <li
                                        class="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                        <div>
                                            <strong style="color: #E5097F;">{{ translate('paid_price') }}</strong>
                                        </div>
                                        <span><strong style="color: #E5097F;"> {{ payed_price }} <span v-if="shipment.ship_from === 'Belgium'">&euro;</span><span v-else>MAD</span></strong></span>
                                    </li>
                                    <li
                                        class="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                        <div>
                                            <strong style="color: #E5097F;">{{ translate('shipment_price') }}</strong>
                                        </div>
                                        <span><strong style="color: #E5097F;"> {{ shipment_price }} <span v-if="shipment.ship_from === 'Belgium'">&euro;</span><span v-else>MAD</span></strong></span>
                                    </li>


                                    <li class="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-1"
                                        style="margin-top: 20px;">
                                        <div>
                                            <strong style="color: #E5097F;">{{ translate('price_to_pay') }}</strong>
                                        </div>
                                        <span><strong style="color: #E5097F;">{{ additional_price }}
                                                <span v-if="shipment.ship_from === 'Belgium'">&euro;</span><span v-else>MAD</span></strong></span>
                                    </li>

                                </ul>

                                <template v-if="this.shipment.description">
                                    <button to="/payment" type="button" class="btn btn-next-active btn-lg btn-block"
                                        @click="ModifyShipment">
                                        <i class="fa fa-check-square-o"></i> {{ translate('validate_payment')
                                        }}
                                    </button>
                                </template>
                                <template v-else>
                                    <button type="button" class="btn btn-next-deactive btn-lg btn-block" @click="showalert">
                                        <i class="fa fa-check-square-o"></i> {{ translate('validate_payment')
                                        }}
                                    </button>
                                </template>

                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="additional_price && payed_price && shipment_price && payment_type">
                <div class="row justify-content-center" style="margin-top: 20px;">
                    <div class="col-md-4 mb-4">
                        <div class="card mb-4">
                            <div class="card-body">
                                <ul class="list-group list-group-flush">

                                    <li class="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-1"
                                        style="margin-top: 20px;">
                                        <div>
                                            <strong style="color: #E5097F;">{{ translate('price_to_pay') }}</strong>
                                        </div>
                                        <span><strong style="color: #E5097F;">{{ shipment_price }}
                                                <span v-if="shipment.ship_from === 'Belgium'">&euro;</span><span v-else>MAD</span></strong></span>
                                    </li>

                                </ul>

                                <template v-if="this.shipment.description">
                                    <button to="/payment" type="button" class="btn btn-next-active btn-lg btn-block"
                                        @click="ModifyShipment">
                                        <i class="fa fa-check-square-o"></i> {{ translate('validate_payment')
                                        }}
                                    </button>
                                </template>
                                <template v-else>
                                    <button type="button" class="btn btn-next-deactive btn-lg btn-block" @click="showalert">
                                        <i class="fa fa-check-square-o"></i> {{ translate('validate_payment')
                                        }}
                                    </button>
                                </template>

                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>
  
  
  
<script>
import axios from 'axios'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';
import { toast } from 'bulma-toast'
import Swal from 'sweetalert2'


export default {
    name: 'NewOrder',
    mixins: [en, fr, nl],
    data() {
        return {
            shipment_id: "",
            shipment: {},
            sender: {},
            receiver: {},
            user: {},
            package_type: "",
            max_weight: "",
            additional_price: "",
            payed_price: "",
            shipment_price: "",
            assurance_text: "",
            payment_type: ""
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },

    },
    watch: {
        package_type(newValue, oldValue) {
            if (this.package_type == "Envelope") {
                this.max_weight = 0.5
            }

            if (this.package_type == "Extra small") {
                this.max_weight = 1.0
            }

            if (this.package_type == "Small") {
                this.max_weight = 2.0
            }

            if (this.package_type == "Medium") {
                this.max_weight = 5.0
            }

            if (this.package_type == "Large") {
                this.max_weight = 10.0
            }

            if (this.package_type == "XL") {
                this.max_weight = 20.0
            }

            if (this.package_type == "XXL") {
                this.max_weight = 25.0
            }
        },
        max_weight(newValue, oldValue) {
            this.calculatePrice()
        }
    },
    mounted() {
        document.title = 'Validate | Eurekali'
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

        this.shipment_id = this.$route.params.shipment_id
        this.getMyshipment()
        this.getPaymentType(this.shipment_id)

    },

    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        async getMyshipment() {
            await axios.post(`/api/v1/get-shipment/${this.shipment_id}/`)
                .then(response => {
                    this.shipment = response.data['shipment']
                    this.package_type = this.shipment.package_type
                    this.max_weight = this.shipment.max_weight
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                    this.$router.push('/dashboard')
                })
        },
        async calculatePrice() {
            this.$store.commit('setIsLoading', true)
            if (this.shipment.insurance_option) {
                this.assurance_text = "yes"
            } else {
                this.assurance_text = "no"
            }
            let weight = this.max_weight;
            let country_origin_code = this.shipment.ship_from === 'Belgium' ? 'BE' : 'MA';
            await axios.post(`/api/v1/calculate-price/${this.shipment_id}/${country_origin_code}/${this.shipment.country}/${weight}/`)
                .then(response => {
                    this.additional_price = response.data['additional_price']
                    this.payed_price = response.data['payed_price']
                    this.shipment_price = response.data['shipment_price']
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
                this.$store.commit('setIsLoading', false)
        },
        async ValidateShipment(shipment_id) {
      this.$store.commit('setIsLoading', true)
      await axios.post(`/api/v1/validate-shipment/${shipment_id}/`)
        .then(response => {
          toast({
            message: this.translate('shipment_validated'),
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: 'top-right',
          })
          this.$router.push('/progress-orders')
        })
        .catch(error => {
          toast({
            message: "Something went wrong",
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: 'top-right',
          })
        })
      this.$store.commit('setIsLoading', false)
    },
        async ModifyShipment() {
            this.$store.commit('setIsLoading', true)

            let weight = this.max_weight
            await axios.post(`/api/v1/modify-shipment/${this.shipment_id}/${this.shipment.country}/${weight}/${this.shipment.description}/${this.package_type}/`)
                .then(response => {
                    toast({
                        message: this.translate('shipment_modified'),
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 3000,
                        position: 'top-right',
                    })

                    if(this.shipment.ship_from == 'Morocco'){
        this.ValidateShipment(this.shipment_id)
      }
      if(this.shipment.ship_from == 'Belgium'){
        this.$router.push('/validate-shipment/' + this.shipment_id);
      }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            this.$store.commit('setIsLoading', false)
        },
        async getPaymentType(shipment_id) {
            await axios.post(`/api/v1/get-payment-type/${shipment_id}/`)
                .then(response => {
                    this.payment_type = response.data['cash_payment']
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        showalert() {
            toast({
                message: this.translate('alert18'),
                type: 'is-danger',
                dismissible: true,
                pauseOnHover: true,
                duration: 3000,
                position: 'top-right',
            })
        }
    }
}
</script>
  

<style>
.btn-edit {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #E5097F;
    border-color: #E5097F;
    border-top-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 0px;
    color: white;
}

.btn-edit:hover {
    background-color: #9b0656;
    color: white;
}

.button-text {
    display: none;
}

.btn-edit:hover .button-text {
    display: inline;
}

.btn-edit {
    transition: all 0.5s ease-in-out;
}

.fill-out {
    background-color: #FEEEF6;
}

.not-fill-out {
    background-color: #F5F5F5;
}

.btn-next-active {
    width: 100%;
    margin-top: 20px;
    background-color: #E5097F;
    color: white;
    font-weight: 600;
    padding: 2px;
    border-radius: 30px;
}

.btn-next-active:hover {
    background-color: #9b0656;
    color: white;
}

.btn-next-deactive {
    width: 100%;
    margin-top: 20px;
    background-color: gray;
    color: white;
    font-weight: 600;
    padding: 2px;
    border-radius: 30px;
}

.btn-next-deactive:hover {
    background-color: gray;
    color: white;
}

.modify-btn2 {
    padding: 0;
    background-color: #0e5dc5;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #0e5dc5;
    user-select: none;
    font-weight: 600;
}

.modify-btn2:hover {
    background-color: #0c4896;
    border: 1px solid #0c4896;
    color: white;
}
</style>


