<template>
  <div class="container">
    <!-- Table displaying only the student names -->
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(student, index) in students" :key="index" @click="selectStudent(student)">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ student.name }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Modal displaying the selected student's details -->
    <div v-if="selectedStudent" class="modal">
    <div class="modal-content">
      <span style="text-align: right;" @click="closeModal" class="close">&times;</span>
      <div class="modal-body">
        <div class="column">
          <p><strong>Name:</strong> {{ selectedStudent.name }}</p>
          <p><strong>Age:</strong> {{ selectedStudent.age }}</p>
        </div>
        <div class="column">
          <p><strong>Mark:</strong> {{ selectedStudent.mark }}</p>
          <p><strong>Class:</strong> {{ selectedStudent.class }}</p>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'StudentTable',
  data() {
    return {
      students: [
      { name: 'John Doe', age: 15, mark: 85, class: '10A' },
        { name: 'Jane Smith', age: 16, mark: 90, class: '10B' },
        { name: 'Jack sergio', age: 18, mark: 100, class: '10B' },
        // Add more students as necessary
      ],
      selectedStudent: null,
    };
  },
  methods: {
    selectStudent(student) {
      this.selectedStudent = student;
    },
    closeModal() {
      this.selectedStudent = null;
    },
  },
};
</script>

<style scoped>
/* Style for the modal */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-body {
  display: flex;
  justify-content: space-between;
}

.column {
  width: 45%; /* Or whatever width you prefer */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
