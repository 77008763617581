<template>
    <section style="margin-top: 30px; margin-bottom: 100px;">

        <div class="container mt-4">
            <div class="row justify-content-center">
                <div class="col-md-6 col-sm-12 mb-4">
                    <div class="p-4 rounded text-center" style="box-shadow: 1px 1px 1px #888888, -1px -1px 1px #888888; font-weight: bold;">
                        


                        <div style="margin-top: 15px;">
                        {{ translate('forgot_password') }}
                        <router-link to="/reset_password" class="btn btn-login2 " type="submit">
                            <i class="fa fa-lock" aria-hidden="true"></i> {{ translate('reset_password') }}
                        </router-link>
                        </div>

                        <div style="margin-top: 15px;">
                        {{ translate('dont_have_account') }}
                        <router-link to="/signup" class="btn btn-login2 " type="submit">
                            <i class="fa fa-user-plus" aria-hidden="true"></i> {{ translate('sign_up') }}
                        </router-link>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-md-6 col-sm-12 mb-4">
                    <div class="p-4 rounded text-center" style="box-shadow: 1px 1px 1px #888888, -1px -1px 1px #888888;">
                        <div>
                            <img src="../assets/img/signup.png" alt="Signup Image">
                        </div>
                        <form @submit.prevent="submitForm" style="margin-top: 20px;">
                            <div class="form-group mb-3">
                        </div>
                        <div class="form-group mb-3">
                            <input type="email" class="form-control" :placeholder="translate('email')" required v-model="email" :class="{ 'fill-out': email, 'not-fill-out': !email }">
                        </div>
                        <div class="form-group mb-3">
                            <input type="password" class="form-control"  :placeholder="translate('password')" required v-model="password" :class="{ 'fill-out': password, 'not-fill-out': !password }">
                        </div>

                        
                        <div class="text-center mt-3">
                            <button type="submit" class="btn btn-login2 me-2">{{ translate('login') }}</button>
                            <br>
                            <div class="notification is-danger" v-if="errors.length" style="margin-top: 20px;">
                                <p v-for="error in errors" :key="error">{{ error }}</p>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
    name: 'LogIn',
    mixins: [en, fr, nl],
    data() {
        return {
            email: '',
            password: '',
            user : {},
            errors: []
        }
    },
    mounted() {
        document.title = 'Log In | Eurekali'
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        async submitForm() {
            axios.defaults.headers.common["Authorization"] = ""
            sessionStorage.removeItem("token")
            const formData = {
                email: this.email,
                password: this.password
            }
            await axios
                .post("/api/v1/token/login/", formData)
                .then(async response => {
                    const token = response.data.auth_token
                    this.$store.commit('setToken', token)

                    axios.defaults.headers.common["Authorization"] = "Token " + token
                    sessionStorage.setItem("token", token)
                    
                    this.$store.commit('setIsLoading', true)
                    await axios.get('/api/v1/users/me/')
                    .then(response => {
                        this.user = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    this.$store.commit('setIsLoading', false)

                    this.$store.commit('setAgent', this.user.is_agent)
                    
                    const toPath = this.$route.query.to || '/dashboard'
                    this.$router.push(toPath)
                })
                .catch(error => {
                    if (error.response) {
                        this.errors.push(this.translate('login_error'))
                    } else {
                        this.errors.push('Something went wrong. Please try again')

                        console.log(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>


