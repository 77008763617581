<template>
    
  <template v-if="user.is_agent">
      <AgnNav />
  </template>
  <template v-else>
      <PartNav />
  </template>
</template>


<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import PartNav from '@/components/PartNav.vue'
import AgnNav from '@/components/AgnNav.vue'


export default {
  components: {
  
  PartNav,
  AgnNav,
  
},
data() {
  return {
    user: {},
    errors: []
  }
},
mounted() {
  document.title = 'My profile | Eurekali';
},
async created() {

  this.$store.commit('setIsLoading', true)

  await axios.get('/api/v1/users/me/')
    .then(response => {
      this.user = response.data


    })
    .catch(error => {
      console.log(error)
    })

  this.$store.commit('setIsLoading', false)
},
}
</script>
  
