import { createStore } from 'vuex'

export default createStore({
  state: {
    lang: 'fr',
    shipment: {
      origin_country: "",
      country: "",
      package_type: "",
      weight: "",
      description: "",
      insure_my_parcel: false,
      home_collection: false,
      printer_option: false,
      read_text: false,
      safe_parcel: false,
      read_conditions: false,
      assurance_amount: "",
      home_collection_amount: "",
      printer_amount: "",
      printer: false,
      payment_cash: false,
      price: "",
      old_price2: "",
      VAT: "",
      service_point_id: "",
      address_search: "",
      servicepoint_address: "",
      express: false,
      s_postal_code: "",
      r_postal_code: "",

      sender: {
        number: "",
        street: "",
        postal_code: "",
        city: "",
        state: "",
        country: "",
        email: "",
        phone: "",
        extention: "",
        first_name: "",
        last_name: ""
      },
      receiver: {
        number: "",
        street: "",
        postal_code: "",
        city: "",
        state: "",
        country: "",
        email: "",
        phone: "",
        extention: "",
        first_name: "",
        last_name: ""
      },
      proforma: {
        item_nature: "",
        item_description: "",
        item_code: "",
        item_number: "",
        item_price: "",
      },
    },
    isAuthenticated: false,
    isAgent: false,
    token: '',
    isLoading: false
  },
  mutations: {
    setLang(state, newLang) {
      state.lang = newLang;
    },
    initializeStore(state) {
      if (sessionStorage.getItem('shipment')) {
        state.shipment = JSON.parse(sessionStorage.getItem('shipment'))
      } else {
        sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
      }

      if (sessionStorage.getItem('token')) {
        state.token = sessionStorage.getItem('token')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    setAgent(state, token) {
      state.isAgent = token
    },
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
    },
    removeAgent(state) {
      state.isAgent = false
    },
    setCountry(state, payload) {
      state.shipment.insure_my_parcel = false,
        state.printer_option = false,
        state.home_collection = false,
        state.shipment.printer_amount = "",
        state.shipment.assurance_amount = "",
        state.shipment.home_collection_amount = "",
        state.shipment.price = "",
        state.shipment.old_price2 = "",
        state.shipment.VAT = "",
        state.shipment.service_point_id = ""
        state.shipment.servicepoint_address = ""

        state.shipment.origin_country = payload.origin_country
        state.shipment.country = payload.new_country
        

      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    setPackage(state, payload) {
      state.shipment.printer_amount = "",
        state.shipment.assurance_amount = "",
        state.shipment.home_collection_amount = "",
        state.shipment.price = "",
        state.shipment.old_price2 = "",
        state.shipment.VAT = "",
        state.shipment.weight = payload.weight
      state.shipment.package_type = payload.package_type
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    setDimensions(state, payload) {
      state.shipment.description = payload.description
      state.shipment.printer = payload.printer
      state.shipment.insure_my_parcel = payload.insure_my_parcel
      state.shipment.home_collection = payload.home_collection
      state.shipment.assurance_amount = payload.assurance_amount
      state.shipment.home_collection_amount = payload.home_collection_amount,
        state.shipment.printer_option = payload.printer_option,
        state.shipment.printer_amount = payload.printer_amount,
        sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    setExpress(state, payload) {
      state.shipment.express = payload.express
      state.shipment.s_postal_code = payload.s_postal_code
      state.shipment.r_postal_code = payload.r_postal_code
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    setPrice(state, price) {
      state.shipment.price = price
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    setread_text(state, read_text) {
      state.shipment.read_text = read_text
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    setsafe_parcel(state, safe_parcel) {
      state.shipment.safe_parcel = safe_parcel
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    setread_conditions(state, read_conditions) {
      state.shipment.read_conditions = read_conditions
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    setOldPrice(state, old_price2) {
      state.shipment.old_price2 = old_price2
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    setVAT(state, VAT) {
      state.shipment.VAT = VAT
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    setAddress_search(state, address_search) {
      state.shipment.address_search = address_search
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    setServicepoint_address(state, servicepoint_address) {
      state.shipment.servicepoint_address = servicepoint_address
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    setSender(state, payload) {
      state.shipment.sender = {
        number: payload.number,
        street: payload.street,
        postal_code: payload.postal_code,
        city: payload.city,
        state: payload.state,
        country: payload.country,
        email: payload.email,
        phone: payload.phone,
        extention: payload.extention,
        first_name: payload.first_name,
        last_name: payload.last_name,
      };
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment));
    },
    setReceiver(state, payload) {
      state.shipment.receiver = {
        number: payload.number,
        street: payload.street,
        postal_code: payload.postal_code,
        city: payload.city,
        state: payload.state,
        country: payload.country,
        email: payload.email,
        phone: payload.phone,
        extention: payload.extention,
        first_name: payload.first_name,
        last_name: payload.last_name
      };
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment));
    },
    setProforma(state, payload) {
      state.shipment.proforma = {
        item_nature: payload.item_nature,
        item_description: payload.item_description,
        item_code: payload.item_code,
        item_number: payload.item_number,
        item_price: payload.item_price
      };
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment));
    },
    setServicePoint(state, service_point_id) {
      state.shipment.service_point_id = service_point_id
      state.shipment.payment_cash = false
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    setcashpayment(state, status) {
      state.shipment.payment_cash = status
      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    setShipmentInstance(state, payload) {
      state.shipment.origin_country = payload.origin_country
      state.shipment.country = payload.country
      state.shipment.package_type = payload.package_type
      state.shipment.weight = payload.weight
      state.shipment.description = payload.description
      state.shipment.insure_my_parcel = payload.insure_my_parcel
      state.shipment.home_collection = payload.home_collection
      state.shipment.read_text = payload.read_text
      state.shipment.safe_parcel = payload.safe_parcel
      state.shipment.read_conditions = payload.read_conditions
      state.shipment.assurance_amount = payload.assurance_amount
      state.shipment.home_collection_amount = payload.home_collection_amount
      state.shipment.printer_amount = payload.printer_amount
      state.shipment.printer_option = payload.printer_option
      state.shipment.payment_cash = payload.payment_cash
      state.shipment.price = payload.price
      state.shipment.old_price2 = payload.old_price2
      state.shipment.VAT = payload.VAT
      state.shipment.service_point_id = payload.service_point_id
      state.shipment.address_search = payload.address_search
      state.shipment.servicepoint_address = payload.servicepoint_address
      state.shipment.express = payload.express
      state.shipment.s_postal_code = payload.s_postal_code
      state.shipment.r_postal_code = payload.r_postal_code

      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
    clearShipment(state) {
      state.shipment = {
        origin_country: "",
        country: "",
        package_type: "",
        weight: "",
        description: "",
        insure_my_parcel: false,
        home_collection: false,
        printer_option: false,
        read_text: false,
        safe_parcel: false,
        read_conditions: false,
        assurance_amount: "",
        home_collection_amount: "",
        printer_amount: "",
        price: "",
        old_price2: "",
        VAT: "",
        service_point_id: "",
        payment_cash: false,
        express: false,
        s_postal_code: "",
        r_postal_code: "",
        sender: "",
        receiver: "",
        proforma: "",
      }

      sessionStorage.setItem('shipment', JSON.stringify(state.shipment))
    },
  },
  actions: {
  },
  modules: {
  }
})
