<template>
  <div>


  </div>
</template>


<script>
import axios from 'axios'


export default {
  data() {
    return {
      shipment: {},
      shipmentinstance_id: "",
      errors: []
    }
  },
  mounted() {
    document.title = 'My profile | Eurekali';
  },
  async created() {

    this.shipmentinstance_id = this.$route.params.shipment_id

    this.$store.commit('setIsLoading', true)
    await axios.get(`/api/v1/shipmentinstance/${this.shipmentinstance_id}/`)
      .then(response => {
        this.shipment = response.data

        this.setSender()
        this.setReceiver()
        if (this.shipment.proforma) {
          this.setProforma()
        }
        this.setShipmentInstance()

        this.$router.push('/validate')

      })
      .catch(error => {
        this.$router.push('/new_order')
        console.log(error)
      })
    this.$store.commit('setIsLoading', false)
  },
  methods: {
    setSender() {
      console.log('setSender')
      const number = this.shipment.sender.number
      const street = this.shipment.sender.street
      const postal_code = this.shipment.sender.postal_code
      const city = this.shipment.sender.city
      const state = this.shipment.sender.state
      const country = this.shipment.sender.country
      const email = this.shipment.sender.email
      const first_name = this.shipment.sender.first_name
      const last_name = this.shipment.sender.last_name
      const extention = this.shipment.sender.extention
      const phone = this.shipment.sender.phone

      this.$store.commit('setSender', {
        number: number,
        street: street,
        postal_code: postal_code,
        city: city,
        state: state,
        country: country,
        email: email,
        phone: phone,
        extention: extention,
        first_name: first_name,
        last_name: last_name
      })
    },
    setReceiver() {
      console.log('setReceiver')
      const number = this.shipment.receiver.number
      const street = this.shipment.receiver.street
      const postal_code = this.shipment.receiver.postal_code
      const city = this.shipment.receiver.city
      const state = this.shipment.receiver.state
      const country = this.shipment.receiver.country
      const email = this.shipment.receiver.email
      const first_name = this.shipment.receiver.first_name
      const last_name = this.shipment.receiver.last_name
      const extention = this.shipment.receiver.extention
      const phone = this.shipment.receiver.phone

      this.$store.commit('setReceiver', {
        number: number,
        street: street,
        postal_code: postal_code,
        city: city,
        state: state,
        country: country,
        email: email,
        phone: phone,
        extention: extention,
        first_name: first_name,
        last_name: last_name
      })
    },
    setShipmentInstance() {
      console.log('setShipmentInstance')
      const country = this.shipment.country
      const package_type = this.shipment.package_type
      const weight = this.shipment.weight
      const description = this.shipment.description
      const insure_my_parcel = this.shipment.insure_my_parcel
      const home_collection = this.shipment.home_collection
      const home_collection_amount = this.shipment.home_collection_amount
      const printer_option = this.shipment.printer
      const printer_amount = this.shipment.printer_amount
      const read_text = this.shipment.read_text
      const safe_parcel = this.shipment.safe_parcel
      const read_conditions = this.shipment.read_conditions
      const assurance_amount = this.shipment.assurance_amount
      const payment_cash = this.shipment.payment_cash
      const price = this.shipment.price
      const old_price2 = this.shipment.old_price2
      const VAT = this.shipment.VAT
      const service_point_id = this.shipment.service_point_id
      const address_search = this.shipment.address_search
      const servicepoint_address = this.shipment.servicepoint_address
      const express = this.shipment.express
      const s_postal_code = this.shipment.s_postal_code
      const r_postal_code = this.shipment.r_postal_code

      this.$store.commit('setShipmentInstance', {
        country: country,
        package_type: package_type,
        weight: weight,
        description: description,
        insure_my_parcel: insure_my_parcel,
        home_collection: this.shipment.home_collection,
        home_collection_amount: this.shipment.home_collection_amount,
        printer_amount: printer_amount,
        printer_option: printer_option,
        read_text: read_text,
        safe_parcel: safe_parcel,
        read_conditions: read_conditions,
        assurance_amount: assurance_amount,
        payment_cash: payment_cash,
        price: price,
        old_price2: old_price2,
        VAT: VAT,
        service_point_id: service_point_id,
        address_search: address_search,
        servicepoint_address: servicepoint_address,
        express: express,
        s_postal_code: s_postal_code,
        r_postal_code: r_postal_code
      })
    },
    async setProforma() {
      console.log('setProforma')
      const item_nature = this.shipment.proforma.item_nature
      const item_description = this.shipment.proforma.item_description
      const item_code = this.shipment.proforma.item_code
      const item_number = this.shipment.proforma.item_number
      const item_price = this.shipment.proforma.item_price

      this.$store.commit('setProforma', {
        item_nature: item_nature,
        item_description: item_description,
        item_code: item_code,
        item_number: item_number,
        item_price: item_price,
      })
    },
  }
}
</script>