<template>
  <div class="container mt-4">
      <h1 style="text-align: center; margin: 50px; font-size: 3em;">My Orders</h1>
  </div>
  <div class="table-container container-lg">
      <div>
          <div class="table-wrapper">
              <template v-if="shipments.length">
                  <div class="table-responsive-lg">

                      <table class="table table-hover table-striped table-bordered table-responsive">
                          <thead class="table-dark">
                              <tr>
                                  <!-- Add the 'white-space: nowrap;' and 'text-align: center;' styles to table headers (th) -->
                                  <th style="white-space: nowrap; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff; border-top-left-radius: 12px;"> Tracking number</th>
                                  <th style="white-space: nowrap; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff"> Shipping date </th>
                                  <th style="white-space: nowrap; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff"> Sender</th>
                                  <th style="white-space: nowrap; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff"> Ship from</th>
                                  <th style="white-space: nowrap; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff"> Ship to</th>
                                  <th style="white-space: nowrap; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff"> Package Type</th>
                                  <th style="white-space: nowrap; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff"> Status</th>
                                  <th style="white-space: nowrap; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff"> Assurance</th>
                                  <th style="white-space: nowrap; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff"> Discount%</th>
                                  <th style="white-space: nowrap; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff"> Price &euro;</th>
                                  <th style="white-space: nowrap; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff; border-top-right-radius: 12px;"> Shipping label</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="shipment in shipments" :key="shipment.shipment_id" @click="goToShipmentPage(shipment.shipment_id)" style="cursor: pointer">
                                  <td style="white-space: nowrap; text-align: center;"> {{ shipment.order_date }}</td>
                                  <td style="white-space: nowrap; text-align: center; "> {{ shipment.shipment_id }}</td>
                                  <td style="white-space: nowrap; text-align: center;"> {{ shipment.package_type }}</td>
                                  <td style="white-space: nowrap; text-align: center;"> {{ shipment.max_weight }}</td>
                                  <td style="white-space: nowrap; text-align: center;"> {{ shipment.package_description }}
                                  </td>
                                  <td style="white-space: nowrap; text-align: center;"> {{ shipment.insurance_option }}
                                  </td>
                                  <td style="white-space: nowrap; text-align: center;"> {{ shipment.sender_address1 }}
                                      <br> {{ shipment.sender_address2 }}
                                  </td>
                                  <td style="white-space: nowrap; text-align: center;"> {{ shipment.pickup_address1 }}
                                      <br> {{ shipment.pickup_address2 }}
                                  </td>
                                  <td style="white-space: nowrap; text-align: center;"> {{ shipment.receiver_address1 }}
                                      <br> {{ shipment.receiver_address2 }}
                                  </td>
                                  <td style="white-space: nowrap; text-align: center;"> <span
                                          class="badge bg-success rounded-pill" style="font-size: 0.9em;">{{
                                              shipment.price
                                          }} &euro;</span></td>
                                  <td style="white-space: nowrap; text-align: center; color: #E5097F; font-size: 20px;"> <i class="fa fa-print" aria-hidden="true"></i></td>

                              </tr>
                          </tbody>
                      </table>
                  </div>
              </template>
              <template v-else>
                  <div class="container" style="text-align: center; color: red; font-weight: bold; font-size: 1.5em;">
                      You have no Orders &#128533
                  </div>
              </template>
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
      return {
          shipments: [],
          user: {}
      };
  },

  methods: {
      async fetchShipments() {
          try {
              const response = await axios.get(`/api/v1/user_shipments/${this.user.id}/`);
              this.shipments = response.data;
          } catch (error) {
              console.error('Error fetching shipments:', error);
          }
      },
      goToShipmentPage(shipmentId) {
    this.$router.push(`/track/${shipmentId}`);
  },
  },
  async created() {

      this.$store.commit('setIsLoading', true)

      await axios.get('/api/v1/users/me/')
          .then(response => {
              this.user = response.data


          })
          .catch(error => {
              console.log(error)
          })

      this.fetchShipments();

      this.$store.commit('setIsLoading', false)
  },
};
</script>

<style>
/* Add custom styles here if necessary */
.table-container {
  overflow-x: auto;
}
</style>
