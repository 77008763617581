<template>
    <nav class="navbar navbar-expand-lg" data-bs-theme="dark" style="background-color: #E5097F;">
        <div class="container">

            <!-- Move the button to the left using order class -->
            <div class="d-flex justify-content-between ">
                <p></p>
                <button class="navbar-toggler align-self-end" style="background-color: #9B0656;" type="button"
                    data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <!-- Add a spacer to push the menu items to the right -->
            <div class="flex-grow-1"></div>

            <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link to="/dashboard" class="nav-link partitem">{{ translate('dashboard') }}</router-link>
                    </li>

                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
    mixins: [en, fr, nl],
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    methods: {
        translate(prop) {
      return this[this.lang][prop];
    },
    }
}
</script>



<style>
.partitem {
    color: white;
    font-weight: bold;
    font-size: 16px;
}

.partitem:hover {
    background-color: #9B0656;
    color: white;
}
</style>