<template>
    <div ref="scrollTarget"></div>

    <div class="laptop-section">
        <section style="padding-bottom: 50px; height: 700px; display: flex; align-items: center; padding-left: 150px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover 100%' }">

            <div style="color: black; font-weight: bold; font-size: 1.8em; width: 50%; color: #E5097F; margin-left: 100px;">
                <template v-if="home_collection">
                    <p>{{ translate('home_success_text1') }}</p>
                <p>{{ translate('home_success_text2') }}</p>
                </template>
                <template v-else>
                    <p>{{ translate('home_success_text1') }}</p>
                <p>{{ translate('home_success_text2') }}</p>
                </template>
            </div>
        </section>
        <div style="color: black; font-weight: 600; font-size: 1.2em; text-align: center; margin-top: -110px; margin-bottom: 50px;">
                <p>{{ translate('home_success_text3') }}</p>
            </div>
    </div>

    <div class="ipad-section">
        <section
            style="padding-bottom: 50px; height: 420px; display: flex; align-items: center; padding-left: 30px; padding-right: 30px;"
            :style="{ backgroundImage: 'url(' + backgroundImage3 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': '100%' }">

            <div style="color: black; font-weight: bold; font-size: 1.5em; margin-top: 350px; color: #E5097F; width: 100%; text-align: center;">
                <template v-if="home_collection">
                    <p>{{ translate('home_success_text1') }}</p>
                <p>{{ translate('home_success_text2') }}</p>
                </template>
                <template v-else>
                    <p>{{ translate('home_success_text1') }}</p>
                <p>{{ translate('home_success_text2') }}</p>
                </template>
            </div>
        </section>
        <p style="text-align: center; color: black; font-size: 1.2em; font-weight: 600; padding-left: 20px; padding-right: 20px;">{{ translate('home_success_text3') }}</p>
    </div>

    <div class="mobile-section">
        <section style="padding-bottom: 15px; height: 100%; display: flex; padding-left: 15px; padding-right: 15px;"
            :style="{ backgroundImage: 'url(' + backgroundImage2 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'contain' }">

            <div style="color: black; font-weight: bold; font-size: 1.4em; margin-top: 250px; text-align: center; color: #E5097F; width: 100%;">
                <template v-if="home_collection">
                    <p>{{ translate('home_success_text1') }}</p>
                <p>{{ translate('home_success_text2') }}</p>
                </template>
                <template v-else>
                    <p>{{ translate('home_success_text1') }}</p>
                    <p>{{ translate('home_success_text2') }}</p>
                </template>
            </div>
        </section>
        <p style="padding-left: 15px; padding-right: 15px; font-weight: 600; color: black; text-align: center; margin-bottom: 30px;">{{ translate('home_success_text3') }}</p>
    </div>

    <div class="container">
        <div class="row mb-1 justify-content-center">
            
            <div class="col-lg-4 mb-1 text-center" style="margin-top: 40px;">
                <div class="div-opt" style="background-color: #FFF4FB;">
                    <img src="../assets/img/step_1.svg" style="transform: scale(0.8); margin-top: -80px;">

                    <p style="color: black; font-weight: 400; margin-bottom: 40px;">{{ translate('step1_text') }}</p>

                    <div class="form-group row" :style="!callme_option && !sent_code ? { display: 'block' } : { display: 'none' }">
                        <div style="padding-left: 40px; padding-right: 40px;">
                            <input type="text" style="border-radius: 20px; border: 1px solid #E5097F; margin-bottom: 15px;" class="form-control" ref="phoneInput12" placeholder="+3223333777" required v-model="phonenumber" :class="{ 'fill-out': phonenumber, 'not-fill-out': !phone }">
                    <button class="btn btn-editphone" @click="selectAllText">{{ translate('edit') }}</button>
                    <button class="btn btn-sendcode" @click="sendSMS">{{ translate('sendsms') }}</button>
                        </div>
                </div>

                <template v-if="sent_code">
                    <div style="margin-top: 35px; font-size: 18px;">
                        <span style="color: #E5097F;"> <i class="fa fa-check-circle"></i> {{ translate('sms_sent_text')
                        }}</span>
                        <br>
                        <span @click="resendSMS"
                            style="color: red; text-decoration: underline; cursor: pointer;">{{ translate('dont_receive_sms') }}</span><br>
                        <span @click="callMe" style="color: #9b0656; text-decoration: underline; cursor: pointer;">{{ translate('call_me') }}</span>
                    </div>
                </template>

                <template v-if="callme_option">
                    <div style="margin-top: 35px; font-size: 18px;">
                        <span style="color: #E5097F;"> <i class="fa fa-check-circle"></i> {{ translate('thank_you') }} {{
                            translate('we_will_call_you') }}</span>
                    </div>
                </template>
                </div>
            </div>

            <div class="col-lg-4 mb-1 text-center" style="margin-top: 40px;">
                <div class="div-opt" style="background-color: #FFF4FB;">
                    <img src="../assets/img/step_2.svg" style="transform: scale(0.8); margin-top: -80px;">
                    <br>
                    <img src="../assets/img/hand_pack.svg">
                    <br>
                    <p style="color: black; font-weight: 400; margin-top: 15px;">{{ translate('step2_text') }}</p>                    
                </div>
            </div>

            <template v-if="home_collection">
                <div class="col-lg-4 mb-1 text-center" style="margin-top: 40px;">
                <div class="div-opt" style="background-color: #FFF4FB;">
                    <img src="../assets/img/step_3.svg" style="transform: scale(0.8); margin-top: -80px;">
                    <br>
                    <img src="../assets/img/shipping_label.svg">
                    <br>
                    <p style="color: black; font-weight: 400; margin-top: 15px;">{{ translate('step4_text') }}</p>                    
                </div>
            </div> 
            </template>
            <template v-else>
                <div class="col-lg-4 mb-1 text-center" style="margin-top: 40px;">
                <div class="div-opt" style="background-color: #FFF4FB;">
                    <img src="../assets/img/step_3.svg" style="transform: scale(0.8); margin-top: -80px;">
                    <br>
                    <img src="../assets/img/eurekali_point.svg">
                    <br>
                    <p style="color: black; font-weight: 400; margin-top: 15px;">{{ translate('step3_text') }}</p>                    
                </div>
            </div> 
            </template>         
            <p style="text-align: center; margin-top: 25px;"><router-link :to="'/track/' + realtrackingnumber" class="btn btn-track" style="border: 1px solid #E5097F;">{{ translate('tracking_number') }} : {{ realtrackingnumber }}</router-link></p>
        </div>
    </div>

    <div class="container">
        <div class="row mb-1 justify-content-center" style="margin-top: 20px;">
            <div class="col-md-8 mb-1 text-center">
                <h1 class="h1-text">{{ translate('track_your_package') }}</h1>
            </div>
        </div>

        <div class="row mb-1 justify-content-center" style="margin-top: 30px;">
            <div class="col-lg-4 mb-1 text-center" style="margin-top: 20px;">
                <div class="div-opt" style="background: linear-gradient(to bottom, #C6FBC9 10%, #B5F0B9 80%);">
                    <h1 class="h1-text-sss">{{ translate('thr_whatsapp') }}</h1>
                    <img src="../assets/img/wtspcontact.png">

                    <h1 class="desc">{{ translate('whts_desc') }}</h1>
                    <button class="btn btn-track" @click="openWhatsapp">00 32 495 92 69 35</button>
                    <h1 class="desc">{{ translate('provide_infor') }}</h1>
                </div>
            </div>

            <div class="col-lg-4 mb-1 text-center" style="margin-top: 20px;">
                <div class="div-opt" style="background: linear-gradient(to bottom, #FFF2F9 10%, #FBD7EB 80%);">
                    <h1 class="h1-text-sss">{{ translate('thr_website') }}</h1>
                    <img src="../assets/img/searchcontact.png">
                    <h1 class="desc">{{ translate('website_desc') }}</h1>
                    <router-link :to="'/track/' + realtrackingnumber" class="btn btn-primary btn-track-02">{{ translate('track_shipment')
                    }}</router-link>
                </div>
            </div>
        </div>

        <div class="row mb-1 justify-content-center" style="margin-top: 20px; margin-bottom: 60px;">
            <div class="col-md-8 mb-1 text-center">
                <h1 class="h1-text-ss" style="color: #E5097F; margin-bottom: 80px;">{{ translate('your_satistfaction') }}
                </h1>
            </div>
        </div>
    </div>

    <div class="container" style="margin-bottom: 80px;">
        <div class="row justify-content-center" style="margin-right: 10px; margin-left: 10px;">
            <div style="text-align: center; background-color: #FDF6CF; width: auto; padding: 40px; border-radius: 20px;">
                <span style="color: #E5097F; font-weight: bold; font-size: 18px;"> {{ translate('how_was_your_experience')
                }} </span> <br>


                <span style="color: #FFAC00; font-size: 18px; cursor: pointer;">
                    <i class="fa fa-star" v-if="stars_number >= 1" @click="set_stars_number_1"></i>
                    <i class="fa fa-star-o" v-if="stars_number < 1" @click="set_stars_number_1"></i>
                    <i class="fa fa-star" v-if="stars_number >= 2" @click="set_stars_number_2"></i>
                    <i class="fa fa-star-o" v-if="stars_number < 2" @click="set_stars_number_2"></i>
                    <i class="fa fa-star" v-if="stars_number >= 3" @click="set_stars_number_3"></i>
                    <i class="fa fa-star-o" v-if="stars_number < 3" @click="set_stars_number_3"></i>
                    <i class="fa fa-star" v-if="stars_number >= 4" @click="set_stars_number_4"></i>
                    <i class="fa fa-star-o" v-if="stars_number < 4" @click="set_stars_number_4"></i>
                    <i class="fa fa-star" v-if="stars_number >= 5" @click="set_stars_number_5"></i>
                    <i class="fa fa-star-o" v-if="stars_number < 5" @click="set_stars_number_5"></i>
                </span>

                <div class="form-group mb-3">
                    <label class="form-label"></label>
                    <textarea class="form-control" :class="{ 'fill-out': feedback, 'not-fill-out': !feedback }" rows="2"
                        :placeholder="translate('your_feedback')" v-model="feedback"></textarea>
                </div>

                <button class="btn btn-track-2" style="margin-top: 0px;" @click="sendfeedback">{{ translate('send')
                }}</button>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
import { toast } from 'bulma-toast';
import Swal from 'sweetalert2'
import 'swiper/swiper-bundle.css';
import Swiper from 'swiper/bundle';
import { Pagination, Navigation } from 'swiper';
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';
import BackgroundImage1 from '../assets/img/completed_step1_desk.svg';
import BackgroundImage2 from '../assets/img/completed_step1_mobile.svg';
import BackgroundImage3 from '../assets/img/completed_step1_ipad.svg';
import BackgroundImage4 from '../assets/img/completed_step1_home_desk.svg';
import BackgroundImage5 from '../assets/img/completed_step1_home_mobile.svg';
import BackgroundImage6 from '../assets/img/completed_step1_home_ipad.svg';

Swiper.use([Pagination, Navigation]);


export default {
    name: 'Success',
    mixins: [en, fr, nl],
    data() {
        return {
            backgroundImage1: BackgroundImage1,
            backgroundImage2: BackgroundImage2,
            backgroundImage3: BackgroundImage3,
            tracking_number: "",
            slides: [],
            stars_number: 5,
            feedback: "",
            phonenumber: "",
            sent_code: false,
            callme_option: false,
            realtrackingnumber: "",
            home_collection: ""
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
        whatsappLink() {
            return `https://wa.me/+32495926935?text=Hello,%20my%20tracking%20number%20is%20${this.realtrackingnumber}`;
        },
    },
    async mounted() {
        document.title = 'Success | Eurekali'
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0);

        this.initializeSwiper();
        await this.getreviews();

        //---------Country code phone

        //------------------------
    },
    async created() {
        this.tracking_number = this.$route.params.shipment_id
        let startIndex = this.tracking_number.indexOf('Z') + 1;
        let endIndex = this.tracking_number.indexOf('C');
        this.tracking_number = this.tracking_number.substring(startIndex, endIndex);
        await this.getPhonenumber()
        this.sent_code = true
        
        if(this.home_collection){
            this.backgroundImage1 = BackgroundImage4
            this.backgroundImage2 = BackgroundImage5
            this.backgroundImage3 = BackgroundImage6
        }
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        initializeSwiper() {
            const swiper = new Swiper('.swiper', {
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        },
        showAlertSuccess() {
            Swal.fire({
                title: this.translate('thanks_feedback'),
                text: "",
                icon: 'success',
                confirmButtonText: 'OK',
                confirmButtonColor: '#4BB543',
            }).then(() => {
                // Redirect to /new_order
                //this.$router.push('/new_order/');
            });
        },
        async sendfeedback() {
            if (this.feedback) {
                this.$store.commit('setIsLoading', true)

                const data = {
                    "comment": this.feedback,
                    "stars_number": this.stars_number
                }
                await axios.post(`/api/v1/create-user-review/${this.tracking_number}/`, data)
                    .then(response => {
                        this.showAlertSuccess()
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            } else {
                toast({
                    message: this.translate('give_feedback'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
            }
        },
        async getreviews() {
            this.$store.commit('setIsLoading', true)
            await axios.get('/api/v1/get-reviews/')
                .then(response => {
                    this.slides = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getPhonenumber() {
            this.$store.commit('setIsLoading', true)
            await axios.get(`/api/v1/get-phone-number/${this.tracking_number}/`)
                .then(response => {
                    this.phonenumber = response.data['phone_number']
                    this.realtrackingnumber = response.data['tracking_number']
                    this.home_collection = response.data['home_collection']
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        selectAllText() {
            this.$refs.phoneInput12.focus();
            this.$refs.phoneInput12.select();
        },
        async sendSMS() {
            if (this.phonenumber) {
                this.$store.commit('setIsLoading', true)

                await axios.get(`/api/v1/send-sms/${this.tracking_number}/${this.phonenumber}/`)
                    .then(response => {

                    })
                    .catch(error => {
                        console.log(error)
                    })
                this.$store.commit('setIsLoading', false)

                this.sent_code = true
            } else {
                this.selectAllText()
            }

        },
        async callClient() {
            if (this.phonenumber) {
                this.$store.commit('setIsLoading', true)
                const actualphonenumber = this.phonenumber
                await axios.get(`/api/v1/call-client/${this.tracking_number}/${actualphonenumber}/`)
                    .then(response => {

                    })
                    .catch(error => {
                        console.log(error)
                    })
                this.$store.commit('setIsLoading', false)
            }

        },
        resendSMS() {
            this.sent_code = false
            this.callme_option = false
        },
        async callMe() {
            await this.callClient()
            this.sent_code = false
            this.callme_option = true
        },
        set_stars_number_1() {
            this.stars_number = 1
        },
        set_stars_number_2() {
            this.stars_number = 2
        },
        set_stars_number_3() {
            this.stars_number = 3
        },
        set_stars_number_4() {
            this.stars_number = 4
        },
        set_stars_number_5() {
            this.stars_number = 5
        },
        openWhatsapp() {
            const urlToOpen = this.whatsappLink;
            const newWindow = window.open(urlToOpen, '_blank');
            newWindow.focus();
        }
    }

}
</script>

<style>
.h1-text {
    margin-top: 20px;
    color: black;
    font-weight: 600;
    font-size: 22px;
}

.h1-text-s {
    margin-top: 20px;
    font-size: 20px;
}

.h1-text-ss {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
}

.h1-text-sss {
    margin-top: 30px;
    margin-bottom: 30px;
    color: #E5097F;
    font-size: 18px;
    font-weight: bold;
}

.desc {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 400;
}

.btn-track {
    margin-top: 10px;
    background-color: #FFFFFF;
    color: #E5097F;
    border-color: #FFFFFF;
    font-weight: 600;
    border-radius: 20px;
}

.btn-track:hover {
    background-color: #E5097F;
    color: #FEEEF6;
    border-color: #E5097F;
    font-weight: 600;
}

.btn-track-02 {
    margin-top: 25px;
    background-color: #E5097F;
    color: white;
    border-color: #E5097F;
    font-weight: 600;
    border-radius: 20px;
}

.btn-track-02:hover {
    background-color: #FEEEF6;
    color: #E5097F;
    border-color: #E5097F;
    font-weight: 600;
}

.div-opt {
    border-radius: 15px;
    padding: 30px;
    margin: 0 15px;
    margin-bottom: 15px;
    height: 100%;
}

.header-container {
    height: 500px;
}

.header-container img {
    object-fit: cover;
    height: 100%;
}

.background-image2-laptop {
    object-fit: cover;
    height: 100%;
    background-image: url('../assets/img/background.png');
    background-size: cover;
    background-position: center;
}

.background-image2-mobile {
    height: 100%;
    background-image: url('../assets/img/small_background.png');
    background-size: fill;
    background-repeat: no-repeat;
    background-position: center;
}

.background-image2-ipad {
    height: 100%;
    background-image: url('../assets/img/ipad_background.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


.background-image2-mobile {
    display: none;
}

.background-image2-ipad {
    display: none;
}

.phrase-mobile {
    display: none;
}

@media (max-width: 767px) {
    .background-image2-laptop {
        display: none;
    }

    .background-image2-mobile {
        display: block;
    }

    .background-image2-ipad {
        display: none;
    }

    .phrase-mobile {
        display: block;
    }

    .phrase-laptop {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .background-image2-laptop {
        display: none;
    }

    .background-image2-mobile {
        display: none;
    }

    .background-image2-ipad {
        display: block;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');




.box-review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.boarddd {
    max-width: 987px;
    width: 100%;
    background-color: #FCF5D5;
    text-align: center;
    border-radius: 20px;
    padding-bottom: 50px;
    padding-top: 30px;
}

.text-lightv2 {
    color: var(--color-light);
}

.swiper {
    width: 600px;
    height: auto;
}

.swiper .swiper-slide .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.swiper .swiper-slide .comments {
    color: black;
    width: 100%;
    border-radius: 5px;
    font-size: .9em;
    position: relative;
}

.swiper .swiper-slide .comments::after {
    content: '';
    position: absolute;
    bottom: -19px;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid var(--color-light);
    z-index: -99;
}


.swiper .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
}

.swiper .profile img {
    width: 30%;
    border-radius: 50%;
    border: 5px solid var(--color-primary);
    margin: 10px 0;
}

.swiper .profile>a {
    text-decoration: none;
    color: var(--color-light);
}

.swiper .profile>span {
    color: var(--color-text);
    font-size: .8em;
}

.swiper .swiper-button-prev,
.swiper-button-next {
    color: var(--color-text);
    display: none;
}

@media (min-width: 320px) {
    .boarddd {
        width: auto;
    }

    .swiper {
        width: 320px;
    }


}

@media (min-width: 1023px) {
    .boarddd {
        width: 100%;
    }

    .swiper {
        width: 600px;
    }

    .swiper .swiper-button-prev,
    .swiper-button-next {
        display: initial;
    }
}

.btn-editphone {
    background-color: #FFFFFF;
    color: #E5097F;
    border: 1px solid #E5097F;
    border-radius: 20px;
    font-weight: 600;
    margin-right: 15px;
}

.btn-editphone:hover {
    background-color: #9b0656;
    color: #FFFFFF;
}

.btn-sendcode {
    background-color: #E5097F;
    color: #FFFFFF;
    border-radius: 20px;
    font-weight: 600;
}

.btn-sendcode:hover {
    background-color: #9b0656;
    border: 1px solid #9b0656;
    color: #FFFFFF;
}

.laptop-section {
    display: block;
}

.mobile-section {
    display: none;
}

.ipad-section {
    display: none;
}

@media (max-width: 767px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: block;
    }

    .ipad-section {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: none;
    }

    .ipad-section {
        display: block;
    }
}</style>