<template>
  <div ref="scrollTarget"></div>
  <div class="container-fluid" style="height: 100%;">
    <div class="row">
      <!-- Left Side Menu -->
      <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block sidebar">
        <div class="position-sticky" style="margin-top: 50px;">
          <ul class="nav flex-column" style="padding-left: 5%; margin-top: 10px;">
            <router-link to="/dashboard" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash1.png" class="img-item" style="margin-right: 12px;">
                {{
                  translate('dashboard')
                }}</li>
            </router-link>
            <router-link to="/orders-validate" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/wait_icon.svg" class="img-item"> {{ translate('shipments_to_validate') }} <span v-if="pending_orders_notification>0">({{ pending_orders_notification }})</span>
              </li>
            </router-link>
            <router-link to="/progress-orders" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/progress_icon.svg" class="img-item"> {{ translate('shipment_progress') }} <span v-if="progress_orders_notification>0">({{ progress_orders_notification }})</span>
              </li>
            </router-link>
            <router-link to="/delivered-orders" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/Check.svg" class="img-item"> {{
                translate('shipment_deleivred') }}
              </li>
            </router-link>
            <router-link to="/commissions" style="color: #000;">
              <li class="nav-item custom active-item"><img src="../assets/img/dash4.png" class="img-item"
                  style="margin-right: 12px;"> {{
                    translate('commissions') }}</li>
            </router-link>
            <router-link to="/new_order" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash5.png" class="img-item" style="margin-right: 12px;">
                {{
                  translate('new_shipment') }}</li>
            </router-link>
            <router-link v-if="company.bonus" to="/mybonus" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/bonus_icon.svg" class="img-item" style="margin-right: 12px;">
                {{translate('bonus') }} 
                <span style="margin-left: 5px; background-color: #E5097F; color: white; padding: 3px 8px 3px 8px; border-radius: 20px; font-weight: bold;">{{ company.bonus_points }} pts</span>
              </li>
            </router-link>
          </ul>

          <ul class="nav flex-column" style="margin-top: 400px; padding-left: 5%; margin-bottom: 10px;">
            <router-link to="/company" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash6.png" class="img-item"> {{ translate('my_company')
              }}</li>
            </router-link>
            <li class="nav-item custom" @click="logout()"><img src="../assets/img/dash8.png" class="img-item"> {{
              translate('sign_out') }}</li>
          </ul>
        </div>
      </nav>

      <!-- Content Area -->
      <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4" style=" background-color: #F7F7F8;">

        <div class="row justify-content-between" style="padding-right: 50px; padding-left: 50px; margin-top: 30px;">
          <div class="col-auto">
            <p style="font-weight: bold; color: black;">{{ translate('hello') }} {{ user.first_name }} <br> <span
                style="font-weight: 400;">
                {{ translate('welcome_back') }}
              </span></p>
          </div>
          <router-link to="/company" class="col-auto" style="cursor: pointer;">
            <p style="color: #E5097F; font-weight: bold;"> <i class="fa fa-building-o" aria-hidden="true"
                style="margin-right: 10px; font-size: 25px;"></i> {{ company.company_name }} </p>
          </router-link>
        </div>



        <div class="row justify-content-center" style="margin-top: 20px; margin-bottom: 100px;">
          <!-- First Table -->
          <div class="col-12 text-center">
            <template v-if="commissions.length">
              <div class="table-responsive-lg">

                <table class="table table-hover table-striped table-bordered table-responsive">
                  <thead class="table-dark">
                    <tr>
                      <!-- Add the 'white-space: nowrap;' and 'text-align: center;' styles to table headers (th) -->
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff; border-top-left-radius: 12px;">
                        {{ translate('tracking_number') }}</th>
                      <th
                        style="vertical-align: middle;  text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('order_date') }} </th>
                      <th
                        style="vertical-align: middle;  text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('ship_from') }}</th>
                      <th
                        style="vertical-align: middle;  text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('ship_to') }}</th>
                      <th
                        style="vertical-align: middle;  text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('payment') }}</th>
                      <th
                        style="vertical-align: middle;  text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('other_cash') }}</th>
                      <th
                        style="vertical-align: middle;  text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('commissions') }}</th>
                      <th
                        style="vertical-align: middle;  text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff; border-top-right-radius: 12px;">
                        {{ translate('details') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="commission in commissions" :key="commission.shipment_id">
                      <td
                        style="vertical-align: middle;  text-align: center; cursor: pointer; text-decoration: underline; color: #E5097F;"
                        @click="goToShipmentPage(commission.shipment_id)"> {{ commission.shipment_id }}</td>
                      <td style="vertical-align: middle;  text-align: center;"> {{ commission.order_date }}</td>
                      <td style="vertical-align: middle;  text-align: center;"> {{ commission.ship_from }}</td>
                      <td style="vertical-align: middle;  text-align: center;"> {{ commission.ship_to }}</td>
                      <td style="vertical-align: middle;  text-align: center;"> {{ translate(commission.payment_type) }}
                      </td>
                      <td style="vertical-align: middle;  text-align: center;"> <span
                          class="badge bg-warning rounded-pill"
                          style="font-size: 0.9em;">{{ commission.additionalCash_amount }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span></span>
                      </td>
                      <td style="vertical-align: middle;  text-align: center;"> <span
                          class="badge bg-success rounded-pill" style="font-size: 0.9em;">{{
                            commission.commission_price
                          }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span></span></td>

                      <td
                        style="vertical-align: middle; text-align: center; color: #E5097F; cursor: pointer; text-decoration: underline;">
                        <span @click="selectShipment(commission)">{{ translate('see_details') }}</span>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <template v-else>
              <div class="container"
                style="text-align: center; color: white; font-weight: bold; font-size: 1.5em; background-color: #F1A8D0; padding: 60px; border-radius: 20px;">
                Currently, there are no orders on your account
              </div>
            </template>

            <div v-if="selectedShipment" class="modal">
              <div class="modal-content">
                <span style="text-align: right;" @click="closeModal" class="close">&times;</span>
                <div style="text-align: center;">
                  <p style="color: #E5097F;"><strong style="color: #E5097F;">{{ translate('tracking_number') }} :
                    </strong> {{ selectedShipment.shipment_id }}</p>
                </div>
                <div class="modal-body">
                  <div class="column">
                    <p><strong>{{ translate('ship_from') }} : </strong> {{ selectedShipment.ship_from }}</p>
                    <p><strong>{{ translate('ship_to') }} : </strong> {{ selectedShipment.ship_to }}</p>
                    <p><strong>{{ translate('package_type') }} : </strong> {{ selectedShipment.package_type }}</p>
                    <p><strong>{{ translate('weight') }} : </strong> {{ selectedShipment.weight }}</p>
                  </div>
                  <div class="column">
                    <p><strong>{{ translate('shipment_price') }} : </strong> {{ selectedShipment.shipment_price }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span>
                    </p>
                    <p><strong>{{ translate('shipment_price') }} ({{ translate('without_vat') }}) : </strong> {{
                      selectedShipment.shipment_price_htva }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span></p>
                    <p><strong>{{ translate('payment_type') }} : </strong> {{ translate(selectedShipment.payment_type) }}
                    </p>
                    <p><strong>{{ translate('other_cash') }} : </strong> <span class="badge bg-warning rounded-pill"
                        style="font-size: 0.8em;">{{ selectedShipment.additionalCash_amount }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span></span></p>
                    <p><strong>{{ translate('commissions') }} : </strong> <span class="badge bg-success rounded-pill"
                        style="font-size: 0.8em;">{{ selectedShipment.commission_price }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span></span></p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </main>
    </div>
  </div>
</template>
    
<script>
import axios from 'axios'
import Chart from 'chart.js/auto';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
  name: 'Dashboard',
  mixins: [en, fr, nl],
  data() {
    return {
      user: {},
      today_shipments: "",
      progress_shipments: "",
      delivered_shipments: "",
      all_shipments: "",
      today_date: "",
      shipments_statistics: [],
      commissions_statistics: [],
      commission_amount: "",
      commissions: [],
      company: {},
      selectedShipment: null,
      pending_orders_notification: 0,
      progress_orders_notification: 0
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
  },
  async created() {

    this.$store.commit('setIsLoading', true)
    await axios.get('/api/v1/users/me/')
      .then(async response => {
        this.user = response.data
        await this.getServicePoint(this.user.id)
      })
      .catch(error => {
        console.log(error)
      })
    this.$store.commit('setIsLoading', false)


    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    this.today_date = `${day}/${month}/${year}`;

  },
  async mounted() {
    document.title = 'Dashboard | Commissions';
    const element = this.$refs.scrollTarget;
    element.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo(0, 0);

    this.$store.commit('setIsLoading', true)
    await this.fetchCommissions()
    await this.getNotifications()
    this.$store.commit('setIsLoading', false)


  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    async fetchCommissions() {
      try {
        const response = await axios.post('/api/v1/get-commissions/');
        this.commissions = response.data['commissions'];
      } catch (error) {
        console.error('Error fetching shipments:', error);
      }
    },
    async getNotifications() {
      await axios.post('/api/v1/get-notifications/')
        .then(response => {
          this.pending_orders_notification = response.data['pending_orders']
          this.progress_orders_notification = response.data['progress_orders']
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
    goToShipmentPage(shipmentId) {
      this.$router.push(`/track/${shipmentId}`);
    },
    async getServicePoint(user_id) {
      await axios.post('/api/v1/getservicepoint/')
        .then(response => {
          this.company = response.data
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
    selectShipment(shipment) {
      this.selectedShipment = shipment;
    },
    closeModal() {
      this.selectedShipment = null;
    },
    logout() {
      axios.defaults.headers.common["Authorization"] = ""
      sessionStorage.removeItem("token")
      this.$store.commit('removeToken')
      this.$store.commit('removeAgent')
      this.$store.commit('clearShipment')
      this.$router.push('/login')
    }
  }
}
</script>
    
<style scoped>
.custom {
  border: none;
  cursor: pointer;
  font-size: 15px;
  padding-bottom: 10px;

}

.custom:hover {
  color: #E5097F;
  font-weight: bold;
}


.active-item {
  color: #E5097F;
  font-weight: bold;
}

.img-item {
  margin-right: 8px;
}

.red-header tr {
  background-color: red;
  color: white;
}

.btn-new-ship {
  color: #E5097F;
  font-weight: bold;
  background-color: #fff;
  border: 2px solid #E5097F;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.btn-new-ship:hover {
  background-color: #9b0656;
  color: white;
  border: 2px solid #9b0656;
}


.btn-more {
  color: #fff;
  font-weight: bold;
  background-color: #E5097F;
  border: 2px solid #E5097F;
  margin-left: 10px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.btn-more:hover {
  background-color: #9b0656;
  border: 2px solid #9b0656;
}

.btn-request {
  background-color: #BCF2C0;
  margin-left: 12px;
  border-radius: 20px;
  border: 2px solid #37B980;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  font-weight: bold;
}

.btn-request:hover {
  background-color: #37B980;
  color: white;
}


.box-shadow-custom {
  box-shadow: 1px 1px 1px #aaa, -1px -1px 1px #aaa;
}

.bg-progress {
  background-color: #D5CB41;
}

.bg-delivered {
  background-color: #41D54B;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-body {
  display: flex;
  justify-content: space-between;
}

.column {
  width: 45%;
}

.modal-content {
  background-color: #FEEEF6;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}</style>
    