<template>
    <nav class="navbar navbar-expand-lg sm-body-tertiary" :class="{ 'red-background': hasScrolled, 'navbar': true }"
        style="padding-bottom: 5px; position: fixed; width: 100%; box-shadow: 0 2px 2px -2px gray;">
        <div class="container">
            <div class="d-flex justify-content-between ">
                <a class="navbar-brand align-self-start" href="https://home.eurekali-point.com">
                    <template v-if="!hasScrolled">
                        <img class="navbar-brand align-self-start" src="../assets/img/logonavbar.svg" style="margin: -5px;">
                    </template>
                    <template v-else>
                        <img class="navbar-brand align-self-start" src="../assets/img/logo-white.svg" style="margin: -5px;">
                    </template>
                </a>
                <button class="navbar-toggler align-self-end" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                    aria-label="Toggle navigation" style="border: 0px solid white; margin-bottom: 15px;">
                    <template v-if="!hasScrolled">
                        <img src="../assets/img/menu_toolbar.svg" style="transform: scale(1.5);">
                    </template>
                    <template v-else>
                        <img src="../assets/img/menu_toolbar_white.svg" style="transform: scale(1.5);">
                    </template>
                </button>
            </div>

            <div class=" collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav ms-auto ">
                    <li class="nav-item custom-nav-item">
                        <a style="font-size: 18px; font-weight: 600;"
                            :style="hasScrolled ? { color: 'white' } : { color: 'black' }" class="nav-link active"
                            aria-current="page" href="https://home.eurekali-point.com">{{ translate('home') }}</a>
                    </li>
                    <li class="nav-item custom-nav-item">
                        <router-link to="/new_order" style="font-size: 18px; font-weight: 600;"
                            :style="hasScrolled ? { color: 'white' } : { color: 'black' }" class="nav-link active"
                            aria-current="page">{{ translate('new_shipment') }}</router-link>
                    </li>
                    <li class="nav-item custom-nav-item">
                        <router-link to="/track" style="font-size: 18px; font-weight: 600;"
                            :style="hasScrolled ? { color: 'white' } : { color: 'black' }" class="nav-link active"
                            aria-current="page">{{ translate('track_shipment') }}</router-link>
                    </li>
                </ul>
                <ul class="navbar-nav ms-auto d-lg-inline-flex">
                    <template v-if="$store.state.isAuthenticated">
                        <li class="nav-item">
                            <div class="nav-link text-dark h5" style="position: relative;">
                                <button class="btn btn-danger custom-btn-new" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <i class="fa fa-user" aria-hidden="true"></i> {{ translate('my_space') }}
                                </button>
                                <ul class="dropdown-menu">
                                    <li><router-link to="/dashboard" class="dropdown-item">{{ translate('my_space')
                                    }}</router-link></li>
                                    <li><router-link to="/myprofile" class="dropdown-item">{{ translate('myprofile')
                                    }}</router-link></li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item">
                            <button class="nav-link text-dark h5" href="" target="blank"><button @click="logout()"
                                    class="btn btn-primary custom-btn-new2"><i class="fa fa-sign-out"
                                        aria-hidden="true"></i> {{ translate('sign_out') }}</button></button>
                        </li>
                    </template>
                    <template v-else>
                        <li class="nav-item">
                            <router-link to="/login" class="nav-link text-dark h5" href="" target="blank"><button
                                    class="btn btn-primary custom-btn-new"><i class="fa fa-sign-in" aria-hidden="true"></i>
                                    {{ translate('login') }}</button></router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/signup" class="nav-link text-dark h5" href="" target="blank"><button
                                    class="btn btn-primary custom-btn-new3"><i class="fa fa-user-plus"
                                        aria-hidden="true"></i> {{ translate('sign_up') }}</button></router-link>
                        </li>
                    </template>


                    <li class="nav-item dropdown">
                        <select :class="{ 'custom-select1': !hasScrolled, 'custom-select2': hasScrolled }" name="lang"
                            v-model="lang" @change="updateLang" style="cursor: pointer;">
                            <option value="en" style="color: #E5097F;">EN</option>
                            <option value="fr" style="color: #E5097F;">FR</option>
                            <option value="nl" style="color: #E5097F;">NL</option>
                        </select>

                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import axios from 'axios'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';



export default {
    mixins: [en, fr, nl],
    data() {
        return {
            orders: [],
            hasScrolled: false,
            lang: this.$store.state.lang,
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);


        const language = navigator.language.split('-')[0];
        const supportedLanguages = ['en', 'fr', 'nl'];

        if (supportedLanguages.includes(language)) {
            this.lang = language;
        } else {
            this.lang = 'en';
        }

        this.updateLang()

    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
        updateLang() {
            this.$store.commit('setLang', this.lang);
        },
        handleScroll() {
            this.hasScrolled = window.pageYOffset > 0;
        },
        logout() {
            axios.defaults.headers.common["Authorization"] = ""
            sessionStorage.removeItem("token")
            this.$store.commit('removeToken')
            this.$store.commit('removeAgent')
            this.$store.commit('clearShipment')
            this.$router.push('/login')
        }
    }
}
</script>




<style scoped>
.custom-nav-item:hover {
    text-decoration: underline #E5097F 4px;

}


.btn-login {
    color: white;
    background-color: #E5097F;
    font-weight: 600;
    border-color: #E5097F;
    padding: 8px 12px 8px 16px;
    gap: 8px;
    height: 40px;
    width: 128px;
    border: none;
    border-radius: 20px;
}

.btn-login:hover {
    color: white;
    background-color: #9B0656;
}

.btn-signup {
    color: white;
    background-color: #9B0656;
    font-weight: 600;
    border-color: #9B0656;
    padding: 8px 12px 8px 16px;
    gap: 8px;
    height: 40px;
    width: 128px;
    border: none;
    border-radius: 20px;
}

.btn-signup:hover {
    color: white;
    background-color: #E5097F;
}

.btn-logout {
    color: white;
    background-color: #dc3545;
    font-weight: 600;
    border-color: #9B0656;
    padding: 8px 12px 8px 16px;
    gap: 8px;
    height: 40px;
    border: none;
    border-radius: 20px;
}

.btn-logout:hover {
    color: white;
    background-color: rgb(175, 12, 12);
}

.custom-btn-new {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin: 0px;
    border-radius: 30px;
    font-weight: 600;
    background-color: #E5097F;
    border: none;
}

.custom-btn-new:hover {
    background-color: #FEEEF6;
    color: #E5097F;
    border: none;
}

.custom-btn-new2 {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin: 0px;
    border-radius: 30px;
    font-weight: 600;
    background-color: #FF2400;
    border: none;
}

.custom-btn-new2:hover {
    background-color: #dc3545;
    border: none;
}

.custom-btn-new3 {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin: 0px;
    border-radius: 30px;
    font-weight: 600;
    background-color: #E5097F;
    border: none;
}

.custom-btn-new3:hover {
    background-color: #FEEEF6;
    color: #E5097F;
    border: none;
}

.custom-select1 {
    border: none;
    font-weight: 600;
    background: transparent;
}

.custom-select2 {
    border: none;
    font-weight: 600;
    background: transparent;
    color: white;
}

.red-background {
    background-color: #9B0656 !important;
    color: white;
    border-bottom: none;
}

.text-color-white {
    color: white;
}

.text-color-black {
    color: black;
}
</style>
