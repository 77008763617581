<template>
    <div style="height: 900px;">
        <!-- Placeholder content -->


        <!-- Daily.co iframe container -->
        <div id="myvideo"></div>
    </div>
</template>


<script>
import axios from 'axios'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
    name: 'LogIn',
    mixins: [en, fr, nl],
    data() {
        return {
            tracking_number: "",
            room_name: "",
            events: [],
            shipment: {},
            langu: ""
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    mounted() {
        document.title = 'Room | Eurekali'

        const room_name = this.$route.params.room_name
        this.room_name = room_name

        const langu = this.$route.params.lang
        this.langu = langu


        // Create the iframe with specific attributes
        const callFrame = window.DailyIframe.createFrame({
            iframeStyle: {
                position: 'absolute',
                width: '80%',
                height: '80%',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                border: 'none',
            },
            showLeaveButton: true,
            showFullscreenButton: true,
        });


        callFrame.on('left-meeting', (e) => {
            this.deleteRoom()
            this.$router.push('/meetings')
            const videoDiv = document.getElementById('myvideo');
            videoDiv.innerHTML = '';
            callFrame.destroy({ url: 'https://supporteureali.daily.co/' + this.room_name })
        });

        // Join the call
        callFrame.join({ url: 'https://supporteureali.daily.co/' + this.room_name, lang: this.langu })
            .catch(error => {
                console.error("Error joining the meeting:", error);
                this.$router.push('/meetings');
                callFrame.destroy();
            });

    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        async deleteRoom() {
            this.$store.commit('setIsLoading', true)
            await axios
                .post(`/api/v1/delete-room/${this.room_name}/`)
                .then(response => {

                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>


