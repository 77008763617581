<template>
    <section style="margin-top: 30px; margin-bottom: 100px;">
        <div class="container mt-4">
            <div class="row justify-content-center">
                <div class="col-md-6 col-sm-12 mb-4">
                    <div class="p-4 rounded text-center" style="box-shadow: 1px 1px 1px #888888, -1px -1px 1px #888888; font-weight: bold;">
                        {{ translate('have_account') }}
                        <router-link to="/login" class="btn btn-login2 " type="submit">
                            <i class="fa fa-user" aria-hidden="true"></i> {{ translate('login') }}
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-md-6 col-sm-12 mb-4">
                    <div class="p-4 rounded text-center" style="box-shadow: 1px 1px 1px #888888, -1px -1px 1px #888888;">
                        <div>
                            <img src="../assets/img/signup.png" alt="Signup Image">
                        </div>
                        <form @submit.prevent="submitForm" style="margin-top: 20px;">
                            <div class="form-group mb-3">
                            <div class="row">
                                <div class="col">
                                    <select class="form-control" v-model="title" :class="{ 'fill-out': title, 'not-fill-out': !title }">
                                        <option value="Mr">{{ translate('mr') }}</option>
                                        <option value="Mrs">{{ translate('mrs') }}</option>
                                        <option value="Ms">{{ translate('ms') }}</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" :placeholder="translate('first_name')" required
                                        v-model="first_name" :class="{ 'fill-out': first_name, 'not-fill-out': !first_name }">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" :placeholder="translate('last_name')" required
                                        v-model="last_name" :class="{ 'fill-out': last_name, 'not-fill-out': !last_name }">
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <input type="email" class="form-control" :placeholder="translate('email')" required v-model="email" :class="{ 'fill-out': email, 'not-fill-out': !email }">
                        </div>
                        <div class="form-group mb-3">
                            <input type="text" class="form-control" ref="phoneInput" :placeholder="translate('phone')" required v-model="phone" :class="{ 'fill-out': phone, 'not-fill-out': !phone }">
                        </div>

                        <div class="input-group mb-3 align-items-center">
                            <input id="autocomplete" type="text" class="form-control" :placeholder="translate('search_address')">
                        </div>

                        <div class="form-group mb-3">
                            <div class="row">
                                <div class="col">
                                    <input type="text" class="form-control" :placeholder="translate('number')" required v-model="number" :class="{ 'fill-out': number, 'not-fill-out': !number }">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" :placeholder="translate('street')" required v-model="street" :class="{ 'fill-out': street, 'not-fill-out': !street }">
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="row">
                                <div class="col">
                                    <input type="text" class="form-control" :placeholder="translate('postal_code')" required
                                        v-model="postal_code" :class="{ 'fill-out': postal_code, 'not-fill-out': !postal_code }">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" :placeholder="translate('city')" required v-model="city" :class="{ 'fill-out': city, 'not-fill-out': !city }">
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="row">
                                <div class="col">
                                    <input type="text" class="form-control" :placeholder="translate('state')" required v-model="state" :class="{ 'fill-out': state, 'not-fill-out': !state }">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Country" required v-model="country"
                                         readonly :class="{ 'fill-out': country, 'not-fill-out': !country }">
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="row">
                                <div class="col">
                                    <input type="password" class="form-control" :placeholder="translate('password')" required
                                        v-model="password" :class="{ 'fill-out': password, 'not-fill-out': !password }">
                                </div>
                                <div class="col">
                                    <input type="password" class="form-control" :placeholder="translate('confirm_password')" required
                                        v-model="re_password" :class="{ 'fill-out': re_password, 'not-fill-out': !re_password }">
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-3">
                            <button type="submit" class="btn btn-login2 me-2">{{ translate('sign_up') }}</button>
                            <br>
                            <div class="notification is-danger" v-if="errors.length">
                                <p v-for="error in errors" :key="error">{{ error }}</p>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import Swal from 'sweetalert2'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
    name: 'SignUp',
    mixins: [en, fr, nl],
    data() {
        return {
            email: '',
            first_name: '',
            last_name: '',
            phone: '',
            extention: '',
            street: '',
            postal_code: '',
            state: '',
            number: '',
            city: '',
            title: 'Mr',
            country: 'Belgium',
            password: '',
            re_password: '',
            errors: []
        }
    },
    computed: {
        lang(){
      return this.$store.state.lang
    },
},
    mounted() {
        document.title = 'Sign UP | Eurekali'
        var autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete"),);
        autocomplete.setComponentRestrictions({ country: ["be"] });


        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (!place.address_components) {
                return;
            }

            let country = '';
            let postalCode = '';
            let state = '';
            let city = '';
            let number = '';
            let street = '';

            for (const component of place.address_components) {
                if (component.types.includes("country")) {
                    country = component.long_name;
                }
                if (component.types.includes("postal_code")) {
                    postalCode = component.long_name;
                }
                if (component.types.includes("administrative_area_level_1")) {
                    state = component.long_name;
                }
                if (component.types.includes("locality")) {
                    city = component.long_name;
                }
                if (component.types.includes("street_number")) {
                    number = component.long_name;
                }
                if (component.types.includes("route")) {
                    street = component.long_name;
                }
            }

            this.country = country;
            this.postal_code = postalCode;
            this.state = state;
            this.city = city;
            this.number = number;
            this.street = street;
        });


        //---------Country code phone
        const phoneInput = this.$refs.phoneInput;
        intlTelInput(phoneInput, {
            separateDialCode: true,
            preferredCountries: ["be"],
        });
        //------------------------

        this.ShowSignupAlert()

    },
    methods: {
        translate(prop){
      return this[this.lang][prop]; 
    },
    ShowSignupAlert() {
      Swal.fire({
        icon: 'question',
        title: this.translate('registration'),
        text: this.translate('registration_text'),
        showCancelButton: true,
        confirmButtonText: this.translate('agent'),
        cancelButtonText: this.translate('individual'),
        confirmButtonColor: '#E5097F',
        cancelButtonColor: '#9b0656',
      }).then((result) => {
        if (result.isConfirmed) {
            this.$router.push('/signup-agent')
        }
      });
    },
        submitForm() {
            this.errors = []
            if (this.email === '') {
                this.errors.push('The Email is missing')
            }
            if (this.password === '') {
                this.errors.push('The password is too short')
            }
            if (this.password !== this.re_password) {
                this.errors.push('The passwords doesn\'t match')
            }
            if (!this.errors.length) {


                const phoneInput = this.$refs.phoneInput;
            const iti = intlTelInputGlobals.getInstance(phoneInput);
            const selectedCountryCode = "+" + iti.getSelectedCountryData().dialCode;
        
            
            const extention = selectedCountryCode
            


                const formData = {
                    email: this.email,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    phone: extention + this.phone,
                    street: this.street,
                    postal_code: this.postal_code,
                    state: this.state,
                    number: this.number,
                    city: this.city,
                    country: this.country,
                    title: this.title,
                    password: this.password,
                    re_password: this.re_password
                }
                axios
                    .post("/api/v1/users/", formData)
                    .then(response => {
                        toast({
                            message: this.translate('register_mssg'),
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 3000,
                            position: 'top-right',
                        })
                        this.$router.push('/login')
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                            console.log(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please try again')

                            console.log(JSON.stringify(error))
                        }
                    })
            }
        }
    }
}
</script>

<style>

.btn-login2{
    background-color: #E5097F;
    color: white;
    margin-left: 15px;
    border-radius: 20px;
    font-weight: bold;
    padding-left: 25px;
    padding-right: 25px;
}

.btn-login2:hover{
    background-color: #9b0656;
    color: white;
}
</style>