<template>
    <div ref="scrollTarget"></div>

    <section style="margin-top: 30px; margin-bottom: 100px;">

        <ProcessTimeLine />

        <div class="mobile-prices"
            style="width: 100%; background-color: #FEEEF6; text-align: center; padding-top: 10px; padding-bottom: 10px; margin-top: -20px; margin-bottom: 30px;">
            <img src="../assets/img/payment_mobile_icon.svg"> <span
                style="color: #E5097F; margin-left: 5px; font-weight: 600;">{{
                    translate("payment") }}</span>
        </div>

        <div class="container mt-4">
            <div class="row justify-content-center">
                <div class="col-md-8 mb-4">
                    <div class="card mb-4" style="border-radius: 20px;">
                        <div class="card-header py-3"
                            style="background-color: #E5097F; border-top-right-radius: 20px; border-top-left-radius: 20px;">
                            <h5 class="mb-0" style="color: white; font-weight: bold;"><img src="../assets/img/payment_icon.svg"
                                    class="img-fluid" style="margin-right: 8px; margin-left: 5px;"> {{ translate('payment_method') }}</h5>
                        </div>
                        <div class="card-body">

                            <div class="row justify-content-center">
                                <template v-if="this.$store.state.shipment.origin_country === 'BE'">
                                <div class="col-md-4 mb-1 text-center">
                                    <strong style="color: #9b0656 ;">{{ translate('pay_credit_card') }}</strong>
                                    <ul style="margin-top: 10px; margin-bottom: 15px;">
                                        <li>
                                            <router-link to="/payment/creditcard">
                                                <div class="container-image">
                                                    <img src="../assets/img/creditpay.svg">
                                                    <div class="middle" style="width: 100%;">
                                                        <div class="text">{{ translate('pay_credit_card') }}</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4 mb-1 text-center">
                                    <strong style="color: #9b0656 ;">{{ translate('pay_bancontact') }}</strong>
                                    <ul style="margin-top: 10px; margin-bottom: 15px;">
                                        <li>
                                            <router-link to="/payment/bancontact">
                                                <div class="container-image">
                                                    <img src="../assets/img/banconpay.svg">
                                                    <div class="middle" style="width: 100%;">
                                                        <div class="text">{{ translate('pay_bancontact') }}</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                                <template v-if="shipment.payment_cash">
                                    <div class="col-md-4 mb-1 text-center">
                                        <strong style="color: #9b0656 ;">{{ translate('pay_cash') }}</strong>
                                        <ul style="margin-top: 10px;">
                                            <li>
                                                <router-link to="/payment/cash">
                                                    <div class="container-image">
                                                        <img src="../assets/img/cashpay.svg">
                                                        <div class="middle" style="width: 100%;">
                                                            <div class="text">{{ translate('pay_cash') }}</div>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                            </div>


                        </div>
                    </div>
                </div>


                <div class="col-md-4 mb-4">
                    <div class="card mb-4" style="background-color: #FEEEF6; border-radius: 20px;border: none;">
                        <div class="card-header py-3"
                            style="background-color: #E5097F; border-top-right-radius: 20px; border-top-left-radius: 20px;">
                            <h5 class="mb-0" style="color: white;font-weight: bold;"><i class="fa fa-bars"
                                    aria-hidden="true"></i> {{ translate('summary') }}</h5>
                        </div>
                        <div class="card-body">
                            <ul class="list-group list-group-flush">
                                <template v-if="insure_my_parcel">
                                    <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                        <div>
                                            <strong style="color: #E5097F;"><i class="fa fa-check-circle"
                                                    aria-hidden="true"></i> {{ translate('assurance') }}</strong>
                                        </div>
                                        <span><strong style="color: #E5097F;"> {{ assurance_amount }} <span
                                                    v-if="this.$store.state.shipment.origin_country === 'BE'">&euro;</span><span
                                                    v-else>MAD</span></strong></span>
                                    </li>
                                </template>
                                <template v-else>
                                    <li class="d-flex justify-content-between align-items-center border-0 px-0">
                                        <div>
                                            <strong style="color: red;"><i class="fa fa-exclamation-triangle"
                                                    aria-hidden="true"></i> {{ translate('no_insurance') }}</strong>
                                        </div>
                                    </li>
                                </template>

                                <template v-if="this.$store.state.shipment.origin_country === 'BE'">
                                    <template v-if="home_collection">
                                        <li
                                            class="d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                            <div>
                                                <strong style="color: #E5097F;"><i class="fa fa-home"></i> {{
                                                    translate('home_collection') }}</strong>
                                            </div>
                                            <span><strong style="color: #E5097F;"> {{ home_collection_amount }}
                                                    &euro;</strong></span>
                                        </li>
                                    </template>
                                    <template v-if="shipment.printer_option">
                                        <li
                                            class="d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                            <div>
                                                <strong style="color: #E5097F;"><i class="fa fa-print"></i> {{
                                                    translate('printer') }}</strong>
                                            </div>
                                            <span><strong style="color: #E5097F;"> {{ shipment.printer_amount }}
                                                    &euro;</strong></span>
                                        </li>
                                    </template>
                                </template>

                                <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-0">
                                    <div>
                                        <strong style="color: #E5097F;">{{ translate('shipment_price') }}</strong>
                                    </div>
                                    <span><strong style="color: #E5097F;"> {{ parseFloat((parseFloat(shipment_price) +
                                        parseFloat(vat_price))).toFixed(2) }} <span
                                                v-if="this.$store.state.shipment.origin_country === 'BE'">&euro;</span><span
                                                v-else>MAD</span></strong></span>
                                </li>

                                <template v-if="discount">
                                    <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-2">
                                        <div>
                                            <strong style="color: red;">Discount</strong>
                                        </div>
                                        <span><strong style="color: red;">- {{ discount }} %</strong></span>
                                    </li>
                                    <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-1"
                                        style="margin-top: 20px;">
                                        <div>
                                            <strong style="color: #E5097F;">{{ translate('total_amount') }}</strong>
                                        </div>
                                        <span><strong style="color: #E5097F;">{{ parseFloat(parseFloat(price) * (1 -
                                            parseFloat(discount) / 100)).toFixed(2)
                                                }}
                                                &euro;</strong></span>
                                    </li>
                                </template>
                                <template v-else>
                                    <template v-if="this.$store.state.shipment.origin_country === 'BE'">
                                        <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-1"
                                            style="margin-top: 20px;">
                                            <div>
                                                <strong style="color: #E5097F;">{{ translate('total_amount') }}</strong>
                                            </div>
                                            <template v-if="home_collection">
                                                <template v-if="shipment.printer_option">
                                                    <span><strong style="color: #E5097F;">{{ (parseFloat(price) +
                                                        parseFloat(home_collection_amount) +
                                                        parseFloat(shipment.printer_amount)).toFixed(2) }}
                                                            &euro;</strong></span>
                                                </template>
                                                <template v-else>
                                                    <span><strong style="color: #E5097F;">{{ (parseFloat(price) +
                                                        parseFloat(home_collection_amount)).toFixed(2) }}
                                                            &euro;</strong></span>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <span><strong style="color: #E5097F;">{{ price }} &euro;</strong></span>
                                            </template>
                                        </li>
                                    </template>
                                    <template v-if="this.$store.state.shipment.origin_country === 'MA'">
                                        <li class="d-flex justify-content-between align-items-center border-0 px-0 mb-1"
                                            style="margin-top: 20px;">
                                            <div>
                                                <strong style="color: #E5097F;">{{ translate('total_amount') }}</strong>
                                            </div>
                                            <span><strong style="color: #E5097F;">{{ price }} MAD</strong></span>
                                        </li>
                                    </template>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
  
  
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import moment from 'moment';
import Swal from 'sweetalert2'
import ProcessTimeLine from '@/components/ProcessTimeLine.vue';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';



export default {
    name: 'NewOrder',
    mixins: [en, fr, nl],
    components: {
        ProcessTimeLine
    },
    data() {
        return {
            new_country: "",
            price: "",
            package_type: "",
            eu_country: "",
            number: "",
            street: "",
            postal_code: "",
            city: "",
            state: "",
            country: "",
            email: "",
            phone: "",
            first_name: "",
            last_name: "",
            VAT: "",
            vat_price: "",
            shipment_price: "",
            discount_choice: "",
            discount: 0,
            payment_cash: "",
            cash_token: "",

            shipment: {},
            stripe: {},
            card: {},
            errors: [],

            shipment_id: "",
            sender_id: "",
            receiver_id: "",
            sender: {},
            receiver: {},
            service_point_id: "",
            service_point: {},

            insure_my_parcel: "",
            assurance_amount: "",
            home_collection: "",
            home_collection_amount: "",

            isLoading: false
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    created() {
        if (!this.$store.state.shipment.price) {
            this.$router.push('/new_order')
        }
    },
    mounted() {
        document.title = 'Payment | Eurekali'
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0)

        this.new_country = this.$store.state.shipment.country
        this.price = this.$store.state.shipment.price
        this.package_type = this.$store.state.shipment.package_type
        this.eu_country = this.$store.state.shipment.eu_country

        this.number = this.$store.state.shipment.sender.number
        this.street = this.$store.state.shipment.sender.street
        this.postal_code = this.$store.state.shipment.sender.postal_code
        this.city = this.$store.state.shipment.sender.city
        this.state = this.$store.state.shipment.sender.state
        this.country = this.$store.state.shipment.sender.country
        this.email = this.$store.state.shipment.sender.email
        this.phone = this.$store.state.shipment.sender.phone
        this.first_name = this.$store.state.shipment.sender.first_name
        this.last_name = this.$store.state.shipment.sender.last_name
        this.choice = false

        this.VAT = this.$store.state.shipment.VAT
        this.assurance_amount = this.$store.state.shipment.assurance_amount
        if (this.$store.state.shipment.insure_my_parcel) {
            this.shipment_price = parseFloat(parseFloat(parseFloat(this.price) / parseFloat(1 + parseFloat(this.VAT) / 100)) - parseFloat(this.assurance_amount)).toFixed(2)
        } else {
            this.shipment_price = parseFloat(parseFloat(this.price) / parseFloat(1 + parseFloat(this.VAT) / 100)).toFixed(2)
        }
        this.vat_price = parseFloat(parseFloat(this.price) - parseFloat(parseFloat(this.price) / parseFloat(1 + parseFloat(this.VAT) / 100))).toFixed(2)


        this.shipment = this.$store.state.shipment
        this.sender = this.$store.state.shipment.sender
        this.receiver = this.$store.state.shipment.receiver
        this.service_point_id = this.$store.state.shipment.service_point_id

        this.insure_my_parcel = this.$store.state.shipment.insure_my_parcel

        this.home_collection = this.$store.state.shipment.home_collection
        this.home_collection_amount = this.$store.state.shipment.home_collection_amount





    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        async getServicePoint() {
            this.$store.commit('setIsLoading', true)

            await axios.get(`/api/v1/servicepoints/${this.service_point_id}`)
                .then(response => {
                    if (response.data) {
                        this.service_point = response.data
                    } else {
                        toast({
                            message: 'Service Point is not valide!',
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'top-right',
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
    }
}
</script>
  
  
<style>
.ulclass {
    position: relative;
    overflow: hidden;
}

.ulclass li.list-group-item {
    position: relative;
    z-index: 1;
    transition: color 0.5s ease, background-color 0.5s ease;
}

.ulclass li.list-group-item:hover {
    background-color: #E5097F;
    /* Change this to the desired background color */
}

.ulclass li.list-group-item:hover span {
    color: #FFFFFF;
    /* Change this to the desired text color */
}

.ulclass li.list-group-item::before,
.ulclass li.list-group-item::after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    background: #E5097F;
    /* Change this to the desired background color */
    z-index: -1;
    transition: transform 0.9s ease;
}

.ulclass li.list-group-item::before {
    left: 0;
    transform: scaleX(0);
    transform-origin: left;
}

.ulclass li.list-group-item::after {
    right: 0;
    transform: scaleX(0);
    transform-origin: right;
}

.ulclass li.list-group-item:hover::before,
.ulclass li.list-group-item:hover::after {
    transform: scaleX(1);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.container-image {
    position: relative;

}



.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}



.container-image:hover .middle {
    opacity: 1;
}

.text {
    background-color: #EE80B7;
    opacity: 0.8;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 32px 32px;
}
</style>
