<template>
  <section style=" margin-bottom: 40px;">

    <div class="header-container position-relative" style="background-color: #F396B3;">
      <div class="background-image1-laptop img-fluid w-100"></div>
      <div class="background-image1-mobile img-fluid w-100"></div>
      <div class="background-image1-ipad img-fluid w-100"></div>

      <div class="position-absolute top-50 start-50 translate-middle text-white laptop-prices">
        <p style="font-weight: bold; font-size: 30px;">{{ translate('welcome_message') }} </p>
        <p style="font-size: 20px; font-weight: 400;">{{ translate('sous_welcome_message') }}</p>
        <p class="attention-icon mobile-prices" style="text-align: center; font-size: 30px; margin-top: 15px;"><i
            class="fa fa-arrow-down"></i></p>
      </div>



      <div class="position-absolute bottom-0 text-white mobile-prices text-center"
        style="width: 100%; margin-bottom: 20px;">
        <p style="font-weight: bold; font-size: 20px; padding-right: 15px; padding-left: 15px;">{{
          translate('welcome_message') }}</p>
        <p style="font-size: 18px; font-weight: 400; padding-right: 15px; padding-left: 15px;">{{
          translate('sous_welcome_message') }}</p>
      </div>


    </div>

    <ProcessTimeLine />

    <div class="mobile-prices"
      style="width: 100%; background-color: #FEEEF6; text-align: center; padding-top: 10px; padding-bottom: 10px; margin-top: -20px; margin-bottom: 30px;">
      <img src="../assets/img/shipping_icon.svg"> <span style="color: #E5097F; margin-left: 5px; font-weight: 600;">{{
        translate("shipping_route") }}</span>
    </div>

    <div class="container mobile-prices">
      <form style="padding: 10px; border-radius: 20px; border: 1px solid darkgray;">

        <div style="text-align: center; color: #E5097F; font-weight: 600; margin-bottom: 10px;">{{
          translate("ship_from") }}</div>

        <div class="input-group mb-3">
          <span class="input-group-text"
            style="border-bottom-left-radius: 40px; border-top-left-radius: 40px; background-color: #FEEEF6; border : 0px; color: #E5097F; padding: 15px;">
            <i class="fa fa-search" aria-hidden="true"></i>
          </span>
          <Select2 v-model="origin_country" :options="countries2" :placeholder="translate('choose_country')"
                          :class="{ 'fill-out': origin_country, 'not-fill-out': !origin_country, 'form-control': true }"
                          :settings="{ width: '100%' }" />
        </div>

        <div :style="express_choice ? { display: 'block' } : { display: 'none' }">
          <div class="input-group mb-3">
            <input id="autocomplete-mobile" type="text" class="form-control fill-out"
              style="border-radius: 40px; border : 1px solid #E5097F; padding: 10px;"
              :placeholder="translate('postal_code')" v-model="s_postal_code"
              :class="{ 'fill-out': s_postal_code, 'not-fill-out': !s_postal_code }">
          </div>
        </div>

        <div style="text-align: center; color: #E5097F; font-weight: 600; margin-bottom: 10px;">{{ translate("ship_to") }}
        </div>

        <div class="input-group mb-3">
          <span class="input-group-text"
            style="border-bottom-left-radius: 40px; border-top-left-radius: 40px; background-color: #FEEEF6; border : 0px; color: #E5097F; padding: 15px;">
            <i class="fa fa-search" aria-hidden="true"></i>
          </span>
          <Select2 v-model="new_country" :options="countries" :placeholder="translate('choose_country')"
            :class="{ 'fill-out': new_country, 'not-fill-out': !new_country, 'form-control': true }"
            :settings="{ width: '100%' }"
            style="border-bottom-right-radius: 40px; border-top-right-radius: 40px; border : 0px; padding: 10px;" />
        </div>

        <div :style="express_choice ? { display: 'block' } : { display: 'none' }">
          <div class="input-group mb-3">
            <input id="autocomplete2-mobile" type="text" class="form-control fill-out"
              style="border-radius: 40px; border : 1px solid #E5097F; padding: 10px;"
              :placeholder="translate('postal_code')" v-model="r_postal_code"
              :class="{ 'fill-out': r_postal_code, 'not-fill-out': !r_postal_code }">
          </div>
        </div>

        <div class="row justify-content-center" ref="scrollTarget" style="margin-top: 30px;">
          <button class="btn btn-secondary dropdown-toggle" type="button"
            style="color: black; background-color: #F5F5F5; border: 1px solid #E5097F; width: 80%; border-radius: 20px; font-size: 14px;"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src="../assets/img/pink_pack.png" style="margin-right: 8px; height: 33px;"> <span
              style="color: black; font-weight: 600;">{{ package_type ||
                translate('choose_package') }}</span>
          </button>
          <ul class="dropdown-menu" style="border-radius: 25px;">
            <li>
              <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Envelope')">
                <div class="item-content">
                  <img src="../assets/img/env.png" class="social-logo" style="width: 30px; height: 20px;"> <b
                    style="color: #E5097F;">Envelope</b> <strong>(Max 0.5 kg - 35 x 27.5 x 1 cm)</strong>
                </div>
              </a>
            </li>

            <li>
              <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Extra small')">
                <div class="item-content">
                  <img src="../assets/img/pack.png" class="social-logo" style="width: 30px; height: 20px;"> <b
                    style="color: #E5097F;">Extra small</b> <strong>(Max 1 kg - 33.7 x 18.2 x 8.1 cm)</strong>
                </div>
              </a>
            </li>

            <li>
                      <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Small')">
                        <div class="item-content">
                          <img src="../assets/img/pack.png" class="social-logo" style="width: 32px; height: 22px;"> <b
                            style="color: #E5097F;">Small</b> <strong>(Max 2 kg - 33.7 x 32.2 x 9.2 cm)</strong>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Medium')">
                        <div class="item-content">
                          <img src="../assets/img/pack.png" class="social-logo" style="width: 34px; height: 24px;"> <b
                            style="color: #E5097F;">Medium</b> <strong>(Max 5 kg - 33.7 x 32.2 x 18 cm)</strong>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Large')">
                        <div class="item-content">
                          <img src="../assets/img/pack.png" class="social-logo" style="width: 36px; height: 26px;"> <b
                            style="color: #E5097F;">Large</b> <strong>(Max 10 kg - 33.7 x 32.2 x 34.5 cm)</strong>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Large Extra')">
                        <div class="item-content">
                          <img src="../assets/img/pack.png" class="social-logo" style="width: 37px; height: 27px;"> <b
                            style="color: #E5097F;">LX</b> <strong>(Max 15 kg - 41.7 x 35.9 x 36.9 cm)</strong>
                        </div>
                      </a>
                    </li>

                    <li>

                      <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Extra large')">
                        <div class="item-content">
                          <div class="item-content">
                            <img src="../assets/img/pack.png" class="social-logo" style="width: 38px; height: 28px;"> <b
                              style="color: #E5097F;">XL</b> <strong>(Max 20 kg - 48.1 x 40.4 x 36.9 cm)</strong>
                          </div>
                        </div>
                      </a>
                    </li>
                    <div class="item-content">
                      <li>
                        <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Extra Extra large')">
                          <div class="item-content">
                            <img src="../assets/img/pack.png" class="social-logo" style="width: 41px; height: 31px;"> <b
                              style="color: #E5097F;">XXL</b> <strong>(Max 25 kg - 54.1 x 44.4 x 40.9 cm)</strong>
                          </div>
                        </a>
                      </li>
            </div>

            <li>
              <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Custom')">
                <div class="item-content">
                  <img src="../assets/img/pack.png" class="social-logo" style="width: 30px; height: 20px;"> <b
                    style="color: #E5097F;">{{ translate("other_size") }}</b>
                </div>
              </a>
            </li>
          </ul>
        </div>

        <template v-if="this.package_type == 'Custom'">
          <div class="row mb-2">
            <div class="col-md">
              <div class="form-outline">
                <label></label>
                <input type="number" class="form-control" v-model="custom_weight" :placeholder="translate('weight')"
                  :class="{ 'fill-out': custom_weight, 'not-fill-out': !custom_weight }" @input="validateCustomWeight">
              </div>
            </div>
            <div class="col-md" ref="scrollTarget">
              <div class="form-outline">
                <label></label>
                <input type="number" class="form-control" v-model="custom_length" :placeholder="translate('length')"
                  :class="{ 'fill-out': custom_length, 'not-fill-out': !custom_length }" @input="validateCustomLength">
              </div>
            </div>
            <div class="col-md">
              <div class="form-outline">
                <label></label>
                <input type="number" class="form-control" v-model="custom_width" :placeholder="translate('width')"
                  :class="{ 'fill-out': custom_width, 'not-fill-out': !custom_width }" @input="validateCustomWidth">
              </div>
            </div>
            <div class="col-md">
              <div class="form-outline">
                <label></label>
                <input type="number" class="form-control" v-model="custom_height" :placeholder="translate('height')"
                  :class="{ 'fill-out': custom_height, 'not-fill-out': !custom_height }" @input="validateCustomHeight">
              </div>
            </div>
          </div>

          <template
            v-if="this.new_country && this.package_type && this.custom_weight && this.custom_width && this.custom_height && this.custom_length">
            <div class="row mb-2 justify-content-center">
              <button class="btn btn-sendparcel" @click="testalert2">{{ translate('send_my_parcel') }}</button>
            </div>
          </template>
          <template v-else>
            <div class="row mb-2 justify-content-center">
              <button class="btn btn-sendparcel-deactivate" @click="showalertDimensions">{{
                translate('send_my_parcel') }}</button>
            </div>
          </template>
        </template>
      </form>

      <div ref="scrollTarget2"></div>

      <template v-if="new_country && package_type && delivery_days && weight">
        <div>
          <div style="text-align: center; color: #E5097F; font-weight: 600; margin-top: 70px; font-size: 18px;">
            {{ translate('rates_times') }}
          </div>
          <div class="card-body">
            <div class="row mb-4 justify-content-center">

              <template v-if="price">
                <div class="row mb-4 justify-content-center">
                  <div class="col-md-6 custom-price" @click="chooseNormalService">
                    <p style="color: black;"><span style="font-weight: 600;">{{ translate('delivery_time') }}
                        :</span> {{ delivery_days }} {{ translate('business_day') }}</p>
                    <p style="color: black;"><span style="font-weight: 600;">{{ translate('package_type') }}
                        :</span> {{ package_type }}</p>
                    <p style="color: black;"><span style="font-weight: 600;">{{ translate('price') }} :</span> <span
                        class="badge rounded-pill" style="font-size: 0.8em; background-color: #32BE6F;">{{ price }} <span v-if="origin_country == 'BE'">&euro;</span> <span v-if="origin_country == 'MA'">MAD</span></span></p>
                    <template v-if="normal_service">
                      <br>
                      <template v-if="new_country && package_type && weight && delivery_days && price">
                        <p><button type="button" class="btn btn-table-active btn-md" @click="setfields"
                            style="width: 100%; border-radius: 20px;"> {{ translate('choose_this_offer') }}
                          </button></p>
                      </template>
                      <template v-else>
                        <p><button type="button" class="btn btn-table-deactive btn-md"
                            style="width: 100%; border-radius: 20px;"> {{ translate('choose_this_offer') }}
                          </button></p>
                      </template>
                    </template>
                  </div>
                </div>
              </template>

              <template v-if="price_cit && s_postal_code && r_postal_code">
                <div class="row justify-content-center" style="margin-top: 15px;">
                  <div class="col-md-6 custom-price2" @click="chooseExpressService">
                    <p
                      style="background-color: #D6A761; font-size: 18px; border-top-right-radius: 25px; border-top-left-radius: 25px; padding: 5px;">
                      <img src="../assets/img/fast-icon.svg"> <span style="font-weight: 600; color: white;">{{
                        translate('ultra_fast') }}</span>
                    </p>

                    <div style="padding: 20px;">
                      <p style="color: black;"><span style="font-weight: 600;">{{ translate('delivery_time') }}
                          :</span> {{ translate('same_day') }}</p>
                      <p style="color: black;"><span style="font-weight: 600;">{{ translate('package_type') }}
                          :</span> {{ package_type }}</p>
                      <p style="color: black;"><span style="font-weight: 600;">{{ translate('price') }} : </span>
                        <span class="badge rounded-pill" style="font-size: 0.8em; background-color: #32BE6F;">{{
                          price_cit }} &euro; </span>
                      </p>
                      <template v-if="express_service">
                        <br>
                        <p><span style="color: #E5097F;"><i class="fa fa-check-circle"></i> {{
                          translate('ultra_fast_text') }}</span></p>
                        <br>
                        <template v-if="new_country && package_type && weight && delivery_days && price_cit">
                          <p><button type="button" class="btn btn-table-active btn-md" @click="setfields2"
                              style="width: 100%; border-radius: 20px;"> {{ translate('choose_this_offer') }}
                            </button></p>
                        </template>
                        <template v-else>
                          <p><button type="button" class="btn btn-table-deactive btn-md"
                              style="width: 100%; border-radius: 20px;"> {{ translate('choose_this_offer') }}
                            </button></p>
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="container mt-4 laptop-prices">
      <div class="row justify-content-center">
        <div class="col-lg-8 mb-4">
          <div class="card mb-4" style="border-radius: 10px; border: 1px solid darkgray; border-top: none">
            <div class="card-header py-3"
              style="background-color: #E5097F; border-top-right-radius: 10px; border-top-left-radius: 10px;">
              <h5 class="mb-0" style="color: white; font-weight: bold;"><img src="../assets/img/ship_icon.svg"
                  style="margin-right: 8px; margin-left: 5px; transform: scale(1.6);">
                {{ translate("shipping_route") }}</h5>
            </div>
            <div class="card-body">
              <form>
                <div class="row mb-4">
                  <div class="col-6">
                    <div class="row mb-3">
                      <div class="form-outline">
                        <label class="form-label">{{ translate("ship_from") }}</label>
                        <Select2 v-model="origin_country" :options="countries2" :placeholder="translate('choose_country')"
                          :class="{ 'fill-out': origin_country, 'not-fill-out': !origin_country, 'form-control': true }"
                          :settings="{ width: '100%' }" />
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row mb-3">
                      <div class="form-outline">
                        <label class="form-label" for="form7Example2">{{ translate("ship_to") }}</label>
                        <Select2 v-model="new_country" :options="countries" :placeholder="translate('choose_country')"
                          :class="{ 'fill-out': new_country, 'not-fill-out': !new_country, 'form-control': true }"
                          :settings="{ width: '100%' }" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6" :style="express_choice ? { display: 'block' } : { display: 'none' }">
                    <div class="row" style="margin-bottom: 20px;">
                      <div class="form-outline">
                        <input id="autocomplete" type="text" class="form-control fill-out"
                          :placeholder="translate('postal_code')" v-model="s_postal_code"
                          :class="{ 'fill-out': s_postal_code, 'not-fill-out': !s_postal_code }">
                      </div>
                    </div>
                  </div>
                  <div class="col-6" :style="express_choice ? { display: 'block' } : { display: 'none' }">
                    <div class="row" style="margin-bottom: 20px;">
                      <div class="form-outline">
                        <input id="autocomplete2" type="text" class="form-control fill-out"
                          :placeholder="translate('postal_code')" v-model="r_postal_code"
                          :class="{ 'fill-out': r_postal_code, 'not-fill-out': !r_postal_code }">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center" ref="scrollTarget">
                  <button class="btn btn-secondary dropdown-toggle" type="button"
                    style="color: black; background-color: #F5F5F5; border: 1px solid #E5097F; width: 80%; border-radius: 30px;"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="../assets/img/pink_pack.png" style="margin-right: 8px; height: 33px;"> <span
                      style="color: black; font-weight: 600;">{{ package_type || translate('choose_package') }}</span>
                  </button>
                  <ul class="dropdown-menu" style="width: 80%; border-radius: 20px;">
                    <li>
                      <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Envelope')">
                        <div class="item-content">
                          <img src="../assets/img/env.png" class="social-logo" style="width: 30px; height: 20px;"> <b
                            style="color: #E5097F;">Envelope</b> <strong>(Max 0.5 kg - 35 x 27.5 x 1 cm)</strong>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Extra small')">
                        <div class="item-content">
                          <img src="../assets/img/pack.png" class="social-logo" style="width: 30px; height: 20px;"> <b
                            style="color: #E5097F;">Extra small</b> <strong>(Max 1 kg - 33.7 x 18.2 x 8.1 cm)</strong>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Small')">
                        <div class="item-content">
                          <img src="../assets/img/pack.png" class="social-logo" style="width: 32px; height: 22px;"> <b
                            style="color: #E5097F;">Small</b> <strong>(Max 2 kg - 33.7 x 32.2 x 9.2 cm)</strong>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Medium')">
                        <div class="item-content">
                          <img src="../assets/img/pack.png" class="social-logo" style="width: 34px; height: 24px;"> <b
                            style="color: #E5097F;">Medium</b> <strong>(Max 5 kg - 33.7 x 32.2 x 18 cm)</strong>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Large')">
                        <div class="item-content">
                          <img src="../assets/img/pack.png" class="social-logo" style="width: 36px; height: 26px;"> <b
                            style="color: #E5097F;">Large</b> <strong>(Max 10 kg - 33.7 x 32.2 x 34.5 cm)</strong>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Large Extra')">
                        <div class="item-content">
                          <img src="../assets/img/pack.png" class="social-logo" style="width: 37px; height: 27px;"> <b
                            style="color: #E5097F;">LX</b> <strong>(Max 15 kg - 41.7 x 35.9 x 36.9 cm)</strong>
                        </div>
                      </a>
                    </li>

                    <li>

                      <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Extra large')">
                        <div class="item-content">
                          <div class="item-content">
                            <img src="../assets/img/pack.png" class="social-logo" style="width: 38px; height: 28px;"> <b
                              style="color: #E5097F;">XL</b> <strong>(Max 20 kg - 48.1 x 40.4 x 36.9 cm)</strong>
                          </div>
                        </div>
                      </a>
                    </li>
                    <div class="item-content">
                      <li>
                        <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Extra Extra large')">
                          <div class="item-content">
                            <img src="../assets/img/pack.png" class="social-logo" style="width: 41px; height: 31px;"> <b
                              style="color: #E5097F;">XXL</b> <strong>(Max 25 kg - 54.1 x 44.4 x 40.9 cm)</strong>
                          </div>
                        </a>
                      </li>
                    </div>

                    <li>
                      <a class="dropdown-item" style="cursor: pointer;" @click="updateSelection('Custom')">
                        <div class="item-content">
                          <img src="../assets/img/pack.png" class="social-logo" style="width: 30px; height: 20px;"> <b
                            style="color: #E5097F;">{{ translate("other_size") }}</b>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>

                <template v-if="this.package_type == 'Custom'">
                  <div class="row mb-2">
                    <div class="col-md">
                      <div class="form-outline">
                        <label></label>
                        <input type="number" class="form-control" v-model="custom_weight"
                          :placeholder="translate('weight')"
                          :class="{ 'fill-out': custom_weight, 'not-fill-out': !custom_weight }"
                          @input="validateCustomWeight">
                      </div>
                    </div>
                    <div class="col-md" ref="scrollTarget">
                      <div class="form-outline">
                        <label></label>
                        <input type="number" class="form-control" v-model="custom_length"
                          :placeholder="translate('length')"
                          :class="{ 'fill-out': custom_length, 'not-fill-out': !custom_length }"
                          @input="validateCustomLength">
                      </div>
                    </div>
                    <div class="col-md">
                      <div class="form-outline">
                        <label></label>
                        <input type="number" class="form-control" v-model="custom_width" :placeholder="translate('width')"
                          :class="{ 'fill-out': custom_width, 'not-fill-out': !custom_width }"
                          @input="validateCustomWidth">
                      </div>
                    </div>
                    <div class="col-md">
                      <div class="form-outline">
                        <label></label>
                        <input type="number" class="form-control" v-model="custom_height"
                          :placeholder="translate('height')"
                          :class="{ 'fill-out': custom_height, 'not-fill-out': !custom_height }"
                          @input="validateCustomHeight">
                      </div>
                    </div>
                  </div>

                  <template
                    v-if="this.new_country && this.package_type && this.custom_weight && this.custom_width && this.custom_height && this.custom_length">
                    <div class="row mb-2 justify-content-center">
                      <button class="btn btn-sendparcel" @click="testalert2">{{ translate('send_my_parcel') }}</button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="row mb-2 justify-content-center">
                      <button class="btn btn-sendparcel-deactivate" @click="showalertDimensions">{{
                        translate('send_my_parcel') }}</button>
                    </div>
                  </template>
                </template>
              </form>
            </div>


            <template v-if="new_country && package_type && delivery_days && weight">
              <div>
                <div style="text-align: center; color: #E5097F; font-weight: 600; margin-top: 20px; font-size: 18px;">
                  {{ translate('rates_times') }}
                </div>
                <div class="card-body">
                  <div class="row mb-4 justify-content-center">

                    <template v-if="price">
                      <div class="row mb-4 justify-content-center">
                        <div class="col-md-6 custom-price" @click="chooseNormalService">
                          <p style="color: black;"><span style="font-weight: 600;">{{ translate('delivery_time') }}
                              :</span> {{ delivery_days }} {{ translate('business_day') }}</p>
                          <p style="color: black;"><span style="font-weight: 600;">{{ translate('package_type') }}
                              :</span> {{ package_type }}</p>
                          <p style="color: black;"><span style="font-weight: 600;">{{ translate('price') }} :</span> <span
                              class="badge rounded-pill" style="font-size: 1em; background-color: #32BE6F;">{{ price }}
                              <span v-if="origin_country == 'BE'">&euro;</span> <span v-if="origin_country == 'MA'">MAD</span> </span></p>
                          <template v-if="normal_service">
                            <br>
                            <template v-if="new_country && package_type && weight && delivery_days && price">
                              <p><button type="button" class="btn btn-table-active btn-md" @click="setfields"
                                  style="width: 100%; border-radius: 20px;"> {{ translate('choose_this_offer') }}
                                </button></p>
                            </template>
                            <template v-else>
                              <p><button type="button" class="btn btn-table-deactive btn-md"
                                  style="width: 100%; border-radius: 20px;"> {{ translate('choose_this_offer') }}
                                </button></p>
                            </template>
                          </template>
                        </div>
                      </div>
                    </template>

                    <template v-if="price_cit && s_postal_code && r_postal_code">
                      <div class="row justify-content-center" style="margin-top: 15px;">
                        <div class="col-md-6 custom-price2" @click="chooseExpressService">
                          <p
                            style="background-color: #D6A761; font-size: 18px; border-top-right-radius: 25px; border-top-left-radius: 25px; padding: 5px;">
                            <img src="../assets/img/fast-icon.svg"> <span style="font-weight: 600; color: white;">{{
                              translate('ultra_fast') }}</span>
                          </p>

                          <div style="padding: 20px;">
                            <p style="color: black;"><span style="font-weight: 600;">{{ translate('delivery_time') }}
                                :</span> {{ translate('same_day') }}</p>
                            <p style="color: black;"><span style="font-weight: 600;">{{ translate('package_type') }}
                                :</span> {{ package_type }}</p>
                            <p style="color: black;"><span style="font-weight: 600;">{{ translate('price') }} : </span>
                              <span class="badge rounded-pill" style="font-size: 1em; background-color: #32BE6F;">{{
                                price_cit }} &euro; </span>
                            </p>
                            <template v-if="express_service">
                              <br>
                              <p><span style="color: #E5097F;"><i class="fa fa-check-circle"></i> {{
                                translate('ultra_fast_text') }}</span></p>
                              <br>
                              <template v-if="new_country && package_type && weight && delivery_days && price_cit">
                                <p><button type="button" class="btn btn-table-active btn-md" @click="setfields2"
                                    style="width: 100%; border-radius: 20px;"> {{ translate('choose_this_offer') }}
                                  </button></p>
                              </template>
                              <template v-else>
                                <p><button type="button" class="btn btn-table-deactive btn-md"
                                    style="width: 100%; border-radius: 20px;"> {{ translate('choose_this_offer') }}
                                  </button></p>
                              </template>
                            </template>
                          </div>
                        </div>
                      </div>
                    </template>

                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>

  <hr style="margin-bottom: 200px;">
</template>
    
    
    
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import Select2 from 'vue3-select2-component'
import Datepicker from '@vuepic/vue-datepicker';
import { ref } from 'vue';
import ProcessTimeLine from '@/components/ProcessTimeLine.vue';
import 'swiper/swiper-bundle.css';
import Swiper from 'swiper/bundle';
import { Pagination, Navigation } from 'swiper';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';

Swiper.use([Pagination, Navigation]);

export default {
  name: 'NewOrder',
  mixins: [en, fr, nl],
  components: {
    Select2,
    Datepicker,
    ProcessTimeLine
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },

    maxDate() {
      const maxDate = new Date();
      maxDate.setDate(maxDate.getDate() + 7);
      maxDate.setHours(0, 0, 0, 0);
      return maxDate;
    },
    start_times() {
      return this.generateTimeOptions();
    },
    end_times() {
      return this.generateTimeOptions2();
    },

    specialPostalCodes() {
      return [1000, 1030, 1040, 1050, 1060, 1070, 1080, 1081, 1082, 1083, 1090, 1140, 1150, 1160, 1170, 1180, 1190, 1200];
    },

    specialCountries() {
      return [
        "Angola",
        "Antigua and Barbuda",
        "Aruba",
        "Ascension island",
        "Bahamas",
        "Belize",
        "Benin",
        "Botswana",
        "Bolivia",
        "Bonaire, Sint Eustatius and Saba",
        "Burkina Faso",
        "Burundi",
        "Cameroon",
        "Central African Republic",
        "Comoros",
        "Congo",
        "Congo the Democratic Republic of the",
        "Cook Islands",
        "Cote d’Ivoire",
        "Curaçao",
        "Djibouti",
        "Dominica",
        "East Timor",
        "Equatorial Guinea",
        "Eritrea",
        "Fiji",
        "French Southern Territories",
        "Gambia",
        "Gamon",
        "Ghana",
        "Grenada",
        "Guyana",
        "Heard and McDonald Islands",
        "Hong Kong",
        "Kiribati",
        "Libya",
        "Macau",
        "Malawi",
        "Mali",
        "Mauritania",
        "Nauru",
        "Netherlands Antilles",
        "Niue",
        "North Korea",
        "Qatar",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Sao Tome and Principe",
        "Seychelles",
        "Sierra Leone",
        "Solomon Islands",
        "Suriname",
        "Syria",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Tuvalu",
        "Uganda",
        "United Arab Emirates",
        "Vanuatu",
        "Yemen",
        "Zimbabwe",
        "Congo, The Democratic Republic of the"
      ];
    },
  },
  setup() {
    const date = ref();
    const dateFormat = 'dd-MM-yyyy';

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Check if the current hour is past 18:00
    const currentHour = new Date().getHours();
    const isPast17 = currentHour >= 17;

    if (isPast17) {
      today.setDate(today.getDate() + 1);
    }

    // Calculate the disabled dates (weekends)
    const disabledDates = (date) => {
      const dayOfWeek = date.getDay();
      return dayOfWeek === 0 || dayOfWeek === 6;
    };

    return {
      date,
      dateFormat,
      today,
      disabledDates
    };
  },
  data() {
    return {
      new_country: "",
      price: "",
      price_cit: "",
      package_type: "",
      weight: "",
      delivery_days: "",
      countries: [],
      countries2:[],


      custom_weight: "",
      custom_width: "",
      custom_height: "",
      custom_length: "",

      s_postal_code: "",
      r_postal_code: "",
      express_choice: false,

      express_service: true,
      normal_service: true,

      slides: [],
      origin_country : ""

    }
  },
  async mounted() {
    document.title = 'New Order | Eurekali'
    window.scrollTo(0, 0);
    this.origin_country = this.$store.state.shipment.origin_country
    this.new_country = this.$store.state.shipment.country
    this.package_type = this.$store.state.shipment.package_type
    this.weight = this.$store.state.shipment.weight
    this.s_postal_code = this.$store.state.shipment.s_postal_code
    this.r_postal_code = this.$store.state.shipment.r_postal_code

    //------------Belgium postal codes
    var input = document.getElementById("autocomplete");
    var options = {
      types: ["postal_code"],
      componentRestrictions: { country: "be" },
    };

    var autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.address_components) {
        return;
      }

      let postalCode = "";
      let city = "";
      for (const component of place.address_components) {
        const componentTypes = component.types;

        if (componentTypes.includes("postal_code")) {
          postalCode = component.long_name;
        } else if (componentTypes.includes("locality")) {
          city = component.long_name;
        }
      }

      this.s_postal_code = postalCode;
    });

    //----------mobile
    var input_mobile = document.getElementById("autocomplete-mobile");
    var options_mobile = {
      types: ["postal_code"],
      componentRestrictions: { country: "be" },
    };

    var autocomplete_mobile = new google.maps.places.Autocomplete(input_mobile, options_mobile);
    autocomplete_mobile.addListener("place_changed", () => {
      const place_mobile = autocomplete_mobile.getPlace();
      if (!place_mobile.address_components) {
        return;
      }

      let postalCode_mobile = "";
      let city_mobile = "";
      for (const component_mobile of place_mobile.address_components) {
        const componentTypes_mobile = component_mobile.types;

        if (componentTypes_mobile.includes("postal_code")) {
          postalCode_mobile = component_mobile.long_name;
        } else if (componentTypes_mobile.includes("locality")) {
          city_mobile = component_mobile.long_name;
        }
      }

      this.s_postal_code = postalCode_mobile;
    });
    //-----------------


    axios.get(`/api/v1/countries/${this.$store.state.lang}`)
      .then(response => {
        this.countries = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    this.initializeSwiper();
    await this.getreviews();

    this.countries2=[
        {"id" : "BE", "text": this.translate("belgium")},
        {"id" : "MA", "text": this.translate("morocco")}
      ]

  },
  watch: {
    async new_country(newValue, oldValue) {
      console.log('Country changed from', oldValue, 'to', newValue);
      await this.getCountrCode(this.new_country)
      this.assignautocomplet(this.countrycode)

      if (this.new_country != this.$store.state.shipment.country) {
        this.package_type = ""
      }

      this.price = ""
      this.price_cit = ""
      this.s_postal_code = ""
      this.r_postal_code = ""

      if ((this.countrycode === 'BE' || this.countrycode === 'NL' || this.countrycode === 'LU' || this.countrycode === 'FR' || this.countrycode === 'DE' || this.countrycode === 'CH' || this.countrycode === 'GB' || this.countrycode === 'DK' ) && this.origin_country === 'BE') {
        this.express_choice = true
      } else {
        this.express_choice = false
      }
    },
    async origin_country(newValue, oldValue) {
      console.log('Origin Country changed from', oldValue, 'to', newValue);

      if(this.origin_country == 'MA'){
        this.countries = this.countries.filter(country => country.id !== "Morocco");
        this.new_country = ""
      }

      if(this.origin_country == 'BE'){
        axios.get(`/api/v1/countries/${this.$store.state.lang}`)
      .then(response => {
        this.countries = response.data;
      })
      .catch(error => {
        console.error(error);
      });
      }

      if (this.origin_country != this.$store.state.shipment.origin_country) {
        this.package_type = ""
      }

      this.price = ""
      this.price_cit = ""
      this.s_postal_code = ""
      this.r_postal_code = ""

      if ((this.countrycode === 'BE' || this.countrycode === 'NL' || this.countrycode === 'LU' || this.countrycode === 'FR' || this.countrycode === 'DE' || this.countrycode === 'CH' || this.countrycode === 'GB' || this.countrycode === 'DK' ) && this.origin_country === 'BE') {
        this.express_choice = true
      } else {
        this.express_choice = false
      }
    },

    package_type(newValue, oldValue) {
      if (newValue != oldValue) {
        this.testalert()
      }

      this.checkFields()
    },

    s_postal_code(newValue, oldValue) {
      if (newValue != this.$store.state.shipment.s_postal_code) {
        this.package_type = ""
      }
    },

    r_postal_code(newValue, oldValue) {
      if (newValue != this.$store.state.shipment.r_postal_code) {
        this.package_type = ""
      }
    },

  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    showalertDimensions() {
      event.preventDefault();

      toast({
        message: this.translate('dimensions_alert'),
        type: 'is-danger',
        dismissible: true,
        pauseOnHover: true,
        duration: 4000,
        position: 'top-right',
      })
    },
    assignautocomplet(countrcode) {
      var input1 = document.getElementById("autocomplete2");

      google.maps.event.clearInstanceListeners(input1);

      var options1 = {
        types: ["postal_code"],
        componentRestrictions: { country: countrcode },
      };

      var autocomplete1 = new google.maps.places.Autocomplete(input1, options1);
      autocomplete1.addListener("place_changed", () => {
        const place1 = autocomplete1.getPlace();
        if (!place1.address_components) {
          return;
        }

        let postalCode1 = "";
        let city1 = "";
        for (const component1 of place1.address_components) {
          const componentTypes1 = component1.types;

          if (componentTypes1.includes("postal_code")) {
            postalCode1 = component1.long_name;
          } else if (componentTypes1.includes("locality")) {
            city1 = component1.long_name;
          }
        }

        this.r_postal_code = postalCode1;
      });

      //-------mobile
      var input1_mobile = document.getElementById("autocomplete2-mobile");

      google.maps.event.clearInstanceListeners(input1_mobile);

      var options1_mobile = {
        types: ["postal_code"],
        componentRestrictions: { country: countrcode },
      };

      var autocomplete1_mobile = new google.maps.places.Autocomplete(input1_mobile, options1_mobile);
      autocomplete1_mobile.addListener("place_changed", () => {
        const place1_mobile = autocomplete1_mobile.getPlace();
        if (!place1_mobile.address_components) {
          return;
        }

        let postalCode1_mobile = "";
        let city1_mobile = "";
        for (const component1_mobile of place1_mobile.address_components) {
          const componentTypes1_mobile = component1_mobile.types;

          if (componentTypes1_mobile.includes("postal_code")) {
            postalCode1_mobile = component1_mobile.long_name;
          } else if (componentTypes1_mobile.includes("locality")) {
            city1_mobile = component1_mobile.long_name;
          }
        }

        this.r_postal_code = postalCode1_mobile;
      });
    },
    async getCountrCode(countryname) {
      this.$store.commit('setIsLoading', true)
      await axios.get(`/api/v1/countrycode/${countryname}/`)
        .then(response => {

          if (this.new_country != this.$store.state.shipment.country) {
            this.receiver_city = ""
            this.receiver_postal_code = ""
          } else {
            this.receiver_city = this.$store.state.shipment.receiver_city
            this.receiver_postal_code = this.$store.state.shipment.receiver_postal_code
          }

          this.countrycode = response.data['country_code']

        })
        .catch(error => {
          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
    },
    updateSelection(option) {
      this.package_type = option
    },
    testalert() {
      if (this.express_choice) {
        if (this.package_type) {
          if (this.package_type == 'Custom') {
            if (this.new_country && this.s_postal_code && this.r_postal_code && this.custom_weight && this.custom_width && this.custom_height && this.custom_length) {
              this.getrates()
            }
          } else {
            if (this.new_country && this.s_postal_code && this.r_postal_code) {
              this.getrates()
            }
          }
        }
      } else {
        if (this.package_type) {
          if (this.package_type == 'Custom') {
            if (this.new_country && this.custom_weight && this.custom_width && this.custom_height && this.custom_length) {
              this.getrates()
            }
          } else {
            if (this.new_country) {
              this.getrates()
            }
          }
        }
      }
    },
    testalert2() {
      event.preventDefault();
      if (this.express_choice) {
        if (this.package_type) {
          if (this.package_type == 'Custom') {
            if (this.new_country && this.s_postal_code && this.r_postal_code && this.custom_weight && this.custom_width && this.custom_height && this.custom_length) {
              this.getrates()
            }
          } else {
            if (this.new_country && this.s_postal_code && this.r_postal_code) {
              this.getrates()
            }
          }
        }
      } else {
        if (this.package_type) {
          if (this.package_type == 'Custom') {
            if (this.new_country && this.custom_weight && this.custom_width && this.custom_height && this.custom_length) {
              this.getrates()
            }
          } else {
            if (this.new_country) {
              this.getrates()
            }
          }
        }
      }
    },
    async getrates() {
      this.setPackage()
      await this.getPrices()
      if (this.express_choice) {
        await this.getPricesCIT()
      }
      await this.getDelivery_days()

      const element = this.$refs.scrollTarget;
      const element2 = this.$refs.scrollTarget2;

      const timingFunction = 'cubic-bezier(0.2, 0.8, 0.4, 1.0)';

      element2.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
        scrollBehavior: timingFunction
      });

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
        scrollBehavior: timingFunction
      });

    },
    async getPrices() {
      this.$store.commit('setIsLoading', true)

      await axios.get(`/api/v1/shipping/price/${this.origin_country}/${this.weight}/${this.new_country}`)
        .then(response => {

          this.price_2d = response.data['price_2d']
          this.price_5d = response.data['price_5d']
          this.VAT = response.data['VAT']


          this.price = response.data['price_2d']


        })
        .catch(error => {

          this.price_2d = ""
          this.price_5d = ""
          this.VAT = ""
          this.price = ""

          toast({
            message: this.translate('alert26'),
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: 'top-right',
          })


          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
    },
    async getPricesCIT() {
      this.$store.commit('setIsLoading', true)

      await axios.get(`/api/v1/shipping/price-cit/${this.weight}/${this.new_country}/${this.s_postal_code}/${this.r_postal_code}/`)
        .then(response => {

          this.price_2d_cit = response.data['price_2d']
          this.price_5d_cit = response.data['price_5d']

          this.price_cit = response.data['price_2d']


        })
        .catch(error => {

          this.price_2d_cit = ""
          this.price_5d_cit = ""
          this.price_cit = ""

          toast({
            message: this.translate('verify_postalcode'),
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: 'top-right',
          })


          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
    },
    async getDelivery_days() {
      this.$store.commit('setIsLoading', true)

      await axios.get(`/api/v1/get-delivery-days/${this.origin_country}/${this.new_country}`)
        .then(response => {
          this.delivery_days = response.data['Days']
        })
        .catch(error => {
          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
    },
    async setOldPrice() {
      console.log('setOldPrice')
      const old_price2 = this.price
      this.$store.commit('setOldPrice', old_price2)
    },
    async setOldPrice2() {
      console.log('setOldPrice')
      const old_price2 = this.price_cit
      this.$store.commit('setOldPrice', old_price2)
    },
    setVAT() {
      console.log('setVAT')
      const VAT = this.VAT
      this.$store.commit('setVAT', VAT)
    },
    async setPrice() {
      this.setVAT()
      this.setOldPrice()
      console.log('setPrice')
      const price = this.price
      this.$store.commit('setPrice', price)
      this.$router.push('/sender_information')
    },
    async setPrice2() {
      this.setVAT()
      this.setOldPrice2()
      console.log('setPrice')
      const price = this.price_cit
      this.$store.commit('setPrice', price)
      this.$router.push('/sender_information')
    },
    setPackage() {
      this.weight = 0

      if (this.package_type == "Envelope") {
        this.weight = 0.5
      }

      if (this.package_type == "Extra small") {
        this.weight = 1.0
      }

      if (this.package_type == "Small") {
        this.weight = 2.0
      }

      if (this.package_type == "Medium") {
        this.weight = 5.0
      }

      if (this.package_type == "Large") {
        this.weight = 10.0
      }
      
      if (this.package_type == "Large Extra") {
        this.weight = 15
      }

      if (this.package_type == "Extra large") {
        this.weight = 20.0
      }

      if (this.package_type == "Extra Extra large") {
        this.weight = 25.0
      }

      if (this.package_type == "Custom") {

        if ((parseFloat(this.custom_height * this.custom_width * this.custom_length) / 5000) > this.custom_weight) {
          this.weight = parseFloat((parseFloat(this.custom_height) * parseFloat(this.custom_length) * parseFloat(this.custom_width)) / 5000).toFixed(2)

        } else {
          this.weight = parseFloat(this.custom_weight).toFixed(2)
        }

        if (this.weight <= 0.5) {
          this.weight = 0.5
        } else {
          if (this.weight <= 1) {
            this.weight = 1.0
          } else {
            if (this.weight <= 2) {
              this.weight = 2.0

            } else {
              if (this.weight <= 5) {
                this.weight = 5.0

              } else {
                if (this.weight <= 10) {
                  this.weight = 10.0

                } else {
                  if (this.weight <= 20) {
                    this.weight = 20.0

                  } else {
                    if (this.weight <= 25) {
                      this.weight = 25.0

                    }
                  }
                }
              }
            }
          }
        }

      }

    },
    setCountry() {
      this.$store.commit('setCountry', {
        origin_country: this.origin_country,
        new_country: this.new_country
      })

    },
    async setfields() {
      this.setCountry()

      const weight = this.weight

      let calculatedPackageType

      if (this.package_type == 'Custom') {
        calculatedPackageType = this.package_type = "Custom, " + this.custom_width + " x " + this.custom_height + " x " + this.custom_length
      } else {
        calculatedPackageType = this.package_type
      }

      const package_type2 = calculatedPackageType

      this.$store.commit('setPackage', {
        weight: weight,
        package_type: package_type2
      })

      this.setExpress(false)
      this.setPrice()

      this.$router.push('/sender_information')
    },
    async setfields2() {
      this.setCountry()

      const weight = this.weight

      let calculatedPackageType

      if (this.package_type == 'Custom') {
        calculatedPackageType = this.package_type = "Custom, " + this.custom_width + " x " + this.custom_height + " x " + this.custom_length
      } else {
        calculatedPackageType = this.package_type
      }

      const package_type2 = calculatedPackageType

      this.$store.commit('setPackage', {
        weight: weight,
        package_type: package_type2
      })

      this.setExpress(true)
      this.setPrice2()

      this.$router.push('/sender_information')
    },
    checkFields() {

      let message = ''

      if (!this.new_country) {
        message = this.translate('alert1');
      }

      if (this.express_choice && !this.s_postal_code) {
        message = this.translate('alert12');
      }

      if (this.express_choice && !this.r_postal_code) {
        message = this.translate('alert12');
      }

      if (message) {
        toast({
          message: message,
          type: 'is-warning',
          dismissible: true,
          pauseOnHover: true,
          duration: 4000,
          position: 'top-right',
        })
      }

    },

    validateCustomWeight() {
      if (this.custom_weight < 0) {
        this.custom_weight = 1;
      }
    },
    validateCustomWidth() {
      if (this.custom_width < 0) {
        this.custom_width = 1;
      }
    },
    validateCustomHeight() {
      if (this.custom_height < 0) {
        this.custom_height = 1;
      }
    },
    validateCustomLength() {
      if (this.custom_length < 0) {
        this.custom_length = 1;
      }
    },
    initializeSwiper() {
      const swiper = new Swiper('.swiper', {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    },
    async getreviews() {
      this.$store.commit('setIsLoading', true)

      await axios.get('/api/v1/get-reviews/')
        .then(response => {
          this.slides = response.data


        })
        .catch(error => {
          console.log(error)
        })


      this.$store.commit('setIsLoading', false)
    },
    async setExpress(cas) {
      console.log('setExpress')

      const express = cas
      const s_postal_code = this.s_postal_code
      const r_postal_code = this.r_postal_code

      this.$store.commit('setExpress', {
        express: express,
        s_postal_code: s_postal_code,
        r_postal_code: r_postal_code
      })
    },
    chooseExpressService() {
      this.express_service = true
    },
    chooseNormalService() {
      this.normal_service = true
    }
  }
}
</script>
    
    


<style>
.fill-out {
  background-color: #FEEEF6;
}

.not-fill-out {
  background-color: #F5F5F5;
}

.btn-next-active {
  width: 100%;
  margin-top: 20px;
  background-color: #E5097F;
  color: white;
  font-weight: 600;
  padding: 2px;
  border-radius: 30px;
}

.btn-next-active:hover {
  background-color: #9b0656;
  color: white;
}

.btn-table-active {
  margin-top: 0px;
  background-color: #E5097F;
  color: white;
  font-weight: 600;
  border-radius: 10px;
}

.btn-table-active:hover {
  background-color: #9b0656;
  color: white;
}

.btn-next-deactive {
  width: 100%;
  margin-top: 20px;
  background-color: gray;
  color: white;
  font-weight: 600;
  padding: 2px;
  border-radius: 30px;
}

.btn-next-deactive:hover {
  background-color: gray;
  color: white;
}

.btn-table-deactive {

  margin-top: 0px;
  background-color: gray;
  color: white;
  font-weight: 600;
  border-radius: 10px;
}

.btn-table-deactive:hover {
  background-color: gray;
  color: white;
}

.padding0 {
  padding-right: 0;
  padding-left: 0;
}

.social-logo {
  margin-right: 8px;
}

.item-content {
  display: flex;
}

.header-img {
  display: block;
  width: 100%;
  height: auto;
}

.background-image1-laptop {
  object-fit: cover;
  height: 100%;
  background-image: url('../assets/img/background_welcome.png');
  background-size: cover;
  background-position: center;
}

.background-image1-mobile {
  height: 100%;
  background-image: url('../assets/img/small_background_welcome.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.background-image1-ipad {
  height: 100%;
  background-image: url('../assets/img/ipad_background_welcome.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}


.background-image1-mobile {
  display: none;
}

.background-image1-ipad {
  display: none;
}

.mobile-prices {
  display: none;
}

@media (max-width: 767px) {
  .background-image1-laptop {
    display: none;
  }

  .background-image1-mobile {
    display: block;
  }

  .background-image1-ipad {
    display: none;
  }

  .laptop-prices {
    display: none;
  }

  .mobile-prices {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .background-image1-laptop {
    display: none;
  }

  .background-image1-mobile {
    display: none;
  }

  .background-image1-ipad {
    display: block;
  }
}


@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');




.box-review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.boarddd {
  max-width: 987px;
  width: 100%;
  background-color: #FCF5D5;
  text-align: center;
  border-radius: 20px;
  padding-bottom: 50px;
  padding-top: 30px;
}

.text-lightv2 {
  color: var(--color-light);
}

.swiper {
  width: 600px;
  height: auto;
}

.swiper .swiper-slide .flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.swiper .swiper-slide .comments {
  color: black;
  width: 100%;
  border-radius: 5px;
  font-size: .9em;
  position: relative;
}

.swiper .swiper-slide .comments::after {
  content: '';
  position: absolute;
  bottom: -19px;
  left: 45%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid var(--color-light);
  z-index: -99;
}


.swiper .profile {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
}

.swiper .profile img {
  width: 30%;
  border-radius: 50%;
  border: 5px solid var(--color-primary);
  margin: 10px 0;
}

.swiper .profile>a {
  text-decoration: none;
  color: var(--color-light);
}

.swiper .profile>span {
  color: var(--color-text);
  font-size: .8em;
}

.swiper .swiper-button-prev,
.swiper-button-next {
  color: var(--color-text);
  display: none;
}

@media (min-width: 320px) {
  .boarddd {
    width: auto;
  }

  .swiper {
    width: 320px;
  }


}

@media (min-width: 1023px) {
  .boarddd {
    width: 100%;
  }

  .swiper {
    width: 600px;
  }

  .swiper .swiper-button-prev,
  .swiper-button-next {
    display: initial;
  }
}

@keyframes iconhighlith {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1.3);
  }
}

.attention-icon {
  animation: iconhighlith 1s infinite alternate;
}

.btn-sendparcel {
  background-color: #E5097F;
  color: white;
  font-weight: 600;
  border-radius: 20px;
  width: auto;
}

.btn-sendparcel:hover {
  background-color: #9b0656;
}


.btn-sendparcel-deactivate {
  background-color: #aaa;
  color: white;
  font-weight: 600;
  border-radius: 20px;
  cursor: not-allowed;
  width: auto;
}

.btn-sendparcel-deactivate:hover {
  background-color: #aaa;
  cursor: not-allowed;
}


.custom-price {
  background-color: #FEEEF6;
  text-align: center;
  border-radius: 25px;
  padding: 20px;
  cursor: pointer;
}

.custom-price:hover {
  border: 3px solid #7DB9FF;
}

.custom-price2 {
  background-color: #FEEEF6;
  text-align: center;
  border-radius: 25px;
  padding: 0px;
  cursor: pointer;
}

.custom-price2:hover {
  border: 3px solid #7DB9FF;
}
</style>
