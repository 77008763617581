export default {
    data() {
        return {
            fr: {
                shipping_route: "Itinéraire d'expédition",
                ship_from: "Expédier depuis",
                ship_to: "Expédier vers",
                postal_code: "Code postal",
                choose_country: "Choisissez un pays",
                city: "Ville",
                shipping_date: "Date d'expédition",
                choose_package: "Choisissez la taille de votre colis",
                belgium: "Belgique",
                other_size: "Autre taille",
                weight: "Poids (KG)",
                width: "Largeur (cm)",
                height: "Hauteur (cm)",
                length: "Longueur (cm)",
                rates_times: "Tarifs et horaires",
                delivery_date: "Date de livraison",
                package_type: "Type de colis",
                price: "Prix (EUR)",
                order: "Commande",


                alert1: 'Veuillez choisir le pays de livraison',
                alert2: 'Veuillez choisir le code postal de livraison',
                alert3: 'Veuillez choisir la ville de livraison',
                alert4: 'Veuillez choisir le code postal de pickup',
                alert5: 'Veuillez choisir la ville de pickup',
                alert6: "Veuillez choisir la date d'expédition",
                alert7: "n'a pas de système de code postal",
                alert8: 'Veuillez remplir le prénom',
                alert9: 'Veuillez remplir le nom de famille',
                alert10: "Veuillez remplir le numéro de l'adresse",
                alert11: 'Veuillez remplir la rue',
                alert12: 'Veuillez remplir le code postal',
                alert13: 'Veuillez remplir la ville',
                alert14: "Veuillez remplir l'État",
                alert15: "Veuillez remplir l'email",
                alert16: 'Veuillez entrer une adresse email valide',
                alert17: 'Veuillez remplir le numéro de téléphone',
                alert18: 'Veuillez remplir la description de votre colis',
                alert19: 'Veuillez choisir un point de service',
                alert20: "Veuillez confirmer l'emballage sécurisé avant de continuer",
                alert21: 'Veuillez remplir les champs nécessaires pour la douane !',
                alert22: 'Veuillez remplir les champs nécessaires !',
                alert23: 'Code promo valide, montant : ',
                alert24: "Le code promo n'est pas valide !",
                alert25: "Impossible d'obtenir des statistiques !!",
                alert26: "Les dimensions sont trop grandes!",
                alert27: 'Choisissez votre option de ramassage',

                summary: "Résumé",
                next: "Suivant",
                total_amount: "Montant total",
                including_vat: "TVA incluse",

                sender_information: "Informations de l'expéditeur",
                first_name: "Prénom",
                last_name: "Nom de famille",
                email: "Email",
                phone: "Téléphone",
                search_address: "Rechercher sur Google Maps",
                number: "Numéro",
                street: "Rue",
                state: "État",
                shipping_route_2: "Itinéraire d'expédition (À",
                no_insurance: "Pas d'assurance",
                assurance: "Assurance",

                receiver_information: "Informations du destinataire",

                //shipment Information
                shipment_info: "Informations sur l'expédition",
                address: "Adresse",
                search: "Trouver",
                enter_address: "Veuillez entrer votre adresse",
                choose_service_point: "Trouvez les points relais proches de chez vous:",
                placeholder_service_point: "Choisissez un point relais pour déposer votre colis",
                description: "Description",
                placeholder_description: "Écrivez une description de votre colis ici...",
                text: "Attention : En cochant ceci, je confirme avoir sécurisé l'emballage pour un transit sûr et je reconnais ma responsabilité.",
                assurance_text1: "Assurer mon colis.",
                assurance_text2: " (Veuillez noter que la valeur maximale assurable des envois est de 1000€)",
                assurance_text2_MA: " (Veuillez noter que la valeur maximale assurable des envois est de 10000 MAD)",
                commercial_text: "Avez-vous quelque chose à déclarer à la douane?",
                nature: "Nature",
                n_items: "N. articles",
                unit_price: "Prix unitaire (€)",
                hs_code: "HS code (facultatif)",
                remark: "Remarque",


                //validation
                validate_info: "Valider les informations",
                max_weight: "Poids maximal",
                service_point: "Point relais",
                pickup_address: "Adresse de ramassage",
                insure_my_parcel: "Assurance colis",
                commercial_value: "Valeur commerciale",
                shipment_price: "Prix de l'expédition",
                nature_package: "Nature du colis",
                edit: "Modifier",
                number_items: "Nombre d'articles",
                country: "Pays",
                vat: "TVA",
                proceed_payment: "Procéder au paiement",


                //payment
                payment_method: "Méthode de paiement",
                pay_credit_card: "Payer par carte de crédit",
                pay_bancontact: "Payer par Bancontact",
                pay_cash: "Payer en espèces",
                payment_success: "Paiement réussi",
                payment_success_cong: "Félicitations ! Votre paiement a été traité avec succès",
                payment_failed: "Paiement échoué",
                payment_failed_txt: "Oops ! Il y a eu un problème lors du traitement de votre paiement. Veuillez réessayer ultérieurement.",


                //payment credit card
                credit_card: "Paiement par carte de crédit",
                enter_promotion_code: "Entrer le code promotionnel",
                promo_code: "Code promo",
                enter_promotion_code_place: "Vous avez un code promo ? Entrez-le ici",
                validate_code: "Valider le code",
                card_inf: "Informations sur la carte",
                discount: "Remise",

                bancontact: "Paiement Bancontact",

                //Cash Payment
                cash: "Paiement en espèces",
                verification_code: "Code de vérification",
                verification_code_place: "Veuillez entrer le code de vérification",
                validate_payment: "Valider le paiement",
                verification_code_failed: "Le code de vérification n'est pas valide !",
                verification_code_txt: "Le code a été envoyé à l'email du point relais que vous avez choisi. Veuillez les contacter pour recevoir le code !",


                //Main nav
                home: "Accueil",
                new_shipment: "Nouvelle expédition",
                track_shipment: "Suivre l'expédition",
                login: "Connexion",
                sign_up: "S'inscrire",
                myprofile: "Mon profil",
                sign_out: "Déconnexion",
                english: "Anglais",
                french: "Français",
                dutch: "Néerlandais",

                //agent nav
                dashboard: "Tableau de bord",
                //part nav
                my_orders: "Mes commandes",



                //Dashboard
                hello: "Bonjour",
                welcome_back: "Bienvenue de retour !",
                tracking_number: "Numéro de suivi",
                order_date: "Date de commande",
                without_vat: "hors TVA",
                payment: "Paiement",
                commissions: "Commissions",
                status: "Statut",
                not_paid: "Non payé",
                paid: "Payé",
                collected: "Collecté",
                cashed: "Encaissé",
                credit_card_s: "Carte de crédit",
                cash_s: "Espèces",
                bancontact_s: "Bancontact",
                orders: "Commandes",
                my_company: "Ma société",
                support: "Support et assistance",
                company_name: "Nom de l'entreprise",
                vat_number: "Numéro de TVA",
                commission: "Commission",
                today_shipment: "Envois du jour",
                shipment_progress: "Envois en cours",
                shipment_deleivred: "Envois livrés",
                all_shipment: "Tous les envois",
                shipments: "Envois",
                orders_overview: "Aperçu des commandes :",
                profit_this_mounth: "Votre profit ce mois-ci",
                shipments_amount: "Argent encaissé",
                total: "Total",
                pay_bank: "Payer par virement bancaire",
                pay_cash: "Payer en espèces",
                see_more: "Voir plus",
                withdraw_money: "Retirer de l'argent",
                in_progress: "En cours",
                delivered: "Livré",
                my_commissions: "Mes commissions",
                bank_alert: "Paiement par virement bancaire",
                bank_alert_txt: "La demande a été envoyée avec succès à l'administration. Nous vous contacterons bientôt !",
                cash_alert: "Paiement en espèces",
                cash_alert_txt: "La demande a été envoyée avec succès à l'administration. Nous vous contacterons bientôt !",
                get_money: "Retirer de l'argent",
                get_money_txt: "La demande a été envoyée avec succès à l'administration. Nous vous contacterons bientôt !",


                //login page
                forgot_password: "Mot de passe oublié ?",
                dont_have_account: "Vous n'avez pas de compte ?",
                have_account: "Vous avez déjà un compte ?",
                reset_password: "Réinitialiser le mot de passe",
                login_error: "E-mail ou mot de passe incorrect",
                password: "Mot de passe",
                confirm_password: "Confirmez le mot de passe",

                no_orders: "Actuellement, il n'y a aucune commande sur votre compte",

                Yes: "Oui",
                No: "Non",

                //profile
                title: "Titre",
                last_login: "Dernière connexion",
                mr: "M",
                mrs: "Mme",
                ms: "Mlle",
                update: "Mise à jour",
                update_alert: "Mise à jour réussie !",
                error: "Quelque chose s'est mal passé. Veuillez réessayer.",
                update_title: "Mettre à jour les informations",
                update_txt: "Êtes-vous sûr de vouloir mettre à jour vos informations ?",
                update_now: "Mettez à jour",
                cancel: "Annuler",

                sender: "Expéditeur",
                shipping_label: "Bon de livraison",

                new_password: "Nouveau mot de passe",
                confirm_new_password: "Confirmer le nouveau mot de passe",

                reset_password_alert: "Mot de passe mis à jour!",

                send_email: "Envoyer un e-mail",
                send_email_alert: 'E-mail Réinitialiser le mot de passe envoyé!',

                track: 'Suivre',
                nom: "Nom",
                shipping_history: "Historique d'expédition",
                receiver: "Destinataire",
                date: 'Date',
                location: "Emplacement",
                track_title: 'Numéro de suivi incorrect',
                track_txt: 'Numéro de suivi invalide. Veuillez réessayer. Merci!',


                sender_information2: "Expéditeur",
                receiver_information2: "Récepteur",
                shipment_info2: "Expédition",


                register_mssg: "Compte créé, veuillez vous connecter !",

                //alerte assurance
                important_notice: "Avis Important",
                alert_assurance_message: "Si vous ne choisissez pas d'assurance, vous ne serez pas éligible à un remboursement en cas de perte ou de dommage.",
                add_assurance: "Ajouter une assurance",
                without_assurance: "Continuer sans assurance",



                //alerte envoi instance d'expédition
                alert_shipmentinstance: "Nous avons envoyé le lien de paiement au client",
                alert_shipmentinstance_message: "Veuillez vérifier l'e-mail suivant pour procéder au paiement : ",
                send_payment_link: "Envoyer le lien de paiement",

                create_new_meeting: "Créer une nouvelle réunion",
                join_your_meeting: "Rejoignez votre réunion",

                //success
                cong: "Félicitations !",
                package_safe_hands: "Votre colis est désormais entre de bonnes mains.",
                once_your_package: "Vous pouvez suivre votre colis en cliquant sur le bouton ci-dessous:",
                track_your_package: "Suivez votre colis avec deux options pratiques :",
                thr_whatsapp: "Via WhatsApp",
                thr_sms: "Via SMS",
                thr_website: "Sur notre site web",
                whts_desc: "Veuillez nous envoyer votre numéro de suivi par WhatsApp à :",
                provide_infor: "Nous vous fournirons des mises à jour en temps réel sur le statut de votre colis.",
                sms_desc: "Veuillez nous envoyer votre numéro de suivi par SMS à :",
                website_desc: "Pour suivre votre colis, veuillez saisir le numéro de suivi sur notre page de suivi.",
                your_satistfaction: "N'hésitez pas à nous contacter si vous avez besoin d'une assistance supplémentaire. Votre satisfaction est notre priorité !",

                business_day: "jour ouvrable",
                delivery_time: "Délai de livraison",

                your_address: "Votre adresse",
                service_point_address: "Adresse du point relais",
                open_google_maps: "Ouvrir dans Google Maps",

                welcome_message: "Le monde à votre porte en un clic!",
                sous_welcome_message: "Bienvenue dans la nouvelle ère de la livraison avec Eurêkali",

                change_myAddresse: "Pas de point relais proche? Modifiez l'adresse",

                //footer
                about_us: "À propos de nous",
                terms_of_use: "Conditions d'utilisation",
                privacy_policy: "Politique de confidentialité",
                accessibility_statement: "Déclaration d'accessibilité",
                all_rights_resereved: "Tous droits réservés",

                home_collection: "Collecte à domicile",
                servicepoint_collection: "Point relais Eurêkali",
                servicepoint_collection_text: "Déposez dans un point relais Eurekali",
                home_collection_text: "Récupérez le colis chez moi",
                drop_off_option: "Choisissez le lieu d'enlèvement :",

                your_feedback: "Veuillez écrire vos commentaires...",
                send: "Envoyer",
                give_feedback: "Veuillez nous donner un feedback",
                thanks_feedback: "Merci pour vos commentaires",
                how_was_your_experience: "Comment était votre expérience avec le nouveau système Eurêkali ?",

                call_us: "Appelez-nous",
                click_here: "Cliquez ici si vous avez besoin d'aide",

                // Page d'accueil de l'agent
                join_us: "Rejoignez-nous",
                become_agent: "Devenez un agent Eurekali aujourd'hui et découvrez comment votre partenariat peut propulser votre entreprise vers le succès !",
                why_agent: "Pourquoi nos agents sont-ils heureux d'être un Point Eurekali ?",
                parg1_title: "1 - Attirez plus de clients",
                parg1: "Devenez un point de service Eurekali et transformez votre magasin en un lieu d'activité pour ceux qui ont besoin d'envoyer. C'est une chance incroyable d'augmenter le trafic et de présenter de nouvelles personnes à votre entreprise.",
                parg2_title: "2 - Augmentez vos revenus",
                parg2: "En tant que partenaire Eurêkali, pour chaque colis que vous gérez, vous pourriez obtenir une commission de 10 % (de 1,8 € à 32 € par envoi). C'est un moyen simple et efficace d'ajouter une nouvelle source de revenus à votre entreprise.",
                parg3_title: "3 - Améliorez votre service client",
                parg3: "Offrir les services d'Eurêkali signifie faciliter la vie de vos clients. Qu'ils souhaitent envoyer ou recevoir des colis nationaux ou internationaux, ils pourront le faire sans quitter votre magasin. Un service client de qualité signifie souvent des clients fidèles !",
                parg4_title: "4 - Boostez votre réputation",
                parg4: "Un partenariat avec Eurekali, une marque respectée dans l'industrie de l'expédition, peut augmenter la crédibilité de votre magasin et instiller la confiance chez vos clients. Affichez fièrement ce partenariat et profitez des avantages qu'il peut apporter à votre image.",
                register: "Inscrivez-vous",

                //Inscription Agent
                personal_information: "Informations personnelles :",
                company_information: "Informations de l'entreprise :",
                activity: "Activité",
                store_name: "Nom du magasin",

                question_promo_code: "Avez-vous un code de promotion ?",
                sale_ticket: "Ticket de vente",

                shipments_to_validate: "Envois en attente",
                proforma_invoice: "Facture proforma",
                validate: "Valider",
                shipment_validated: "L'envoi a été validé",
                question_validation: "Etes-vous sûr de vouloir valider cet envoi ?",
                shipment_validation: "Validation de l'envoi",
                modify: "Modifier",

                paid_price: "Prix payé par le client",
                price_to_pay: "Prix à payer à l'agent",
                validate_payment: "Valider le paiement",
                package_description: "Description de colis",
                other_cash: "Supplément en espèces",
                commission_to_pay: "Commissions agent",
                no_codes: "Actuellement, il n'y a pas de demandes de codes de vérification pour les paiements en espèces",
                client: "Client",
                verification_code: "Code de vérification",
                amount: "Montant",
                request_date: "Date de demande",
                cancel: "Annuler",

                my_outstandings: "Mes encours :",
                my_outstandings_text1: "• Votre commission sera ajoutée automatiquement, même si le client commande directement sur notre site, paie par carte et dépose le colis dans votre magasin.",
                my_outstandings_text2: "• La facturation se fera automatiquement tous les 15 jours.",
                total_to_pay: "Total à payer",
                total_to_be_reimbursed: "Total à rembourser",
                invoice_request: "Demander une facture",
                request_refund: "Demander un remboursement",
                invoice_request_text: "Êtes-vous sûr de vouloir demander une facture ?",
                request_refund_text: "Êtes-vous sûr de vouloir demander un remboursement ?",
                invoicev2_created_success: "La facture a été générée avec succès",
                refund_created_success: "Le remboursement a été demandé",
                my_bills: "Mes factures :",
                invoice_number: "Numéro de référence",
                invoice_date: "Date de la facturation",
                amounts: "Montants",
                status: "Statut",
                invoice_in_pdf: "Imprimer",
                my_refunds: "Mes remboursements :",
                pay: "Payer",
                invoice_payment: "Paiement de la facture",
                invoice_payment_text: "Veuillez choisir votre mode de paiement",
                pay_with_card: "Payer par carte",
                pay_with_cash: "Payer en espèces",

                registration: "Inscription",
                registration_text: "Souhaitez-vous créer un nouveau compte en tant que ?",
                agent: "Agent",
                individual: "Particulier",

                details: "Détails",
                see_details: "Voir les détails",
                payment_type: "Type de paiement",

                printer_text: "Avez-vous une imprimante qui vous permet de créer l'étiquette d'expédition ?",
                printer: "Imprimante",
                read_conditions_alert: "Avant de continuer, vous devez confirmer votre acceptation des conditions générales d'utilisation.",
                safe_parcel_alert: "Veuillez confirmer que votre envoi ne contient aucun produit dangereux",
                safe_parcel_text: "Je déclare que mon envoi ne contient aucun produit dangereux :",
                safe_parcel_text_sntc1: "Ni aucun produit interdit par le transporteur ou par le pays de destination.",
                safe_parcel_text_sntc2: "Consultez la liste des produits interdits.",
                safe_parcel_text_sntc3: "Tout envoi non conforme peut engager la responsabilité et bloquer la livraison de votre colis.",
                safe_parcel_text_sntc4: "Votre envoi ne donnera lieu à aucune indemnisation.",
                read_conditions_text_sntc1: "J'accepte",
                read_conditions_text_sntc2: "les conditions générales d'utilisation du site eurekali.com",
                read_conditions_text_sntc3: "ainsi que",
                read_conditions_text_sntc4: "les conditions générales du transporteur.",

                packaging: "Emballage",
                packaging_text: "En cochant ceci, je confirme avoir sécurisé l'emballage pour un transport sûr et j'accepte ma responsabilité.",
                important_note: "Note importante : ",
                phonenumver_verify_text: "Veuillez vérifier votre numéro de téléphone, car c'est ainsi que vous recevrez les informations sur votre envoi.",
                sendsms: "Confirmer",
                sms_confirmation: "Envoyer la confirmation par SMS",
                sms_sent_text: "Vos informations d'expédition ont été transmises avec succès par SMS à votre numéro de téléphone.",
                dont_receive_sms: "Je n'ai pas reçu le SMS",

                pickup_location: "Lieu d'enlèvement",
                thank_you: "Merci!",
                we_will_call_you: "Nous vous appellerons dans quelques instants",
                call_me: "Appelez-moi",

                users_review_text1: "Les avis de nos clients sur Eurekali",
                users_review_text2: "Confiance et satisfaction !",

                customs_declaration: "Déclaration en douane :",

                // Page des produits
                products_page_title: "Produits interdits, réglementés et non recommandés pour les opérations d'importation et d'exportation.",
                products_paragraphe: "Pour simplifier votre envoi, nous avons répertorié des produits clés qui sont interdits ou soumis à réglementation (veuillez noter que cette liste n'est pas exhaustive). Avant chaque envoi, nous vous recommandons de vérifier les conditions générales du transporteur et de vous assurer que votre colis ne présente aucun risque.",
                products_text1: "Facilitez votre expédition:",
                products_text2: "Voici une réglementation essentielle à prendre en compte:",
                products_text3: "Aujourd'hui, l'interdiction ou la réglementation d'une marchandise dépend de plusieurs facteurs, notamment le mode de transport, les réglementations internationales, les spécificités du pays d'origine ou de destination, les règles du transporteur et la nature de la marchandise elle-même. L'objectif est de prévenir les risques potentiels pendant le transport, de protéger les personnes, les biens et l'environnement, et de garantir une norme internationale dans le transport de marchandises entre les pays.",
                products_text4: "Il est important de noter que l'envoi de marchandises interdites expose l'expéditeur à des poursuites pénales en cas de dommages matériels ou corporels lors du transport. De plus, le transporteur peut choisir de bloquer ou de détruire les marchandises, sans possibilité de réclamation ou de compensation.",
                products_text5: "Les marchandises interdites comprennent:",
                products_text6: "Produits explosifs, dangereux, toxiques, inflammables, corrosifs ou radioactifs tels que les désinfectants pour les mains, les produits désinfectants, les carburants, les produits chimiques, les armes, les narcotiques, etc.",
                products_text7: "Colis mouillés qui fuient ou dégagent des odeurs.",
                products_text8: "Produits sous pression, y compris les aérosols, les bouteilles de gaz et les bouteilles d'oxygène.",
                products_text9: "Armes à feu, munitions et équipement militaire.",
                products_text10: "Batteries au lithium-ion et au lithium-métal, ainsi que les véhicules électriques.",
                products_text11: "Corps humains, organes, matériaux biologiques et déchets médicaux.",
                products_text12: "Médicaments, même avec ordonnance.",
                products_text13: "Produits liés à la faune et à la flore, plantes, animaux vivants ou morts, etc.",
                products_text14: "Argent et objets de valeur, tels que pièces de monnaie, bijoux, métaux précieux, etc.",
                products_text15: "Produits du tabac, y compris les cigarettes électroniques.",
                products_text16: "Produits alimentaires périssables et produits nécessitant un contrôle de la température.",
                products_text17: "Contrefaçons, produits illégaux ou immoraux.",
                products_text18: "Biberons en polycarbonate contenant du bisphénol A.",
                products_text19: "Envois de forme cylindrique ou conique, tels que tapis ou matelas.",
                products_text20: "Autres produits avec ce type de symbole:",
                products_text21: "Les produits non recommandés comprennent:",
                products_text22: "Articles fragiles ou ayant une forme d'emballage non standard.",
                products_text23: "Boissons alcoolisées (réservées aux clients professionnels avec un emballage spécifique approuvé).",
                products_text24: "Appareils électroménagers tels que fours, machines à laver, lave-vaisselle, etc.",
                products_text25: "Matériaux tels que la céramique, le marbre, le verre, etc.",
                products_text26: "Instruments de musique, équipements électroniques, objets décoratifs, accessoires de voiture, etc.",
                products_text27: "Il est essentiel de tenir compte de ces réglementations pour garantir un envoi sûr conforme aux lois et normes internationales.",

                //conditions de la page
                conditions_page_title: "Conditions d'utilisation du site web",
                conditions_definitions: "1. Définitions :",
                conditions_text1: "Dans le cadre de ces conditions d'utilisation, la terminologie suivante sera utilisée :",
                conditions_text2: '"Utilisateur" désigne toute personne physique ou morale souhaitant utiliser les services proposés par les transporteurs répertoriés sur le site web pour comparer, réserver, commander et/ou acheter ou demander à quelqu' + "'un de commander et/ou acheter un service proposé par les transporteurs répertoriés sur le site.",
                conditions_text3: '"Transport" désigne toute opération, à partir du moment où le transporteur prend en charge les marchandises jusqu' + "'à leur livraison à destination, en présence d'un représentant de l'entreprise acheteuse ou d'une autre personne. Il fait également référence à tout service ou option supplémentaire proposé par le transporteur, tel que l'assurance de colis ou le paiement contre remboursement.",
                conditions_text4: '"Transporteur" désigne tout opérateur de transport proposant les services fournis sur le site web. Cela inclut également les opérateurs postaux, les intégrateurs de fret internationaux et nationaux, les coursiers urbains et interurbains, les entreprises spécialisées dans la livraison express, les entreprises proposant des points de relais de livraison, et les entreprises locales ou régionales.',
                conditions_text5: '"Transporteur" fait également référence à la société tierce désignée par EUREKALI pour prendre en charge la collecte du colis, conformément à ces conditions d' + "'utilisation et aux conditions générales du transporteur sélectionné.",
                conditions_text6: "2. Objectif et Application des Conditions :",
                conditions_text7: "Sauf indication contraire par écrit, ces conditions générales d'utilisation (ci-après dénommées " + '"les Conditions")' + " s'appliquent à tous les utilisateurs du site www.eurekali.be (ci-après dénommé " + '"le Site").',
                conditions_text8: "Les services d'achat ne sont accessibles qu'aux utilisateurs ayant lu et accepté intégralement les Conditions, le document intitulé " + '"Mon guide' + " d'expédition" + '" et les conditions spécifiques des transporteurs pour chaque service, avant de passer toute commande.',
                conditions_text9: "EUREKALI se réserve le droit de modifier les Conditions à tout moment et sans préavis. Il est entendu que de telles modifications ne s'appliqueront pas aux commandes précédemment acceptées.",
                conditions_text10: 'Le Site est un outil permettant aux utilisateurs de comparer les tarifs de transport proposés par les transporteurs à des tarifs préférentiels.',
                conditions_text11: "L'utilisateur est libre de choisir le transporteur avec lequel il souhaite travailler.",
                conditions_text12: 'Le Site est publié par la société à responsabilité limitée EUREKALI, dont le siège social est situé au 16b Rue des Trois Arbres, 1180 Uccle (Belgique), et immatriculée au Registre belge des entités légales (BCE) sous le numéro 0537.251.722. Numéro de téléphone : +32 2 333 37 77, email : info@eurekali.com.',
                conditions_text13: '3. Informations Préliminaires et Clause de non-responsabilité :',
                conditions_text14: "Pour accéder au site, l'utilisateur doit disposer d'une connexion Internet et de l'équipement nécessaire. Les frais de connexion sont à la charge de l'utilisateur.",
                conditions_text15: "EUREKALI ne garantit pas la continuité de l'accès au Site ni à l'un des services des transporteurs (sauf pour les commandes en cours) et ne peut être tenu responsable de l'indisponibilité du Site ou des services du transporteur. À tout moment, EUREKALI peut décider de mettre à jour le Site pour améliorer son fonctionnement, de modifier tout ou partie du Site et de son contenu pour le rendre plus réactif aux demandes des utilisateurs, de corriger les éventuelles erreurs présentes sur le Site, ou de réaliser toute opération de maintenance sur les serveurs d'EUREKALI susceptible de perturber l'accès au Site ou à un service de transport.",
                conditions_text16: "EUREKALI ne peut être tenu responsable de l'incapacité de l'utilisateur à accéder au Site en raison des opérations susmentionnées ou pour toute autre raison indépendante de son contrôle. EUREKALI ne peut être tenu responsable de l'interruption de la connexion de l'utilisateur à tout moment ou de tout dysfonctionnement indépendant de son contrôle pouvant empêcher l'utilisateur d'effectuer des opérations.",
                conditions_text17: "Dans le cas où le dysfonctionnement serait causé par le fonctionnement du Site, l'utilisateur reconnaîtra que, techniquement, le bon fonctionnement du Site peut dépendre de facteurs qui ne sont pas entièrement sous le contrôle d'EUREKALI.",
                conditions_text18: "EUREKALI ne garantit pas que le site est exempt de virus informatiques ou d'autres anomalies indépendantes de son contrôle. Néanmoins, EUREKALI prendra toutes les mesures possibles pour assurer aux utilisateurs une utilisation sécurisée du Site.",
                conditions_text19: "Par conséquent, EUREKALI ne peut être tenu responsable des dommages ou pertes dus à des erreurs système ou des interruptions affectant le bon fonctionnement du Site et l'affichage en ligne des services du transporteur.",
                conditions_text20: "De plus, en cas d'utilisation illégale ou contraire aux Conditions, EUREKALI se réserve le droit de refuser l'accès au Site à tout moment et sans préavis.",
                conditions_text21: "4. Utilisation du Site :",
                conditions_text22: "EUREKALI propose exclusivement, via son Site, un service de comparaison automatisé des tarifs de transport détaillés de marchandises (à l'exclusion des services de déménagement) fournis par les transporteurs qu'elle sélectionne, de manière non exclusive et non exhaustive.",
                conditions_text23: "Les étapes complètes de la commande de service sont expliquées dans le document" + ' "Mon guide d' + "'expédition" + ", que l'utilisateur reconnaît après l'avoir lu et accepté.",
                conditions_text24: "Le contrat de transport est conclu directement entre l'utilisateur et le transporteur. EUREKALI facture à l'utilisateur l'ensemble des services.",
                conditions_text25: "EUREKALI est autorisée par la société CIT Express (dont le siège social est situé au 16b Rue des Trois Arbres, 1180 Uccle, Belgique, et immatriculée au BCE sous le numéro 0537.251.722) à, au nom et pour le compte de CIT Express, conclure avec l'utilisateur, en vertu des Conditions, le mandat gratuit par lequel CIT Express conclura à son tour chaque contrat de transport entre le transporteur choisi par l'utilisateur et l'utilisateur, au nom et pour le compte duquel CIT Express agira (en vertu du mandat gratuit donné par l'utilisateur, lui-même conclu par EUREKALI en tant qu'agent autorisé de CIT Express, conformément à ce qui précède).",
                conditions_text26: "EUREKALI est également autorisée par CIT Express à veiller à ce que les factures à l'utilisateur soient émises au prix choisi par l'utilisateur et à collecter les montants dus. Sous réserve du paiement intégral des montants dus par l'utilisateur, EUREKALI garantit le paiement au transporteur par l'utilisateur.",
                conditions_text27: "EUREKALI attire l'attention de l'utilisateur sur les conditions générales du transporteur sélectionné qui s'appliquent à sa commande. Les conditions des transporteurs peuvent être consultées (ici). L'utilisateur accepte de se conformer à ces conditions, en particulier d'accepter les limitations de responsabilité concernant les services de transport, EUREKALI n'ayant aucune responsabilité à cet égard.",
                conditions_text28: "L'utilisateur renonce expressément à toute action légale ou extrajudiciaire, réclamation, en garantie ou en contre-action, directe ou indirecte, contre EUREKALI en relation avec les services des transporteurs.",
                conditions_text29: "L'utilisateur reconnaît que la collecte de l'expédition peut être effectuée par une société tierce pour des raisons d'efficacité, sans qu'EUREKALI n'accepte de responsabilités autres que celles décrites dans les Conditions ou les conditions générales du transporteur sélectionné.",
                conditions_text30: "L'utilisateur renonce au droit de se prévaloir de tout document pouvant être supplémentaire ou contraire aux Conditions ou aux conditions générales des transporteurs.",
                conditions_text31: "EUREKALI ne peut garantir à l'utilisateur l'existence, l'exactitude ou la disponibilité de toute offre de transport affichée sur le site. Les offres seront fournies à EUREKALI sous la seule responsabilité des transporteurs et sous réserve de l'acceptation des commandes par le transporteur.",
                conditions_text32: "5. Compte Client :",
                conditions_text33: "L'utilisateur peut créer un " + '"compte client"' + ", bien que ce ne soit pas obligatoire pour utiliser le Site.",
                conditions_text34: "Pour créer un compte client, l'utilisateur doit fournir une adresse e-mail valide et un mot de passe. L'utilisateur est responsable de la confidentialité de son nom d'utilisateur et de son mot de passe, ainsi que des actions qui peuvent être effectuées depuis son compte en utilisant son nom d'utilisateur et/ou son mot de passe.",
                conditions_text35: "L'utilisateur accepte expressément d'informer immédiatement EUREKALI de toute utilisation non autorisée de son compte et/ou de son mot de passe et/ou de tout problème pouvant perturber leur sécurité. EUREKALI ne peut, en aucun cas, être tenu responsable de toute perte ou dommage résultant de la violation des obligations de cet paragraphe.",
                conditions_text36: "6. Modification et Annulation de la Commande par l'Utilisateur :",
                conditions_text37: "Aucune annulation ou modification ne sera possible après la confirmation de la commande sur le site. L'utilisateur reconnaît que le délai de rétractation de 14 jours prévu par la loi belge du 6 avril 2010 sur les pratiques du marché et la protection du consommateur ne s'applique pas aux commandes couvertes par ces Conditions.",
                conditions_text38: "7. Tarifs et Prix :",
                conditions_text39: "Les tarifs proposés par les transporteurs sont indiqués en euros, toutes taxes comprises, et peuvent être modifiés à tout moment et sans préavis. Seul le prix confirmé au moment de la commande sera valide.",
                conditions_text40: "Cependant, les prix des services de transport proposés via le Site ne sont pas sujets à modification après la confirmation de la commande.",
                conditions_text41: "En général, sauf indication contraire, tout autre service non expressément mentionné dans la confirmation de commande n'est pas inclus dans le prix. De même, les frais et taxes applicables de toute nature seront à la charge de l'utilisateur ou du contact de destination, conformément à la réglementation en vigueur.",
                conditions_text42: "Il incombe donc à l'utilisateur de se renseigner sur les droits et taxes, y compris les tarifs douaniers, qui peuvent s'appliquer à l'expédition, car aucune information de ce type ne peut être fournie à l'utilisateur dans le cadre de la commande.",
                conditions_text43: "Dans le cas où un paiement supplémentaire serait appliqué pour des coûts supplémentaires pendant le processus de commande, répertoriés dans la section " + '"frais supplémentaires" de "Mon guide d' + "'expédition" + '", l' + "'utilisateur sera informé directement par e-mail. La commande sera mise en attente jusqu'à ce que le paiement du supplément de prix soit effectué.",
                conditions_text44: "Dans le cas où le poids du colis serait sensiblement supérieur à celui communiqué lors de la commande, des pénalités seront calculées selon le tableau ci-dessous. Si le poids et le volume sont supérieurs à ceux communiqués, la pénalité la plus lourde des deux doit être appliquée.",
                conditions_text45: "Téléchargez l'ensemble des conditions d'utilisation et de service du site au format PDF.",

                send_my_parcel: "Envoyer mon colis",
                dimensions_alert: "Veuillez entrer les dimensions de votre colis",
                verify_postalcode: "Veuillez vous assurer que les codes postaux sont corrects",
                same_day_delivery: "Livraison le jour même",
                same_day: "Le jour même",
                choose_this_offer: "Choisir cette offre",
                ultra_fast: "Ultra rapide",
                ultra_fast_text: "Avec l'option Ultra Rapide, pickup et livraison le jour même",

                my_space: "Mon espace",

                delivery_estimate: "Estimation de livraison",
                pickup_time_phrase: "Mon horaire de ramassage préféré est entre:",
                delivery_date_pickup_time: "Date de livraison et heure de ramassage",
                from_pickuptime: "De",
                to_pickuptime: "À",
                validate_order: "Valider la commande",
                ship_date_alert: "Veuillez remplir la date d'expédition",
                pickup_time_alert: "Veuillez remplir l'heure de ramassage",
                shipment_modified: "L'expédition a été modifiée",

                confirm: "Confirmer",
                bravo: "BRAVO!",
                bravo_text1: "Votre colis est prêt à être envoyé.",
                bravo_text2: "Il ne reste plus qu'à confirmer votre numéro de téléphone pour recevoir l'adresse du point de dépôt le plus proche de chez vous.",
                printing_documents: "Impression de documents",
                print_shippinglabel: "Imprimer l'étiquette d'expédition",
                print: "Imprimer",
                print_saleticket: "Imprimer le ticket de vente",
                print_proformainvoice: "Imprimer la facture proforma",
                pack_itmes: "Emballer les articles en toute sécurité dans l'emballage de transporteur fourni.",
                affix_itmes1: "Fixez l'étiquette d'expédition et la facture proforma solidement sur le colis",
                affix_itmes2: "Fixez l'étiquette d'expédition solidement sur le colis",
                complete: "Terminé",

                home_success_text1: "Félicitations!",
                home_success_text2: "Votre colis est prêt à être expédié.",
                home_success_text3: "Merci de prendre en compte les points suivants pour finaliser le processus d'envoi:",
                step1_text: "Veuillez confirmer votre numéro de téléphone pour recevoir l'adresse du point de dépôt.",
                step2_text: "Veuillez vérifier que votre colis est bien emballé et protégé pour assurer sa sécurité lors de la livraison.",
                step3_text: "Veuillez déposer votre colis dans le point de dépôt que vous avez préalablement choisi.",
                step4_text: "Dans quelques instants, une fois que vous aurez reçu le bon d'envoi par e-mail, veuillez l'imprimer et le coller sur votre colis.",

                contact_us_problem: "Si vous rencontrez le moindre problème, veuillez nous contacter au numéro suivant:",
                contact_us_problem_phone: "0032 2 333 37 77",

                le_volume: "Le volume",
                see_the_list: "Voir la liste des marchandises interdites:",
                call_us_questions: "N'hésitez pas à nous appeler si vous avez des questions:",
                modal_title: "Contrôle et Gestion d'Expédition",
                modal_title_desc: "Cette page vous donne le contrôle total sur l'envoi. Vous pouvez la valider, la modifier ou la rejeter en fonction des détails fournis ou de la nature de l'envoi.",

                inactive_account: "Votre compte est inactif. Veuillez régler votre situation",

                meetings_error: "Une salle de réunion portant ce nom existe déjà",
                delete_room: "Delete existing room",

                bonus_title: "Bienvenue chez Eurêkali!",
                bonus_subtitle: "À chaque envoi, gagnez des points fidélité pour profiter d'avantages exceptionnels.",
                my_points: "Mes points",
                remarks_bonus: "Remarques",
                new_client_bonus: "Parrainage nouveau agent =",
                new_client_points_bonus: "15 points",
                edit_shipment_bonus: "Modification d'envoi =",
                edit_shipment_points_bonus: "5 points",
                new_shipment_bonus: "Nouveau envoi =",
                new_shipment_points_bonus: "5 points",
                fivepoints_bonus: "5 points =",
                fivepoints_points_bonus: "1 €",
                bonus_title2: "Dépenser mes points de fidélité",
                bonus_subtitle2: "Échangez vos points fidélité contre un bon d'achat",
                order_bonus: "Commandez",
                bonus: "Bonus",
                bonus_button: "Remboursement Cadeaux",
                bonus_alert: "Nous avons bien reçu votre demande, Merci",

                min_points: "Vous avez besoin d'un minimum de 250 points pour commander un cadeau.",

                parrainage_title: "Bonus Boost!",
                parrainage_stitle: "Chaque nouvelle assistance que vous recommandez et qui persuade un nouvel agent de rejoindre notre système vous rapportera 100 points de bonus !",
                parrainage_stitle2: "Il vous suffit de remplir le formulaire ci-dessous pour nous permettre de contacter l'agent intéressé et de faciliter son inscription.",
                parrainage_your_info: "Votre Informations :",
                parrainage_your_name: "Votre nom complet",
                parrainage_your_email: "Votre email",
                parrainage_agent_info: "Informations de nouveau agent :",
                parrainage_agent_name: "Nom complet",
                parrainage_agent_address: "Adresse",
                parrainage_agent_email: "Adresse email",
                parrainage_send: "Envoyer",

                parrainage_not_txt1: "Merci pour votre aide !",
                parrainage_not_txt2: "Lorsque nous contacterons le nouvel agent et procéderons à son inscription, vos bonus seront automatiquement ajoutés à votre compte",

                morocco: "Maroc",

                location_choose_title: "Choisissez Votre Emplacement",
                location_choose_desc: "Veuillez choisir votre emplacement actuel",

                coworking_signup_title1: "Bienvenue sur Eurêkali Point",
                coworking_signup_title2: "Transformez votre espace de coworking",
                coworking_signup_title3: "En intégrant dès maintenant notre réseau de points relais!",

                contact: "Contact"


            }
        }
    }
}
