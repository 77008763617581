<template>
    <div ref="scrollTarget"></div>

    <div class="laptop-agent">
        <header class="header-container-laptop position-relative">
            <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                <div class="carousel-inner">
                    <div class="carousel-item active" data-bs-interval="3000">
                        <img src="../assets/img/agent-home.png" class="d-block w-100 header-image-laptop" alt="Image 3">
                    </div>
                </div>
            </div>
            <div class="position-absolute top-50 start-50 ">

                <div class="row gx-5">
                    <div class="col-md-12">
                        <span style="color: white; font-size: x-large;">{{ translate('become_agent') }}</span>
                        <br>
                        <router-link to="/signup-agent" class="btn btn-primary join-us-btn"> {{ translate('join_us')
                        }}</router-link>
                    </div>
                </div>
            </div>
        </header>
    </div>

    <div class="ipad-agent">
        <header class="header-container-laptop position-relative">
            <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                <div class="carousel-inner">
                    <div class="carousel-item active" data-bs-interval="3000">
                        <img src="../assets/img/agent-home-ipad.png" class="d-block w-100 header-image-laptop"
                            alt="Image 3">
                    </div>
                </div>
            </div>
            <div class="position-absolute top-50 start-50 ">

                <div class="row gx-5">
                    <div class="col-md-12">
                        <span style="color: white; font-size: x-large;">{{ translate('become_agent') }}</span>
                        <br>
                        <router-link to="/signup-agent" class="btn btn-primary join-us-btn"> {{ translate('join_us')
                        }}</router-link>
                    </div>
                </div>
            </div>
        </header>
    </div>

    <div class="mobile-agent">
        <header class="header-container-laptop position-relative">
            <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                <div class="carousel-inner">
                    <div class="carousel-item active" data-bs-interval="3000">
                        <img src="../assets/img/agent-home-mobile.png" class="d-block w-100 header-image-laptop"
                            alt="Image 3">
                    </div>
                </div>
            </div>
            <div class="position-absolute top-50 start-50 translate-middle">
                <div class="row" style="width: 100%;">
                    <div class="col-12" style="text-align: center;">
                        <span style="color: white; font-size: large; font-weight: bold; text-align: center;">{{
                            translate('become_agent') }}</span>
                        <br>
                        <router-link to="/signup-agent" class="btn btn-primary join-us-btn"> {{ translate('join_us')
                        }}</router-link>
                    </div>
                </div>
            </div>
        </header>
    </div>


    <section class="container mt-md-5" style="margin-top: 200px;">
        <div class="container d-flex justify-content-center align-items-center">
            <div class="row align-items-center">
                <div class="col-12 col-md-auto text-center text-md-left">
                    <img src="../assets/img/question-icon.svg" alt="Description Icon" class="img-fluid">
                </div>
                <div class="col-12 col-md" style="text-align: center;">
                    <p style="color: #E5097F; font-weight: 500; font-size: 22px;">{{ translate('why_agent') }}</p>
                </div>
            </div>
        </div>
    </section>



    <section class="container d-flex justify-content-center align-items-center"
        style="margin-top: 20px; margin-bottom: 35px;">
        <div class="row gx-5">
            <div class="col-md-6" style="margin-top: 10px;">
                <div class="service-card-laptop text-center card">
                    <h5 class="card-title">{{ translate('parg1_title') }}</h5>
                    <p class="card-text">
                        {{ translate('parg1') }}
                    </p>
                </div>
            </div>
            <div class="col-md-6" style="margin-top: 10px;">
                <div class="service-card-laptop text-center card">
                    <h5 class="card-title">{{ translate('parg2_title') }}</h5>
                    <p class="card-text">
                        {{ translate('parg2') }}
                    </p>
                </div>
            </div>
        </div>
    </section>

    <section class="container d-flex justify-content-center align-items-center"
        style="margin-top: 10px; margin-bottom: 35px;">
        <div class="row gx-5">
            <div class="col-md-6" style="margin-top: 10px;">
                <div class="service-card-laptop text-center card">
                    <h5 class="card-title">{{ translate('parg3_title') }}</h5>
                    <p class="card-text">
                        {{ translate('parg3') }}
                    </p>
                </div>
            </div>
            <div class="col-md-6" style="margin-top: 10px;">
                <div class="service-card-laptop text-center card">
                    <h5 class="card-title">{{ translate('parg4_title') }}</h5>
                    <p class="card-text">
                        {{ translate('parg4') }}
                    </p>
                </div>
            </div>
        </div>
    </section>

    <section class="container d-flex justify-content-center align-items-center"
        style="margin-top: 60px; margin-bottom: 75px;">
        <div class="row gx-5">
            <router-link to="/signup-agent" class="btn btn-primary register"> {{ translate('register') }} </router-link>
        </div>
    </section>
</template>



<script>
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';



export default {
    name: 'Agent',
    mixins: [en, fr, nl],
    data() {
        return {
            tracking_number: "",
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    async mounted() {
        document.title = 'Agent | Eurekali'
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0);
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
    }

}
</script>



<style scoped>
.header-container-laptop {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.header-image-laptop {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.service-card-laptop {
    border-radius: 15px;
    margin: 15px 0;
    padding: 15px;
    height: 100%;
    cursor: pointer;
    transition: transform 0.3s;
}

.service-card-laptop:hover {
    transform: scale(1.1);
}

.service-card-laptop h5 {
    color: #E5097F;
    font-weight: bold;
    margin-top: 10px;
}

.service-card-laptop p {
    margin-top: 5px;
    text-align: center;
    color: black;
}

.join-us-btn {
    margin-top: 15px;
    background-color: white;
    color: #E5097F;
    border-radius: 15px;
    font-weight: bold;
    border: 1px solid white;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.join-us-btn:hover {
    background-color: #E5097F;
    border: 1px solid white;
    color: white;
}

.register {
    background-color: #E5097F;
    border: 1px solid #E5097F;
    font-weight: 600;
    width: 150px;
    border-radius: 15px;
}

.register:hover {
    background-color: #9B0656;
    border: 1px solid #9B0656;
}

.mobile-agent {
    display: none;
}

.ipad-agent {
    display: none;
}

@media (max-width: 767px) {
    .mobile-agent {
        display: block;
    }

    .laptop-agent {
        display: none;
    }

    .ipad-agent {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .laptop-agent {
        display: none;
    }

    .mobile-agent {
        display: none;
    }

    .ipad-agent {
        display: block;
    }
}</style>