<template>
    <div ref="scrollTarget"></div>

    <div class="header-container-proudcts position-relative">
        <div class="background-image2-products-laptop img-fluid w-100"></div>
        <div class="background-image2-products-ipad img-fluid w-100"></div>
        <div class="background-image2-products-mobile img-fluid w-100"></div>

        <div class="phrase-laptop">
            <div class="position-absolute top-50 start-50 translate-middle text-white">

                <div class="phrase-laptop">


                    <div class="row align-items-center">
                        <div class="col-md-2" style="text-align: right;">
                            <img src="../assets/img/warning-icon.svg" class="img-fluid mx-auto d-block">
                        </div>
                        <div class="col-md-10" style="text-align: left;">
                            <p style="font-size: 30px; font-weight: 600; color: #E5097F;">
                                {{ translate('products_page_title') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="phrase-mobile">
            <div class="position-absolute top-50 start-50 translate-middle text-white w-100">

                <div class="phrase-mobile">
                    <p style="font-size: 20px; font-weight: 600; color: #E5097F; text-align: center; padding: 10px;">
                        <img src="../assets/img/warning-icon.svg" style="transform: scale(0.7);"> <br> {{
                            translate('products_page_title') }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container" style="margin-top: 50px; padding: 10px;">
        <div style="padding: 30px; color: #E5097F; background-color: #FEEEF6; border-radius: 20px;">
            {{ translate('products_paragraphe') }}
        </div>

        <div style="margin-top: 20px; text-align: justify;">
            <span style="color: #E5097F; font-weight: 600; font-size: 22px;">{{ translate('products_text1') }}</span>
            <br>
            <br>
            <span style="color: black; font-weight: 600;">{{ translate('products_text2') }}</span>
            <br>
            <br>
            <span style="color: black;">{{ translate('products_text3') }}</span>
            <br>
            <br>
            <span style="color: black;"><i style="margin-right: 5px; color: #FFB02E;"
                    class="fa fa-exclamation-triangle"></i>{{ translate('products_text4') }}</span>
        </div>

        <div style="margin-top: 50px; text-align: justify;">
            <span style="color: #E5097F; font-weight: 600; font-size: 22px;">{{ translate('products_text5') }}</span>
            <br>
            <br>
            <div style="padding: 15px;">
                <span style="color: black;">{{ translate('products_text6') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text7') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text8') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text9') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text10') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text11') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text12') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text13') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text14') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text15') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text16') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text17') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text18') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text19') }}</span>
            </div>
        </div>

        <div style="margin-top: 50px; text-align: justify;">
            <span style="color: #E5097F; font-weight: 600; font-size: 22px;">{{ translate('products_text20') }}</span>
            <br>
            <br>
            <div style="padding: 10px;">
                <img src="../assets/img/dangerous_products.svg">
            </div>
        </div>

        <div style="margin-top: 20px; margin-bottom: 80px; text-align: justify;">
            <span style="color: #E5097F; font-weight: 600; font-size: 22px;">{{ translate('products_text21') }}</span>
            <br>
            <br>
            <div style="padding: 15px;">
                <span style="color: black;">{{ translate('products_text22') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text23') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text24') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text25') }}</span>
                <br>
                <br>
                <span style="color: black;">{{ translate('products_text26') }}</span>
                <br>
                <br>
            </div>
            <span style="color: black; font-weight: 600;">{{ translate('products_text27') }}</span>
        </div>

    </div>
</template>


<script>
import axios from 'axios';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
    name: 'Success',
    mixins: [en, fr, nl],
    data() {
        return {
            tracking_number: "",
            slides: [],
            stars_number: 5,
            feedback: "",
            phonenumber: "",
            sent_code: false,
            callme_option: false
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    async mounted() {
        document.title = 'Products | Eurekali'
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0);


    },
    created() {
        this.tracking_number = this.$route.params.shipment_id
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },

    }

}
</script>

<style>
.h1-text {
    margin-top: 20px;
    color: black;
    font-weight: 600;
    font-size: 22px;
}

.h1-text-s {
    margin-top: 20px;
    font-size: 20px;
}

.h1-text-ss {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
}

.h1-text-sss {
    margin-top: 30px;
    margin-bottom: 30px;
    color: #E5097F;
    font-size: 18px;
    font-weight: bold;
}

.desc {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 400;
}

.btn-track {
    margin-top: 10px;
    background-color: #FFFFFF;
    color: #E5097F;
    border-color: #FFFFFF;
    font-weight: 600;
    border-radius: 20px;
}

.btn-track:hover {
    background-color: #E5097F;
    color: #FEEEF6;
    border-color: #E5097F;
    font-weight: 600;
}

.btn-track-2 {
    margin-top: 25px;
    background-color: #E5097F;
    color: white;
    border-color: #E5097F;
    font-weight: 600;
    width: 50%;
    border-radius: 20px;
}

.btn-track-2:hover {
    background-color: #FEEEF6;
    color: #E5097F;
    border-color: #E5097F;
    font-weight: 600;
}

.div-opt {
    border-radius: 15px;
    padding: 30px;
    margin: 0 15px;
    margin-bottom: 15px;
    height: 100%;
}

.header-container-proudcts {
    height: 300px;
}

.header-container img {
    object-fit: cover;
    height: 100%;
}

.background-image2-products-laptop {
    object-fit: cover;
    height: 100%;
    background-image: url('../assets/img/products-header.png');
    background-size: fill;
    background-repeat: no-repeat;
    background-position: center;
}

.background-image2-products-mobile {
    height: 100%;
    background-image: url('../assets/img/products-header-mobile.png');
    background-size: fill;
    background-repeat: no-repeat;
    background-position: center;
}

.background-image2-products-ipad {
    height: 100%;
    background-image: url('../assets/img/products-header.png');
    background-size: fill;
    background-repeat: no-repeat;
    background-position: right;
}


.background-image2-products-mobile {
    display: none;
}

.background-image2-products-ipad {
    display: none;
}

.phrase-mobile {
    display: none;
}

@media (max-width: 767px) {
    .background-image2-products-laptop {
        display: none;
    }

    .background-image2-products-mobile {
        display: block;
    }

    .background-image2-products-ipad {
        display: none;
    }

    .phrase-mobile {
        display: block;
    }

    .phrase-laptop {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .background-image2-products-laptop {
        display: none;
    }

    .background-image2-products-mobile {
        display: none;
    }

    .background-image2-products-ipad {
        display: block;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');




.box-review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.boarddd {
    max-width: 987px;
    width: 100%;
    background-color: #FCF5D5;
    text-align: center;
    border-radius: 20px;
    padding-bottom: 50px;
    padding-top: 30px;
}

.text-lightv2 {
    color: var(--color-light);
}

.btn-editphone {
    background-color: #FFFFFF;
    color: #E5097F;
    border: 1px solid #E5097F;
    border-radius: 20px;
}

.btn-editphone:hover {
    background-color: #9b0656;
    color: #FFFFFF;
}

.btn-sendcode {
    background-color: #E5097F;
    color: #FFFFFF;
    border-radius: 20px;
}

.btn-sendcode:hover {
    background-color: #9b0656;
    border: 1px solid #9b0656;
    color: #FFFFFF;
}
</style>
