<template>
    <div ref="scrollTarget"></div>
    <div class="container-fluid" style="height: 100%;">
        <div class="row">
            <!-- Left Side Menu -->
            <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block sidebar">
                <div class="position-sticky" style="margin-top: 50px;">
                    <ul class="nav flex-column" style="padding-left: 5%; margin-top: 10px;">
                        <router-link to="/dashboard" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/dash1.png" class="img-item"
                                    style="margin-right: 12px;"> {{
                                        translate('dashboard')
                                    }}</li>
                        </router-link>
                        <router-link to="/orders-validate" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/wait_icon.svg" class="img-item"> {{ translate('shipments_to_validate') }} <span v-if="pending_orders_notification>0">({{ pending_orders_notification }})</span>
                            </li>
                        </router-link>
                        <router-link to="/progress-orders" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/progress_icon.svg" class="img-item"> {{ translate('shipment_progress') }} <span v-if="progress_orders_notification>0">({{ progress_orders_notification }})</span>
                            </li>
                        </router-link>
                        <router-link to="/delivered-orders" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/Check.svg" class="img-item"> {{
                translate('shipment_deleivred') }}
              </li>
            </router-link>
                        <router-link to="/commissions" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/dash4.png" class="img-item"
                                    style="margin-right: 12px;"> {{
                                        translate('commissions') }}</li>
                        </router-link>
                        <router-link to="/new_order" style="color: #000;">
                            <li class="nav-item custom"><img src="../assets/img/dash5.png" class="img-item"
                                    style="margin-right: 12px;"> {{
                                        translate('new_shipment') }}</li>
                        </router-link>
                        <router-link v-if="company.bonus" to="/mybonus" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/bonus_icon.svg" class="img-item" style="margin-right: 12px;">
                {{translate('bonus') }} 
                <span style="margin-left: 5px; background-color: #E5097F; color: white; padding: 3px 8px 3px 8px; border-radius: 20px; font-weight: bold;">{{ company.bonus_points }} pts</span>
              </li>
            </router-link>
                    </ul>

                    <ul class="nav flex-column" style="margin-top: 400px; padding-left: 5%; margin-bottom: 10px;">
                        <router-link to="/company" style="color: #000;">
                            <li class="nav-item custom active-item"><img src="../assets/img/dash6.png" class="img-item"> {{
                                translate('my_company')
                            }}</li>
                        </router-link>
                        <li class="nav-item custom" @click="logout()"><img src="../assets/img/dash8.png" class="img-item">
                            {{
                                translate('sign_out') }}</li>
                    </ul>
                </div>
            </nav>

            <!-- Content Area -->
            <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4" style=" background-color: #F7F7F8;">

                <div class="row justify-content-between" style="padding-right: 50px; padding-left: 50px; margin-top: 30px;">
                    <div class="col-auto">
                        <p style="font-weight: bold; color: black;">{{ translate('hello') }} {{ user.first_name }} <br>
                            <span style="font-weight: 400;">
                                {{ translate('welcome_back') }}
                            </span>
                        </p>
                    </div>
                    <router-link to="/company" class="col-auto" style="cursor: pointer;">
                        <p style="color: #E5097F; font-weight: bold;"> <i class="fa fa-building-o" aria-hidden="true"
                                style="margin-right: 10px; font-size: 25px;"></i> {{ company.company_name }} </p>
                    </router-link>
                </div>

                <div class="row justify-content-center" style="margin-top: 40px;  margin-bottom: 100px;">
                    <div class="col-md-8 col-sm-12 mb-4">
                        <div class="border" style="border-radius: 30px; padding: 15px; background-color: white;">
                            <div class="text-center">
                                <img src="../assets/img/signup.png" alt="Signup Image">
                            </div>
                            <form @submit.prevent="submitForm" style="margin-top: 30px;">
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col">
                                            <label class="form-label custom-label">{{ translate('company_name') }}</label>
                                            <input type="text" class="form-control" placeholder="First Name" required
                                                v-model="company.company_name" readonly style="background-color: #FEEEF6;">
                                        </div>
                                        <div class="col">
                                            <label class="form-label custom-label">{{ translate('vat_number') }}</label>
                                            <input type="text" class="form-control" placeholder="Last Name" required
                                                v-model="company.vat_number" readonly style="background-color: #FEEEF6;">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col">
                                            <label class="form-label custom-label">{{ translate('commission') }}</label>
                                            <input type="text" class="form-control" placeholder="First Name" required
                                                v-model="company.commission" readonly style="background-color: #FEEEF6;">
                                        </div>
                                        <div class="col">
                                            <label class="form-label custom-label">{{ translate('cash') }}</label>
                                            <input type="text" class="form-control" placeholder="Last Name" required
                                                v-model="company.cash_payment" readonly style="background-color: #FEEEF6;">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col">
                                            <label class="form-label custom-label">{{ translate('email') }}</label>
                                            <input type="text" class="form-control" placeholder="First Name" required
                                                v-model="company.email" readonly style="background-color: #FEEEF6;">
                                        </div>
                                        <div class="col">
                                            <label class="form-label custom-label">{{ translate('phone') }}</label>
                                            <input type="text" class="form-control" placeholder="Last Name" required
                                                v-model="company.phone" readonly style="background-color: #FEEEF6;">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col">
                                            <label class="form-label custom-label">{{ translate('number') }}</label>
                                            <input type="text" class="form-control" placeholder="First Name" required
                                                v-model="company.number" readonly style="background-color: #FEEEF6;">
                                        </div>
                                        <div class="col">
                                            <label class="form-label custom-label">{{ translate('street') }}</label>
                                            <input type="text" class="form-control" placeholder="Last Name" required
                                                v-model="company.street" readonly style="background-color: #FEEEF6;">
                                        </div>
                                    </div>
                                </div>



                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col">
                                            <label class="form-label custom-label">{{ translate('postal_code') }}</label>
                                            <input type="text" class="form-control" placeholder="Postal Code"
                                                v-model="company.postal_code" readonly style="background-color: #FEEEF6;">
                                        </div>
                                        <div class="col">
                                            <label class="form-label custom-label">{{ translate('city') }}</label>
                                            <input type="text" class="form-control" placeholder="City"
                                                v-model="company.city" readonly style="background-color: #FEEEF6;">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col">
                                            <label class="form-label custom-label">{{ translate('state') }}</label>
                                            <input type="text" class="form-control" placeholder="State"
                                                v-model="company.state" readonly style="background-color: #FEEEF6;">
                                        </div>
                                        <div class="col">
                                            <label class="form-label custom-label">{{ translate('country') }}</label>
                                            <input type="text" class="form-control" placeholder="Country"
                                                v-model="company.country" readonly style="background-color: #FEEEF6;">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </main>
        </div>
    </div>
</template>
    
<script>
import axios from 'axios'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';

export default {
    name: 'Dashboard',
    mixins: [en, fr, nl],
    data() {
        return {
            user: {},
            today_shipments: "",
            progress_shipments: "",
            delivered_shipments: "",
            all_shipments: "",
            today_date: "",
            shipments_statistics: [],
            commissions_statistics: [],
            commission_amount: "",
            shipments: [],
            company: {},
            progress_orders_notification: 0,
            pending_orders_notification: 0
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    async created() {

        this.$store.commit('setIsLoading', true)
        await axios.get('/api/v1/users/me/')
            .then(async response => {
                this.user = response.data
                await this.getServicePoint(this.user.id)
            })
            .catch(error => {
                console.log(error)
            })
        this.$store.commit('setIsLoading', false)


        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();

        this.today_date = `${day}/${month}/${year}`;

    },
    async mounted() {
        document.title = 'Dashboard | Company';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0);

        this.$store.commit('setIsLoading', true)
        await this.getNotifications()
        this.$store.commit('setIsLoading', false)
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        async getServicePoint(user_id) {
            await axios.post('/api/v1/getservicepoint/')
                .then(response => {
                    this.company = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        async getNotifications() {
      await axios.post('/api/v1/get-notifications/')
        .then(response => {
          this.pending_orders_notification = response.data['pending_orders']
          this.progress_orders_notification = response.data['progress_orders']
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
        logout() {
            axios.defaults.headers.common["Authorization"] = ""
            sessionStorage.removeItem("token")
            this.$store.commit('removeToken')
            this.$store.commit('removeAgent')
            this.$store.commit('clearShipment')
            this.$router.push('/login')
        }
    }
}
</script>
    
<style scoped>
.custom {
    border: none;
    cursor: pointer;
    font-size: 15px;
    padding-bottom: 10px;

}

.custom:hover {
    color: #E5097F;
    font-weight: bold;
}


.active-item {
    color: #E5097F;
    font-weight: bold;
}

.img-item {
    margin-right: 8px;
}

.red-header tr {
    background-color: red;
    color: white;
}

.btn-new-ship {
    color: #E5097F;
    font-weight: bold;
    background-color: #fff;
    border: 2px solid #E5097F;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
    padding-top: 2px;
}

.btn-new-ship:hover {
    background-color: #9b0656;
    color: white;
    border: 2px solid #9b0656;
}


.btn-more {
    color: #fff;
    font-weight: bold;
    background-color: #E5097F;
    border: 2px solid #E5097F;
    margin-left: 10px;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.btn-more:hover {
    background-color: #9b0656;
    border: 2px solid #9b0656;
}

.btn-request {
    background-color: #BCF2C0;
    margin-left: 12px;
    border-radius: 20px;
    border: 2px solid #37B980;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: bold;
}

.btn-request:hover {
    background-color: #37B980;
    color: white;
}


.box-shadow-custom {
    box-shadow: 1px 1px 1px #aaa, -1px -1px 1px #aaa;
}

.custom-label {
    font-weight: 600;
}</style>
    