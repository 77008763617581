<template>
    <section style="margin-top: 30px; margin-bottom: 100px;">


        <div class="container mt-4">
            <div class="row justify-content-center">
                <div class="col-md-6 col-sm-12 mb-4">
                    <div class="p-4 rounded text-center"
                        style="box-shadow: 1px 1px 1px #888888, -1px -1px 1px #888888; font-weight: bold;">
                        <div>
                            {{ translate('have_account') }}
                            <router-link to="/login" class="btn btn-login2 " type="submit">
                                <i class="fa fa-user" aria-hidden="true"></i> {{ translate('login') }}
                            </router-link>
                        </div>

                        <div style="margin-top: 15px;">
                            {{ translate('dont_have_account') }}
                            <router-link to="/signup" class="btn btn-login2 " type="submit">
                                <i class="fa fa-user-plus" aria-hidden="true"></i> {{ translate('sign_up') }}
                            </router-link>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-md-6 col-sm-12 mb-4">
                    <div class="p-4 rounded text-center" style="box-shadow: 1px 1px 1px #888888, -1px -1px 1px #888888;">
                        <div>
                            <img src="../assets/img/signup.png" alt="Signup Image">
                        </div>
                        <form @submit.prevent="submitForm" style="margin-top: 20px;">
                            <div class="form-group mb-3">
                            </div>

                            <div class="form-group mb-3">
                                <input type="password" class="form-control" :placeholder="translate('new_password')"
                                    required v-model="new_password"
                                    :class="{ 'fill-out': new_password, 'not-fill-out': !new_password }">
                            </div>

                            <div class="form-group mb-3">
                                <input type="password" class="form-control" :placeholder="translate('confirm_new_password')"
                                    required v-model="re_new_password"
                                    :class="{ 'fill-out': re_new_password, 'not-fill-out': !re_new_password }">
                            </div>

                            <div class="text-center mt-3">
                                <button type="submit" class="btn btn-login2 me-2">Reset Password</button>
                                <br>
                                <div class="notification is-danger" v-if="errors.length" style="margin-top: 20px;">
                                    <p v-for="error in errors" :key="error">{{ error }}</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


    </section>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';

export default {
    name: 'ResetPassword',
    mixins: [en, fr, nl],
    data() {
        return {
            new_password: '',
            re_new_password: '',
            errors: []
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    mounted() {
        document.title = 'Reset Password | Eurekali'
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        submitForm() {
            const uid = this.$route.params.uid
            const token = this.$route.params.token

            this.errors = []
            if (this.new_password === '') {
                this.errors.push('The password is too short')
            }
            if (this.new_password !== this.re_new_password) {
                this.errors.push('The passwords doesn\'t match')
            }
            if (!this.errors.length) {
                const formData = {
                    uid: uid,
                    token: token,
                    new_password: this.new_password,
                    re_new_password: this.re_new_password
                }
                axios
                    .post("/api/v1/users/reset_password_confirm/", formData)
                    .then(response => {
                        toast({
                            message: this.translate('reset_password_alert'),
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 3000,
                            position: 'top-right',
                        })
                        this.$router.push('/login')
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                            console.log(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            this.errors.push(this.translate('error'))

                            console.log(JSON.stringify(error))
                        }
                    })
            }
        }
    }
}
</script>


