<template>
    <section style="margin-top: 50px; margin-bottom: 100px;">

        <div class="container mt-4">

            <div class="row justify-content-center">
                <div class="col-md-6 col-sm-12 mb-4">
                    <div class="p-4 rounded text-center" style="box-shadow: 1px 1px 1px #888888, -1px -1px 1px #888888;">
                        <div>
                            <img src="../assets/img/signup.png" alt="Signup Image">
                        </div>
                        <form @submit.prevent="submitForm" style="margin-top: 20px;">
                            <div class="form-group mb-3">
                            </div>
                            <div class="form-group mb-3">
                                <input type="text" class="form-control" :placeholder="translate('first_name')" required
                                    v-model="first_name" :class="{ 'fill-out': first_name, 'not-fill-out': !first_name }">
                            </div>
                            <div class="form-group mb-3">
                                <input type="text" class="form-control" :placeholder="translate('last_name')" required
                                    v-model="last_name" :class="{ 'fill-out': last_name, 'not-fill-out': !last_name }">
                            </div>


                            <div class="text-center mt-3">
                                <button type="submit" class="btn btn-login2 me-2">{{ translate('create_new_meeting') }}</button>
                                <br>
                                <div class="notification is-danger" v-if="errors.length" style="margin-top: 20px;">
                                    <p v-for="error in errors" :key="error">{{ error }}</p>
                                </div>
                            </div>
                        </form>
                        <template v-if="room_namec">
                                <button type="submit" class="btn btn-login2 me-2" @click="joinmeeting" style="margin-top: 20px;">{{ translate('join_your_meeting') }}</button>
                            </template>
                            <template v-if="delete_room">
                                <button type="submit" class="btn btn-login2 me-2" @click="deleteRoom" style="margin-top: 20px;">{{ translate('delete_room') }}</button>
                            </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
    name: 'LogIn',
    mixins: [en, fr, nl],
    data() {
        return {
            first_name: "",
            last_name: "",
            room_namec: "",
            delete_room: false,
            errors: []
        }
    },
    mounted() {
        document.title = 'Meetings | Eurekali',

        this.first_name = this.$store.state.shipment.sender.first_name
        this.last_name = this.$store.state.shipment.sender.last_name
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        async deleteRoom() {
            this.$store.commit('setIsLoading', true)
            await axios
                .post('/api/v1/delete-room/' + this.first_name + '_' + this.last_name +'/')
                .then(response => {

                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        async submitForm() {
            this.$store.commit('setIsLoading', true)
            const room_name = this.first_name + '_' + this.last_name

            await axios
                .post(`/api/v1/create-room/${room_name}/`)
                .then(response => {
                    this.room_namec = this.first_name + '_' + this.last_name;
                    const newPageUrl = 'https://eurekali-point.com/meetingsroom/' + this.first_name + '_' + this.last_name + '/' + this.lang;
                    window.open(newPageUrl, '_blank');
                })
                .catch(error => {
                    if (error.response) {
                        this.delete_room = true;
                        this.errors.push(this.translate('meetings_error'))
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        console.log(JSON.stringify(error))
                    }
                })

            this.$store.commit('setIsLoading', false)
        },
        joinmeeting(){
            const newPageUrl = 'https://eurekali-point.com/meetingsroom/' + this.room_namec + '/' + this.lang;
            window.open(newPageUrl, '_blank');
        }
    }
}
</script>


