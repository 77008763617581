<template>
    <div class="table-container container-lg" style="margin-top: 30px; margin-bottom: 100px;">
        <div>
            <div class="table-wrapper">
                <template v-if="shipments.length">
                    <div class="table-responsive-lg">

                        <table class="table table-hover table-striped table-bordered table-responsive">
                            <thead class="table-dark">
                                <tr>
                                    <!-- Add the 'white-space: nowrap;' and 'text-align: center;' styles to table headers (th) -->
                                    <th
                                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff; border-top-left-radius: 12px;">
                                        {{ translate('tracking_number') }}</th>
                                    <th
                                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                                        {{ translate('order_date') }} </th>
                                    <th
                                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                                        {{ translate('shipping_date') }} </th>
                                    <th
                                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                                        {{ translate('ship_from') }}</th>
                                    <th
                                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                                        {{ translate('ship_to') }}</th>
                                    <th
                                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                                        {{ translate('package_type') }}</th>
                                    <th
                                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                                        {{ translate('max_weight') }} (Kg)</th>
                                    <th
                                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                                        {{ translate('status') }}</th>
                                    <th
                                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                                        {{ translate('assurance') }}</th>
                                    <th
                                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                                        {{ translate('discount') }} %</th>
                                    <th
                                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff; border-top-right-radius: 12px;">
                                        {{ translate('price') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="shipment in shipments" :key="shipment.shipment_id">
                                    <td style="vertical-align: middle; text-align: center; cursor: pointer; text-decoration: underline; color: #E5097F;"
                                        @click="goToShipmentPage(shipment.shipment_id)"> {{ shipment.shipment_id }}</td>
                                    <td style="vertical-align: middle; text-align: center;"> {{ shipment.order_date }} <br>
                                        {{ shipment.order_time }}</td>
                                    <td style="vertical-align: middle; text-align: center;"> {{ shipment.shipping_date }}
                                    </td>
                                    <td style="vertical-align: middle; text-align: center;"> {{ shipment.ship_from }}</td>
                                    <td style="vertical-align: middle; text-align: center;"> {{ shipment.ship_to }}</td>
                                    <td style="vertical-align: middle; text-align: center;"> {{ shipment.package_type }}
                                    </td>
                                    <td style="vertical-align: middle; text-align: center;"> {{ shipment.max_weight }}</td>
                                    <template v-if="!shipment.isDelivered">
                                        <td style="vertical-align: middle; text-align: center;"> <span
                                                class="badge rounded-pill"
                                                :class="{ 'bg-progress': !shipment.isDelivered, 'bg-delivered': shipment.isDelivered }"
                                                style="font-size: 1em; back">{{ translate('in_progress') }} </span> </td>
                                    </template>
                                    <template v-else>
                                        <td style="vertical-align: middle; text-align: center;"> <span
                                                class="badge rounded-pill"
                                                :class="{ 'bg-progress': !shipment.isDelivered, 'bg-delivered': shipment.isDelivered }"
                                                style="font-size: 1em; back">{{ translate('delivered') }} </span> </td>
                                    </template>

                                    <td style="vertical-align: middle; text-align: center;"> {{
                                        translate(shipment.insurance_option) }}</td>
                                    <td style="vertical-align: middle; text-align: center;"> {{ shipment.discount }} %</td>
                                    <td style="vertical-align: middle; text-align: center;"> <span
                                            class="badge bg-success rounded-pill" style="font-size: 0.9em;">{{
                                                shipment.price }} &euro;</span></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div class="container"
                        style="text-align: center; color: white; font-weight: bold; font-size: 1.5em; background-color: #F1A8D0; padding: 60px; border-radius: 20px;">
                        {{ translate('no_orders') }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';

export default {
    mixins: [en, fr, nl],
    data() {
        return {
            shipments: [],
            user: {}
        };
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        async fetchShipments() {
            try {
                const response = await axios.get(`/api/v1/user_shipments/${this.user.id}/`);
                this.shipments = response.data;
            } catch (error) {
                console.error('Error fetching shipments:', error);
            }
        },
        goToShipmentPage(shipmentId) {
            this.$router.push(`/track/${shipmentId}`);
        },
    },
    async created() {

        this.$store.commit('setIsLoading', true)

        await axios.get('/api/v1/users/me/')
            .then(response => {
                this.user = response.data


            })
            .catch(error => {
                console.log(error)
            })

        this.fetchShipments();

        this.$store.commit('setIsLoading', false)
    },
};
</script>
  
<style>
/* Add custom styles here if necessary */
.table-container {
    overflow-x: auto;
}
</style>
  